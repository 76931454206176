
.side-links{
display:block;
}
.side-links ul{
padding:0px;
margin:0px;
}
.side-links ul li{
padding:0px;
margin:0px;
list-style:none;
}
.side-links ul li a{
    padding: 10px 10px;
    text-decoration: none;
    display: block;
    color: #333 ;
    background: #FFF;
    border: 1px #CCC solid;
    margin-bottom: 5px;
}
.side-links ul li a:hover{
    color: #FFF ;
    background: #f47629;
    border: 1px #f47629 solid;
}

.side-links ul li a.active{
    color: #FFF ;
    background: #f47629;
    border: 1px #f47629 solid;
}

.btn-color {
    color: #fff;
    background-color: #f47629;
    border: 1px solid #f47629;
    padding: 13px 30px 17px;
    height: 61px;
    font-size: 20px;
    line-height: 31px;
    border-radius: 6px;
}
.btn-color:hover {
    color: #f47629;
    background-color: #fff;
    border: 1px solid #f47629;
	}
