/* ----------------------------------------------------------------
    TEMPLATE FONTS
-----------------------------------------------------------------*/


/*Google fonts */

/*@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Hind:400,500,600,700,300);
@import url(https://fonts.googleapis.com/css?family=Nunito:300,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600);*/
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,300i);



/*href='//fonts.googleapis.com/css?family=Playfair+Display:400,700italic|Raleway:700|*/


/*body*/



/**! 02. Typography **/
/*
html {
    font-size: 87.5%;
}
@media all and (max-width: 768px) {
    html {
        font-size: 81.25%;
    }
}
body {
    font-size: 1em;
    line-height: 1.85714286em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    color: #666666;
    font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    color: #252525;
    font-weight: 300;
    font-variant-ligatures: common-ligatures;
    margin-top: 0;
    margin-bottom: 0;
}
h1,
.h1 {
    font-size: 3.14285714em;
    line-height: 1.31818182em;
}
h2,
.h2 {
    font-size: 2.35714286em;
    line-height: 1.36363636em;
}
h3,
.h3 {
    font-size: 1.78571429em;
    line-height: 1.5em;
}
h4,
.h4 {
    font-size: 1.35714286em;
    line-height: 1.68421053em;
}
h5,
.h5 {
    font-size: 1em;
    line-height: 1.85714286em;
}
h6,
.h6 {
    font-size: 0.85714286em;
    line-height: 2.16666667em;
}
.lead {
    font-size: 1.35714286em;
    line-height: 1.68421053em;
}
@media all and (max-width: 767px) {
    h1,
    .h1 {
        font-size: 2.35714286em;
        line-height: 1.36363636em;
    }
    h2,
    .h2 {
        font-size: 1.78571429em;
        line-height: 1.5em;
    }
    h3,
    .h3 {
        font-size: 1.35714286em;
        line-height: 1.85714286em;
    }
    .lead {
        font-size: 1.35714286em;
        line-height: 1.68421053em;
    }
}
p,
ul,
ol,
pre,
table,
blockquote {
    margin-bottom: 1.85714286em;
}


ul.bullets {
    list-style: inside;
}
ol {
    list-style-type: upper-roman;
    list-style-position: inside;
}


strong {
    font-weight: 600;
}
hr {
    margin: 1.85714286em 0;
    border-color: #fafafa;
}
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}*/

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* ----------------------------------------------------------------
    BODY
-----------------------------------------------------------------*/

.real-estate-form-container {
	overflow: inherit !important;
}

.real-estate-form {
  margin-bottom: 0;
  margin-top: -300px;
  position: relative;
  z-index: 4 !important;
}

.real-estate-form #book {
  background-color: #fff;
  border: 1px solid #eee;
}

.innerBanerText {
    font-weight: 300;
    font-size: 34px;
    font-family: Raleway;
    line-height: 40px;
    color: #f47629;
}

-------------------------
/* 
.fa-1 {
    background: url(../images/icon-1.png) center center no-repeat;
        background-color: rgba(0, 0, 0, 0);
}
.fa-2 {
    background: url(../images/icon-2.png) center center no-repeat;
        background-color: rgba(0, 0, 0, 0);
}
.fa-3 {
    background: url(../images/icon-3.png) center center no-repeat;
        background-color: rgba(0, 0, 0, 0);
}
.fa-4 {
    background: url(../images/icon-4.png) center center no-repeat;
        background-color: rgba(0, 0, 0, 0);
}
.fa-5 {
    background: url(../images/icon-5.png) center center no-repeat;
        background-color: rgba(0, 0, 0, 0);
}
.fa-6 {
    background: url(../images/icon-6.png) center center no-repeat;
        background-color: rgba(0, 0, 0, 0);
} */


.font24{
font-size:20px;
}

#header.dark #mainMenu nav > ul > li > a:hover{
color:#f47629;
}
#header.dark #mainMenu nav > ul > li > a.select{
color:#f47629;
}
.contact-list {
    list-style: none;
	font-size:16px;
	line-height:28px;
}
.contact-list li{
	margin-bottom:10px;
}

.footer-top {
    margin-bottom: 0px;
    padding: 15px 0;
    background: #f47629;
    font-size: 26px;
    line-height: 28px;
    font-weight: 400;
}
@media (min-width: 768px) {
    .footer-top .row {
        display: table;
        width: 100%;
    }
    .footer-top [class^="col-"],
   .footer-top [class*=" col-"] {
        position: relative;
        display: table-cell;
        vertical-align: top;
        text-align: center;
        float: none;
    }
    .footer-top .col-sm-1::after {
        content: "�";
        position: absolute;
        left: 50%;
        margin-left: -3px;
        top: 0;
    }
}


.footer-top .textwidget p {
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
}

.form-heding{
color:#FFF !important;
background:#f47629;
padding:4px 10px;
margin-bottom: 0px !important;
border-top-left-radius: 4px;
border-top-right-radius: 4px;
display:inline-block;
}


html {
  overflow-x: hidden; }

/* Layout boxed & wide */
@media (max-width: 479px) {
  .container {
    padding: 0 20px; } }
body #wrapper {
  padding: 0;
  margin: 0;
  overflow-x: hidden; }
body.wide #wrapper {
  width: 100%;
  margin: 0; }
body.boxed #wrapper, body.boxed-simple #wrapper {
  position: relative;
  border: 1px solid #eeeeee;
  background-color: #ffffff; }
body.outter {
  margin: 30px; }
body.boxed-simple {
  background-color: #f8f8f8;
  border: 0 none !important; }

.container-wide {
  padding-left: 30px;
  padding-right: 30px; }

/* Responsive */
@media (max-width: 479px) {
  body {
    font-size: 13px; }
    body .outter {
      margin: 0px; } }
@media (min-width: 1240px) {
  body.boxed #wrapper,
  body.boxed-simple #wrapper {
    float: none;
    width: 1220px;
    margin: 0 auto; }

  body.boxed-simple #wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px; }

  .container-fluid {
    padding-left: 30px;
    padding-right: 30px; }

  .container-wide {
    padding-right: 8%;
    padding-left: 8%;
    margin: 0 auto; } }
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

iframe {
  border: none; }

/* Sections */
section {
  padding: 80px 0;
  position: relative;
  z-index: auto;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background-color: #ffffff; }

/* Panles */
.panel .table th,
.panel .table td {
  padding-left: 20px !important; }

/*# sourceMappingURL=layout.css.map */

/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
::selection {
  background: #1abc9c;
  color: #FFF;
  text-shadow: none; }

::-moz-selection {
  background: #1abc9c;
  /* Firefox */
  color: #FFF;
  text-shadow: none; }

::-webkit-selection {
  background: #1abc9c;
  /* Safari */
  color: #FFF;
  text-shadow: none; }

:active,
:focus {
  outline: none !important; }

/** Typography **/
html {
  font-size: 87.5%; }

@media all and (max-width: 768px) {
  html {
    font-size: 81.25%; } }
body {
  font-size: 1em;
  line-height: 1.65714286em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  color: #565656;
  font-weight: 400; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #252525;
  margin: 0;
  font-weight: 300;
  letter-spacing: 1px; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .h1 small,
  .h2 small,
  .h3 small,
  .h4 small,
  .h5 small,
  .h6 small {
    font-weight: 100; }

h1,
.h1 {
  font-size: 3.14285714em;
  line-height: 1.31818182em; }
  h1:not(:last-child),
  .h1:not(:last-child) {
    margin-bottom: 0.59090909090909em; }

h2,
.h2 {
  font-size: 2.35714286em;
  line-height: 1.36363636em;
  font-weight: 600; }
  h2:not(:last-child),
  .h2:not(:last-child) {
    margin-bottom: 0.78787878787879em; }

h3,
.h3 {
  font-size: 1.78571429em;
  line-height: 1.5em;
  font-weight: 600; }
  h3:not(:last-child),
  .h3:not(:last-child) {
    margin-bottom: 1.04em; }

h4,
.h4 {
  font-size: 1.35714286em;
  line-height: 1.68421053em;
  font-weight: 600; }
  h4:not(:last-child),
  .h4:not(:last-child) {
    margin-bottom: 0.700842105263158em; }

h5,
.h5 {
  font-size: 1em;
  line-height: 1.85714286em;
  font-weight: 600; }
  h5:not(:last-child),
  .h5:not(:last-child) {
    margin-bottom: 0.600842105263158em; }

h6,
.h6 {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
  font-weight: 600; }
  h6:not(:last-child),
  .h6:not(:last-child) {
    margin-bottom: 0.500842105263158em; }

.lead {
  font-size: 1.35714286em;
  line-height: 1.68421053em; }

@media all and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2.35714286em;
    line-height: 1.36363636em; }

  h2,
  .h2 {
    font-size: 1.78571429em;
    line-height: 1.5em; }

  h3,
  .h3 {
    font-size: 1.35714286em;
    line-height: 1.85714286em; }

  .lead {
    font-size: 1.35714286em;
    line-height: 1.68421053em; } }
p {
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  line-height: 28px;
  letter-spacing: 0;
  color: #7b7b7b; }

p,
ul,
ol,
pre,
table {
  margin-bottom: 1.85714286em; }

ul.bullets {
  list-style: inside; }

ol {
  list-style-type: upper-roman;
  list-style-position: inside; }

strong {
  font-weight: 600; }

/*hr {
    margin: 1.85714286em 0;
    border-color: #fafafa;
}*/
a {
  color: #565656; }
  a:hover, a:focus, a:active {
    text-decoration: none;
    outline: none;
    color: #1abc9c; }

/*
// Fonts 
h1,
h1 a,  
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a {
    color: #111;
    font-family: $font-family-2;
    line-height: 1.5;
    margin-bottom: $default-margin;
    text-rendering: optimizelegibility;
    font-weight: 400;
}
h1 {
    font-size: 36px;
}
h2 {
    font-size: 30px;
}
h3 {
    font-size: 24px;
}
h4 {
    font-size: 18px;
 /*   font-weight: 700;
    letter-spacing: -.04em;
    line-height: 1.17;
    color: #202020;
}
h5 {
    font-size: 14px;
}
h6 {
    font-size: 12px;
}
h4 {
    font-weight: 600;
}
h5,
h6 {
    font-weight: bold;
    margin-bottom: $default-margin;
}
p,
p.lead {
    color: #696f6f;
    margin-bottom: $default-margin;
}*/
.font-pacifico {
  font-family: 'Dancing Script' !important; }

.font-raleway a,
.font-raleway h1,
.font-raleway h2,
.font-raleway h3,
.font-raleway h4,
.font-raleway h5,
.font-raleway h6 {
  font-family: 'Raleway' !important;
  font-weight: 100 !important; }

h1.font-raleway,
h2.font-raleway,
h3.font-raleway,
h4.font-raleway,
h5.font-raleway,
h6.font-raleway {
  font-family: 'Raleway' !important;
  font-weight: 100 !important; }

.font-nothing-you-could-do,
.font-nothing-you-could-do a,
.font-nothing-you-could-do h1,
.font-nothing-you-could-do h2,
.font-nothing-you-could-do h3,
.font-nothing-you-could-do h4,
.font-nothing-you-could-do h5,
.font-nothing-you-could-do h6,
h1.font-nothing-you-could-do,
h2.font-nothing-you-could-do,
h3.font-nothing-you-could-do,
h4.font-nothing-you-could-do,
h5.font-nothing-you-could-do,
h6.font-nothing-you-could-do {
  font-family: 'Nothing You Could Do', cursive !important;
  font-weight: normal !important; }

.font-herr-von-muellerhoff,
.font-herr-von-muellerhoff a,
.font-herr-von-muellerhoff h1,
.font-herr-von-muellerhoff h2,
.font-herr-von-muellerhoff h3,
.font-herr-von-muellerhoff h4,
.font-herr-von-muellerhoff h5,
.font-herr-von-muellerhoff h6,
h1.font-herr-von-muellerhoff,
h2.font-herr-von-muellerhoff,
h3.font-herr-von-muellerhoff,
h4.font-herr-von-muellerhoff,
h5.font-herr-von-muellerhoff,
h6.font-herr-von-muellerhoff {
  font-family: 'Herr Von Muellerhoff', cursive !important;
  font-weight: normal !important; }

.font-cedarville,
.font-cedarville a,
.font-cedarville h1,
.font-cedarville h2,
.font-cedarville h3,
.font-cedarville h4,
.font-cedarville h5,
.font-cedarville h6,
h1.font-cedarville,
h2.font-cedarville,
h3.font-cedarville,
h4.font-cedarville,
h5.font-cedarville,
h6.font-cedarville {
  font-family: 'Cedarville Cursive', cursive; }

.font-parisienne,
.font-parisienne a,
.font-parisienne h1,
.font-parisienne h2,
.font-parisienne h3,
.font-parisienne h4,
.font-parisienne h5,
.font-parisienne h6,
h1.font-parisienne,
h2.font-parisienne,
h3.font-parisienne,
h4.font-parisienne,
h5.font-parisienne,
h6.font-parisienne {
  font-family: 'Parisienne', cursive; }

.heading-1 {
  text-align: center; }
  .heading-1 h1 {
    margin-bottom: 40px;
    font-size: 70px;
    line-height: 90px;
    color: #000000;
    font-weight: 600; }
  .heading-1 p {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 34px;
    font-weight: 300;
    color: #000000;
    line-height: 50px;
    margin-bottom: 40px; }
    .heading-1 p span {
      font-weight: 600; }
  .heading-1.text-left p, .heading-1.text-right p {
    padding: 0; }

.heading-2 {
  text-align: center; }
  .heading-2 h1 {
    margin-bottom: 30px;
    font-size: 56px;
    line-height: 70px;
    color: #000000;
    font-weight: 600; }
  .heading-2 p {
    font-size: 22px;
    font-weight: 300;
    color: #000000;
    line-height: 36px;
    margin-bottom: 40px; }
    .heading-2 p span {
      font-weight: 600; }
  .heading-2.text-left p, .heading-2.text-right p {
    padding: 0; }

.heading-3 {
  text-align: center;
  margin-bottom: 40px; }
  .heading-3 h1,
  .heading-3 h2,
  .heading-3 h3 {
    color: #000000;
    margin-bottom: 20px;
    font-size: 59px;
    font-weight: 100;
    letter-spacing: -1px;
    line-height: 70px; }
  .heading-3 p {
    font-size: 17px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 20px; }
    .heading-3 p span {
      font-weight: 600; }
  .heading-3.text-left p, .heading-3.text-right p {
    padding: 0; }

.heading-4 {
  text-align: center;
  margin-bottom: 40px; }
  .heading-4 h1,
  .heading-4 h2,
  .heading-4 h3 {
    font-family: "Nunito", "Helvetica", "Arial", sans-serif;
    color: #000000;
    font-weight: 300;
    font-size: 50px;
    letter-spacing: -0.02em; }
  .heading-4 p {
    font-size: 18px;
    line-height: 1.75;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; }
    .heading-4 p span {
      font-weight: 600; }
  .heading-4.text-left p, .heading-4.text-right p {
    padding: 0; }

.marquee-title {
  white-space: nowrap;
  font-weight: 700;
  font-size: 165px;
  word-spacing: 0px;
  font-family: 'Poppins', sans-serif;
  color: #eeeeee;
  margin-bottom: -60px; }

/*# sourceMappingURL=typography.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* ----------------------------------------------------------------
Topbar
-----------------------------------------------------------------*/
#topbar {
  position: relative;
  z-index: 200;
  background-color: #ffffff;
    /*+ #header:not(.header-transparent):not(.header-dark-transparent):not(.header-light-transparent) {
border-top: 1px solid $light;
}*/
  /*colored*/ }
  #topbar .top-menu {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    float: left; }
    #topbar .top-menu > li {
      display: inline-block;
      height: 40px;
      line-height: 40px; }
      #topbar .top-menu > li > a {
        display: block;
        font-size: 12px;
        font-weight: 500;
        height: 40px;
        margin-right: 16px; }
  #topbar .top-links {
    position: relative; }
  #topbar .social-icons {
    float: right;
    height: 100%;
    overflow: hidden; }
    #topbar .social-icons li,
    #topbar .social-icons li a {
      float: left;
      list-style: outside none none; }
    #topbar .social-icons li a {
      border-radius: 0px;
      font-size: 15px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      width: 35px;
      overflow: hidden;
      margin: 0; }
  #topbar.dark {
    background-color: #252525;
        /* + #header:not(.header-transparent):not(.header-dark-transparent):not(.header-light-transparent) {
    border-top: 1px solid rgba($black, .8);
}*/ }
    #topbar.dark .top-menu > li > a,
    #topbar.dark .social-icons li a {
      color: #ffffff !important;
      /*border-right: 1px solid rgba($light, .1);*/ }
  #topbar.topbar-colored, #topbar.topbar-colored.dark {
    background-color: #1abc9c; }

.topbar-dropdown {
  color: #747474;
  float: left;
  font-size: 13px;
  font-weight: 400;
  position: relative; }
  .topbar-dropdown .title {
    border-left: 1px solid #eeeeee;
    padding: 0 20px;
    cursor: pointer;
    line-height: 40px; }
    .topbar-dropdown .title a {
      color: #747474; }
      .topbar-dropdown .title a:hover {
        text-decoration: underline; }
    .topbar-dropdown .title b {
      font-weight: 600; }
    .topbar-dropdown .title .fa {
      margin-left: 7px;
      position: relative;
      top: -1px; }
      .topbar-dropdown .title .fa:first-child {
        margin-left: 0;
        margin-right: 7px; }
  .topbar-dropdown:first-child .title {
    border-color: transparent;
    padding-left: 0; }
  .topbar-dropdown .title img,
  .topbar-dropdown .list-entry img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    position: relative;
    top: -1px; }
  .topbar-dropdown .dropdown-list {
    position: absolute;
    width: auto;
    left: 0px;
    top: auto;
    border-radius: 3px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: solid 1px #eee;
    padding: 0px 16px;
    z-index: 1;
    text-align: left;
    opacity: 0;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transform-origin: 50% 0% 0px;
    -moz-transform-origin: 50% 0% 0px;
    -webkit-transform-origin: 50% 0% 0px;
    -ms-transform-origin: 50% 0% 0px;
    z-index: 1;
    visibility: hidden; }
  .topbar-dropdown:hover .dropdown-list, .topbar-dropdown:hover .topbar-form {
    opacity: 1;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    visibility: visible; }
  .topbar-dropdown .list-entry {
    line-height: 30px;
    white-space: nowrap;
    display: block;
    color: #747474;
    padding: 2px 0;
    border-top: 1px #f0f0f0 solid; }
    .topbar-dropdown .list-entry:hover {
      color: #222; }
    .topbar-dropdown .list-entry:first-child {
      border-top: none; }
  .topbar-dropdown .topbar-form {
    position: absolute;
    width: 240px;
    padding: 20px 20px 10px 20px;
    right: auto;
    top: 40px;
    border-radius: 3px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: solid 1px #eee;
    z-index: 1;
    text-align: left;
    opacity: 0;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transform-origin: 50% 0% 0px;
    -moz-transform-origin: 50% 0% 0px;
    -webkit-transform-origin: 50% 0% 0px;
    -ms-transform-origin: 50% 0% 0px;
    visibility: hidden; }
  .topbar-dropdown input {
    margin-bottom: 2px; }
  .topbar-dropdown .form-group {
    margin-bottom: 10px; }
  .topbar-dropdown .checkbox {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    vertical-align: middle;
    margin-top: -12px; }
  .topbar-dropdown.float-right .title {
    padding: 0 0px 0 20px !important; }
  .topbar-dropdown .dropdown-invert.topbar-form {
    left: auto;
    right: -20px; }

#topbar.topbar-fullwidth > .container {
  width: 100%;
  padding: 0 30px; }

#topbar {
  border-bottom: 1px solid #eeeeee; }
  #topbar.topbar-dark, #topbar.dark {
    background-color: #252525;
    border-color: rgba(231, 231, 231, 0.25); }
    #topbar.topbar-dark .top-menu > li > a,
    #topbar.topbar-dark .social-icons li a, #topbar.dark .top-menu > li > a,
    #topbar.dark .social-icons li a {
      color: #ffffff !important; }
  #topbar .topbar-light .top-menu > li > a,
  #topbar .topbar-light .social-icons li a {
    color: #111 !important; }

.topbar-transparent {
  margin-top: -2px; }
  .topbar-transparent.topbar-dark .topbar-dropdown .title a, .topbar-transparent.topbar-dark .topbar-dropdown {
    color: #ffffff; }
  .topbar-transparent .topbar-dropdown .title {
    border-color: rgba(231, 231, 231, 0.25); }
  .topbar-transparent .topbar-dropdown:first-child .title {
    border-color: transparent; }

#topbar.topbar-dark.topbar-colored {
  border-bottom: 0; }
#topbar.topbar-transparent {
  background-color: transparent;
  border-bottom: 1px solid rgba(144, 144, 144, 0.25); }
  #topbar.topbar-transparent.dark {
    border-bottom: 1px solid rgba(231, 231, 231, 0.25); }

#topbar ~ #header.header-transparent + #slider,
#topbar ~ #header.header-transparent + #page-title:not(.page-title-classic),
#topbar ~ #header.header-transparent + .fullscreen,
#topbar ~ #header.header-transparent + .halfscreen,
#topbar ~ #header.header-transparent + #google-map, #topbar ~ #header.header-light-transparent + #slider,
#topbar ~ #header.header-light-transparent + #page-title:not(.page-title-classic),
#topbar ~ #header.header-light-transparent + .fullscreen,
#topbar ~ #header.header-light-transparent + .halfscreen,
#topbar ~ #header.header-light-transparent + #google-map, #topbar ~ #header.header-dark-transparent + #slider,
#topbar ~ #header.header-dark-transparent + #page-title:not(.page-title-classic),
#topbar ~ #header.header-dark-transparent + .fullscreen,
#topbar ~ #header.header-dark-transparent + .halfscreen,
#topbar ~ #header.header-dark-transparent + #google-map, #topbar ~ #header.header-colored-transparent + #slider,
#topbar ~ #header.header-colored-transparent + #page-title:not(.page-title-classic),
#topbar ~ #header.header-colored-transparent + .fullscreen,
#topbar ~ #header.header-colored-transparent + .halfscreen,
#topbar ~ #header.header-colored-transparent + #google-map {
  top: -120px;
  margin-bottom: -120px; }
#topbar ~ #header.header-transparent + #page-title:not(.page-title-classic), #topbar ~ #header.header-light-transparent + #page-title:not(.page-title-classic), #topbar ~ #header.header-dark-transparent + #page-title:not(.page-title-classic), #topbar ~ #header.header-colored-transparent + #page-title:not(.page-title-classic) {
  padding-top: 260px; }

/*# sourceMappingURL=topbar.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* ----------------------------------------------------------------
    Header
-----------------------------------------------------------------*/
#header {
  position: relative;
  width: 100%;
  z-index: 199 !important;
  height: 80px;
  line-height: 80px;
  /*sticky version*/
  /*dark*/
  /*transparent*/
  /*transparent dark*/
  /*transparent light*/
  /*transparent light*/
  /*colored*/
  /*header extras*/
  /*Responsive mobile menu*/ }
  #header .container {
    position: relative; }
  #header #header-wrap {
    background-color: #ffffff;
    -webkit-backface-visibility: hidden;
    left: 0;
    right: 0;
    transition: all .4s ease-in-out;
    border-bottom: 1px solid #eeeeee;
    /*logo*/ }
    #header #header-wrap #logo {
      float: left;
      font-size: 28px;
      position: relative;
      z-index: 1;
      transition: all .4s ease-in-out;
      height: 80px; }
      #header #header-wrap #logo a > img {
        vertical-align: inherit;
        transition: all .4s ease-in-out;
        height: 80px;
        width: auto; }
  #header.header-sticky #header-wrap {
    background-color: #ffffff !important;
    transition: top .8s ease-in-out;
    position: fixed;
    top: -140px;
    box-shadow: 0 12px 6px rgba(0, 0, 0, 0.06); }
    #header.header-sticky #header-wrap #logo {
      font-size: 20px; }
  #header.header-sticky.sticky-active #header-wrap {
    top: 0; }
  #header.header-sticky.dark #header-wrap {
    background-color: #252525 !important; }
  #header.dark {
    /*Main Menu*/ }
    #header.dark #header-wrap {
      background-color: #252525;
      border-bottom: 1px solid #292929; }
      #header.dark #header-wrap .lines,
      #header.dark #header-wrap .lines:before,
      #header.dark #header-wrap .lines:after {
        background-color: #ffffff; }
    #header.dark #mainMenu nav > ul > li:before {
      color: #9d9d9d; }
    #header.dark #mainMenu nav > ul > li > a {
      color: #ffffff; }
      #header.dark #mainMenu nav > ul > li > a .menu-sub-title {
        color: #eeeeee; }
    #header.dark #mainMenu nav > ul > li.current > a:after, #header.dark #mainMenu nav > ul > li:hover > a:after, #header.dark #mainMenu nav > ul > li:focus > a:after {
      background-color: #ffffff; }
    #header.dark .header-extras i {
      color: #ffffff; }
    #header.dark .header-extras .lines,
    #header.dark .header-extras .lines:before,
    #header.dark .header-extras .lines:after {
      background-color: #ffffff; }
  #header.header-transparent #header-wrap, #header.header-transparent.dark #header-wrap {
    background-color: transparent;
    border-bottom: 0; }
  #header.header-dark-transparent #header-wrap {
    background-color: rgba(37, 37, 37, 0.4);
    border-bottom: 0; }
  #header.header-light-transparent #header-wrap, #header.header-light-transparent.dark #header-wrap {
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: 0; }
  #header.header-colored-transparent #header-wrap, #header.header-colored-transparent.dark #header-wrap {
    background-color: rgba(26, 188, 156, 0.8);
    border-bottom: 0; }
  #header.header-colored-transparent.header-sticky #header-wrap, #header.header-colored-transparent.dark.header-sticky #header-wrap {
    background-color: #ffffff !important; }
  #header.header-transparent + #slider,
  #header.header-transparent + #page-title:not(.page-title-classic),
  #header.header-transparent + .fullscreen,
  #header.header-transparent + .halfscreen,
  #header.header-transparent + #google-map, #header.header-light-transparent + #slider,
  #header.header-light-transparent + #page-title:not(.page-title-classic),
  #header.header-light-transparent + .fullscreen,
  #header.header-light-transparent + .halfscreen,
  #header.header-light-transparent + #google-map, #header.header-dark-transparent + #slider,
  #header.header-dark-transparent + #page-title:not(.page-title-classic),
  #header.header-dark-transparent + .fullscreen,
  #header.header-dark-transparent + .halfscreen,
  #header.header-dark-transparent + #google-map, #header.header-colored-transparent + #slider,
  #header.header-colored-transparent + #page-title:not(.page-title-classic),
  #header.header-colored-transparent + .fullscreen,
  #header.header-colored-transparent + .halfscreen,
  #header.header-colored-transparent + #google-map {
    top: -80px;
    margin-bottom: -80px; }
  #header.header-transparent + #page-title:not(.page-title-classic), #header.header-light-transparent + #page-title:not(.page-title-classic), #header.header-dark-transparent + #page-title:not(.page-title-classic), #header.header-colored-transparent + #page-title:not(.page-title-classic) {
    padding-top: 220px; }
  #header.header-fullwidth #header-wrap .container {
    width: 100%;
    padding: 0 30px; }
  #header.header-fullwidth #header-wrap .header-extras {
    border-left: 1px solid #eeeeee;
    padding-left: 4px;
    margin-left: 14px; }
  #header.header-fullwidth.header-transparent #header-wrap {
    border-bottom: 1px solid rgba(144, 144, 144, 0.25); }
    #header.header-fullwidth.header-transparent #header-wrap .header-extras {
      border-left: 1px solid rgba(144, 144, 144, 0.25); }
  #header.header-fullwidth.header-transparent.dark #header-wrap {
    border-bottom: 1px solid rgba(231, 231, 231, 0.25); }
    #header.header-fullwidth.header-transparent.dark #header-wrap .header-extras {
      border-left: 1px solid rgba(231, 231, 231, 0.25); }
  #header.header-modern:not(.header-active) {
    margin: 40px auto 0;
    width: 1200px; }
    #header.header-modern:not(.header-active) + #slider,
    #header.header-modern:not(.header-active) + .fullscreen,
    #header.header-modern:not(.header-active) + .halfscreen,
    #header.header-modern:not(.header-active) + #google-map {
      top: -130px;
      margin-bottom: -130px; }
    #header.header-modern:not(.header-active) #header-wrap {
      background-color: #ffffff;
      border-radius: 4px; }
    #header.header-modern:not(.header-active).dark #header-wrap {
      background-color: #252525; }
    #header.header-modern:not(.header-active).header-transparent #header-wrap {
      background-color: transparent; }
    #header.header-modern:not(.header-active).header-colored #header-wrap {
      background-color: #1abc9c;
      border-bottom: 0; }
  #header.header-colored #header-wrap {
    background-color: #1abc9c;
    border-bottom: 0; }
  #header.header-mini {
    line-height: 60px;
    height: 60px; }
    #header.header-mini #header-wrap #mainMenu > .container nav > ul > li > a {
      font-size: 11px; }
    #header.header-mini #header-wrap #mainMenu > .container nav > ul > li .dropdown-submenu:before {
      top: -6px; }
    #header.header-mini #header-wrap #logo {
      height: 60px; }
      #header.header-mini #header-wrap #logo a > img {
        width: 100%;
        height: 60px; }
    #header.header-mini #header-wrap #mainMenu-trigger {
      height: 60px; }
  #header.header-logo-right #header-wrap #logo {
    float: right; }
  #header.header-logo-right #header-wrap .header-extras {
    float: left; }
    #header.header-logo-right #header-wrap .header-extras ul > li:first-child {
      margin-left: 0px; }
  #header.header-logo-right #header-wrap #mainMenu nav {
    float: left; }
  #header.header-static #header-wrap {
    position: fixed !important;
    top: 0 !important;
    background-color: #ffffff; }
  #header.header-plain #header-wrap {
    background-color: transparent;
    border: 0 !important; }
    #header.header-plain #header-wrap .header-extras {
      border: 0 !important; }
  #header .header-extras {
    float: right;
    z-index: 201;
    position: relative;
    /* Shoping cart*/ }
    #header .header-extras > ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      #header .header-extras > ul > li {
        float: left;
        margin-left: 20px;
        border: 0; }
        #header .header-extras > ul > li > a:not(.btn),
        #header .header-extras > ul > li .topbar-dropdown > .title {
          font-family: "Raleway", "Helvetica", "Arial", sans-serif;
          font-size: 12px;
          position: relative;
          display: block;
          font-style: normal;
          text-transform: uppercase;
          font-weight: 700; }
          #header .header-extras > ul > li > a:not(.btn) i,
          #header .header-extras > ul > li .topbar-dropdown > .title i {
            font-size: 14px;
            position: relative; }
            #header .header-extras > ul > li > a:not(.btn) i:nth-of-type(2),
            #header .header-extras > ul > li .topbar-dropdown > .title i:nth-of-type(2) {
              visibility: hidden;
              position: absolute; }
          #header .header-extras > ul > li > a:not(.btn).toggle-active i:nth-of-type(2),
          #header .header-extras > ul > li .topbar-dropdown > .title.toggle-active i:nth-of-type(2) {
            visibility: visible;
            position: relative; }
          #header .header-extras > ul > li > a:not(.btn).toggle-active i:nth-of-type(1),
          #header .header-extras > ul > li .topbar-dropdown > .title.toggle-active i:nth-of-type(1) {
            visibility: hidden;
            position: absolute; }
        #header .header-extras > ul > li .btn {
          font-size: 13px;
          line-height: 1.3;
          margin-bottom: 0px; }
    #header .header-extras #shopping-cart {
      position: relative; }
      #header .header-extras #shopping-cart > a {
        position: relative; }
        #header .header-extras #shopping-cart > a .shopping-cart-items {
          position: absolute;
          z-index: 1;
          background: #1abc9c;
          border-radius: 50%;
          color: #ffffff;
          font-size: 9px;
          font-weight: 600;
          height: 13px;
          line-height: 10px;
          padding: 2px 4px;
          right: -6px;
          top: -6px;
          width: 13px; }
    #header .header-extras .topbar-dropdown .title {
      line-height: inherit !important;
      letter-spacing: 1px;
      padding: 0;
      color: #252525; }
    #header .header-extras .topbar-dropdown .dropdown-list {
      right: -10px;
      margin-top: -20px;
      left: auto;
      box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.1);
      margin-top: -16px; }
      #header .header-extras .topbar-dropdown .dropdown-list:before {
        content: " ";
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #ffffff;
        top: -9px;
        right: 14px;
        position: absolute; }
  #header #mainMenu-trigger {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    height: 80px;
    z-index: 1; }
    #header #mainMenu-trigger button {
      background: none;
      border: 0;
      padding: 0; }
      #header #mainMenu-trigger button:focus {
        outline: 0; }
      #header #mainMenu-trigger button i {
        font-size: 17px; }

/* ----------------------------------------------------------------
Main Menu
-----------------------------------------------------------------*/
#mainMenu {
  padding: 0;
  /*Menu Styles*/
  /*Menu Positions*/
  /*Menu options*/ }
  #mainMenu > .container {
    padding: 0 !important;
    /*   width: 100%;
    max-width: 1170px;*/ }
  #mainMenu [class*="col-md-"] {
    line-height: 24px !important; }
  #mainMenu nav {
    float: right; }
    #mainMenu nav > ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      #mainMenu nav > ul > li {
        float: left;
        border: 0;
        transition: all .3s ease;
        margin-left: 6px; }
        #mainMenu nav > ul > li > a {
          position: relative;
          font-family: "Raleway", "Helvetica", "Arial", sans-serif;
          padding: 11px 14px;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1px;
          color: #252525;
          text-transform: uppercase;
          border-radius: 0;
          border-width: 0;
          border-style: solid;
          border-color: transparent;
          transition: all .3s ease;
          line-height: normal; }
          #mainMenu nav > ul > li > a > i {
            margin-left: 2px;
            margin-right: 6px;
            min-width: 14px;
            font-size: 14px; }
          #mainMenu nav > ul > li > a:after, #mainMenu nav > ul > li > a:before {
            transition: all .3s ease; }
        #mainMenu nav > ul > li.hover-active > a,
        #mainMenu nav > ul > li.hover-active > span, #mainMenu nav > ul > li.current > a,
        #mainMenu nav > ul > li.current > span, #mainMenu nav > ul > li:hover > a,
        #mainMenu nav > ul > li:hover > span, #mainMenu nav > ul > li:focus > a,
        #mainMenu nav > ul > li:focus > span {
          color: #1abc9c; }
        #mainMenu nav > ul > li .dropdown-menu {
          background-position: right bottom;
          background-repeat: no-repeat;
          visibility: hidden;
          display: block;
          opacity: 0;
          transition: all 0.3s ease;
          min-width: 230px;
          top: auto;
          background-color: #ffffff;
          border: 0;
          border-style: solid;
          border-color: #eeeeee;
          border-width: 1px !important;
          left: auto;
          margin: 0;
          margin-top: -6px;
          border-radius: 4px;
          box-shadow: 0px 0px 18px 0 rgba(0, 0, 0, 0.05);
          padding: 10px; }
          #mainMenu nav > ul > li .dropdown-menu > li > a,
          #mainMenu nav > ul > li .dropdown-menu > li > span,
          #mainMenu nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a {
            font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
            font-size: 13px;
            line-height: 14px;
            font-weight: 400;
            font-style: normal;
            color: #444444;
            padding: 12px 20px 12px 18px;
            display: block;
            transition: .3s ease; }
            #mainMenu nav > ul > li .dropdown-menu > li > a:focus, #mainMenu nav > ul > li .dropdown-menu > li > a:hover, #mainMenu nav > ul > li .dropdown-menu > li > a:active,
            #mainMenu nav > ul > li .dropdown-menu > li > span:focus,
            #mainMenu nav > ul > li .dropdown-menu > li > span:hover,
            #mainMenu nav > ul > li .dropdown-menu > li > span:active,
            #mainMenu nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a:focus,
            #mainMenu nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a:hover,
            #mainMenu nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a:active {
              background-color: transparent;
              color: #1abc9c;
              cursor: pointer; }
            #mainMenu nav > ul > li .dropdown-menu > li > a > i,
            #mainMenu nav > ul > li .dropdown-menu > li > span > i,
            #mainMenu nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a > i {
              margin-left: -4px;
              margin-right: 6px;
              min-width: 14px; }
          #mainMenu nav > ul > li .dropdown-menu > li.current > a,
          #mainMenu nav > ul > li .dropdown-menu > li.current > span, #mainMenu nav > ul > li .dropdown-menu > li:hover > a,
          #mainMenu nav > ul > li .dropdown-menu > li:hover > span, #mainMenu nav > ul > li .dropdown-menu > li:focus > a,
          #mainMenu nav > ul > li .dropdown-menu > li:focus > span, #mainMenu nav > ul > li .dropdown-menu > li:active > a,
          #mainMenu nav > ul > li .dropdown-menu > li:active > span, #mainMenu nav > ul > li .dropdown-menu > li.hover-active > a,
          #mainMenu nav > ul > li .dropdown-menu > li.hover-active > span {
            color: #f47629 !important; }
        #mainMenu nav > ul > li.mega-menu-item {
          position: static; }
          #mainMenu nav > ul > li.mega-menu-item > .dropdown-menu {
            left: 0;
            right: 0;
            overflow: hidden;
            width: 1140px;
            max-width: 100%; }
          #mainMenu nav > ul > li.mega-menu-item .mega-menu-content {
            padding: 20px; }
            #mainMenu nav > ul > li.mega-menu-item .mega-menu-content .mega-menu-title {
              font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
              font-size: 13px;
              line-height: 14px;
              font-weight: 700;
              text-transform: uppercase;
              padding-bottom: 12px; }
            #mainMenu nav > ul > li.mega-menu-item .mega-menu-content li ~ .mega-menu-title {
              margin-top: 12px; }
            #mainMenu nav > ul > li.mega-menu-item .mega-menu-content > a,
            #mainMenu nav > ul > li.mega-menu-item .mega-menu-content > span,
            #mainMenu nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li > a {
              padding: 10px 0px 10px 0px; }
              #mainMenu nav > ul > li.mega-menu-item .mega-menu-content > a > i,
              #mainMenu nav > ul > li.mega-menu-item .mega-menu-content > span > i,
              #mainMenu nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li > a > i {
                margin-left: 0px;
                margin-right: 8px; }
            #mainMenu nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li:first-child > a {
              padding-top: 0; }
            #mainMenu nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li:last-child > a {
              padding-bottom: 0; }
        #mainMenu nav > ul > li .dropdown-submenu {
          position: relative; }
          #mainMenu nav > ul > li .dropdown-submenu:before {
            line-height: 40px;
            content: "\f105";
            font-family: fontawesome;
            font-size: 12px;
            opacity: 0.6;
            position: absolute;
            right: 18px;
            color: #262626;
            transition: .3s ease; }
          #mainMenu nav > ul > li .dropdown-submenu > .dropdown-menu {
            margin-left: -6px;
            left: 100%;
            top: -1px; }
            #mainMenu nav > ul > li .dropdown-submenu > .dropdown-menu.menu-invert {
              right: 100%;
              left: auto; }
          #mainMenu nav > ul > li .dropdown-submenu:hover:before {
            right: 14px; }
          #mainMenu nav > ul > li .dropdown-submenu:hover > ul {
            opacity: 1;
            visibility: visible; }
        #mainMenu nav > ul > li:hover > ul {
          opacity: 1;
          visibility: visible; }
        #mainMenu nav > ul > li:last-child {
          margin-right: 0; }
      #mainMenu nav > ul > a.btn {
        margin-top: 4px; }
      #mainMenu nav > ul li ul {
        list-style: none;
        padding: 0; }
      #mainMenu nav > ul .label {
        font-size: 8px;
        padding: 2px 4px;
        line-height: 8px;
        margin: 0 4px; }
  #mainMenu.menu-fullwidth nav > ul > li.mega-menu-item > .dropdown-menu {
    left: 0 !important;
    right: 0 !important;
    width: 100%; }
  #mainMenu.menu-rounded nav > ul > li > a {
    border-radius: 4px;
    border-width: 2px; }
  #mainMenu.menu-rounded nav > ul > li.current > a, #mainMenu.menu-rounded nav > ul > li:hover > a, #mainMenu.menu-rounded nav > ul > li:focus > a {
    background-color: #1abc9c;
    color: #ffffff; }
  #mainMenu.menu-outline nav > ul > li > a {
    border-radius: 50px;
    border-width: 2px; }
  #mainMenu.menu-outline nav > ul > li.current > a, #mainMenu.menu-outline nav > ul > li:hover > a, #mainMenu.menu-outline nav > ul > li:focus > a {
    color: #1abc9c;
    border-color: #1abc9c;
    background-color: transparent; }
  #mainMenu.menu-sub-title nav > ul > li {
    line-height: 64px; }
    #mainMenu.menu-sub-title nav > ul > li > a > .menu-sub-title {
      font-size: 10px;
      padding: 0px;
      margin-top: -14px !important;
      line-height: 0;
      height: 0;
      text-transform: none;
      display: block;
      opacity: 0.5;
      font-weight: 400;
      margin: 0;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-bottom: -50px;
      margin-left: 14px;
      margin-right: 14px; }
    #mainMenu.menu-sub-title nav > ul > li .dropdown-submenu:before {
      top: -20px; }
  #mainMenu.menu-lines nav > ul > li {
    margin-left: 4px; }
    #mainMenu.menu-lines nav > ul > li > a {
      border-radius: 0;
      border: none; }
      #mainMenu.menu-lines nav > ul > li > a:after {
        content: '';
        position: absolute;
        top: auto;
        bottom: -2px;
        left: 50%;
        width: 0;
        height: 2px; }
    #mainMenu.menu-lines nav > ul > li.current > a, #mainMenu.menu-lines nav > ul > li:hover > a, #mainMenu.menu-lines nav > ul > li:focus > a {
      color: #1abc9c; }
      #mainMenu.menu-lines nav > ul > li.current > a:after, #mainMenu.menu-lines nav > ul > li:hover > a:after, #mainMenu.menu-lines nav > ul > li:focus > a:after {
        width: 100%;
        left: 0%;
        background-color: #1abc9c; }
  #mainMenu.menu-creative nav > ul > li {
    margin-left: 4px; }
    #mainMenu.menu-creative nav > ul > li > a:after {
      background-color: #1abc9c;
      content: "";
      height: 2px;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 100%;
      transform: translateY(10px);
      transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
      width: 100%;
      bottom: -2px; }
    #mainMenu.menu-creative nav > ul > li.current > a:after, #mainMenu.menu-creative nav > ul > li:hover > a:after, #mainMenu.menu-creative nav > ul > li:focus > a:after {
      opacity: 1;
      transform: translateY(0px); }
  #mainMenu.menu-slide nav > ul > li {
    visibility: hidden;
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    transition: all 0s cubic-bezier(0.175, 0.885, 0.525, 1.2);
    -webkit-transition-duration: 150ms;
    -moz-transition-duration: 150ms;
    -o-transition-duration: 150ms;
    transition-duration: 150ms; }
  #mainMenu.menu-slide nav > ul:nth-child(n+1) {
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-delay: 60ms;
    -moz-transition-delay: 60ms;
    -o-transition-delay: 60ms;
    transition-delay: 60ms; }
  #mainMenu.menu-slide nav > ul:nth-child(n+2) {
    -webkit-transition-duration: 350ms;
    -moz-transition-duration: 350ms;
    -o-transition-duration: 350ms;
    transition-duration: 350ms;
    -webkit-transition-delay: 120ms;
    -moz-transition-delay: 120ms;
    -o-transition-delay: 120ms;
    transition-delay: 120ms; }
  #mainMenu.menu-slide nav > ul:nth-child(n+3) {
    -webkit-transition-duration: 450ms;
    -moz-transition-duration: 450ms;
    -o-transition-duration: 450ms;
    transition-duration: 450ms;
    -webkit-transition-delay: 180ms;
    -moz-transition-delay: 180ms;
    -o-transition-delay: 180ms;
    transition-delay: 180ms; }
  #mainMenu.menu-slide nav > ul:nth-child(n+4) {
    -webkit-transition-duration: 550ms;
    -moz-transition-duration: 550ms;
    -o-transition-duration: 550ms;
    transition-duration: 550ms;
    -webkit-transition-delay: 240ms;
    -moz-transition-delay: 240ms;
    -o-transition-delay: 240ms;
    transition-delay: 240ms; }
  #mainMenu.menu-slide nav > ul:nth-child(n+5) {
    -webkit-transition-duration: 650ms;
    -moz-transition-duration: 650ms;
    -o-transition-duration: 650ms;
    transition-duration: 650ms;
    -webkit-transition-delay: .3s;
    -moz-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s; }
  #mainMenu.menu-slide nav > ul:nth-child(n+6) {
    -webkit-transition-duration: 750ms;
    -moz-transition-duration: 750ms;
    -o-transition-duration: 750ms;
    transition-duration: 750ms;
    -webkit-transition-delay: 360ms;
    -moz-transition-delay: 360ms;
    -o-transition-delay: 360ms;
    transition-delay: 360ms; }
  #mainMenu.menu-slide nav > ul:nth-child(n+7) {
    -webkit-transition-duration: 750ms;
    -moz-transition-duration: 750ms;
    -o-transition-duration: 750ms;
    transition-duration: 750ms;
    -webkit-transition-delay: 420ms;
    -moz-transition-delay: 420ms;
    -o-transition-delay: 420ms;
    transition-delay: 420ms; }
  #mainMenu.menu-slide nav > ul:nth-child(n+8) {
    -webkit-transition-duration: 750ms;
    -moz-transition-duration: 750ms;
    -o-transition-duration: 750ms;
    transition-duration: 750ms;
    -webkit-transition-delay: 480ms;
    -moz-transition-delay: 480ms;
    -o-transition-delay: 480ms;
    transition-delay: 480ms; }
  #mainMenu.menu-slide.items-visible nav > ul > li {
    visibility: visible;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1; }
    #mainMenu.menu-slide.items-visible nav > ul > li:nth-child(n+1) {
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-delay: 60ms;
      -moz-transition-delay: 60ms;
      -o-transition-delay: 60ms;
      transition-delay: 60ms; }
    #mainMenu.menu-slide.items-visible nav > ul > li:nth-child(n+2) {
      -webkit-transition-duration: 350ms;
      -moz-transition-duration: 350ms;
      -o-transition-duration: 350ms;
      transition-duration: 350ms;
      -webkit-transition-delay: 120ms;
      -moz-transition-delay: 120ms;
      -o-transition-delay: 120ms;
      transition-delay: 120ms; }
    #mainMenu.menu-slide.items-visible nav > ul > li:nth-child(n+3) {
      -webkit-transition-duration: 450ms;
      -moz-transition-duration: 450ms;
      -o-transition-duration: 450ms;
      transition-duration: 450ms;
      -webkit-transition-delay: 180ms;
      -moz-transition-delay: 180ms;
      -o-transition-delay: 180ms;
      transition-delay: 180ms; }
    #mainMenu.menu-slide.items-visible nav > ul > li:nth-child(n+4) {
      -webkit-transition-duration: 550ms;
      -moz-transition-duration: 550ms;
      -o-transition-duration: 550ms;
      transition-duration: 550ms;
      -webkit-transition-delay: 240ms;
      -moz-transition-delay: 240ms;
      -o-transition-delay: 240ms;
      transition-delay: 240ms; }
    #mainMenu.menu-slide.items-visible nav > ul > li:nth-child(n+5) {
      -webkit-transition-duration: 650ms;
      -moz-transition-duration: 650ms;
      -o-transition-duration: 650ms;
      transition-duration: 650ms;
      -webkit-transition-delay: .3s;
      -moz-transition-delay: .3s;
      -o-transition-delay: .3s;
      transition-delay: .3s; }
    #mainMenu.menu-slide.items-visible nav > ul > li:nth-child(n+6) {
      -webkit-transition-duration: 750ms;
      -moz-transition-duration: 750ms;
      -o-transition-duration: 750ms;
      transition-duration: 750ms;
      -webkit-transition-delay: 360ms;
      -moz-transition-delay: 360ms;
      -o-transition-delay: 360ms;
      transition-delay: 360ms; }
    #mainMenu.menu-slide.items-visible nav > ul > li:nth-child(n+7) {
      -webkit-transition-duration: 750ms;
      -moz-transition-duration: 750ms;
      -o-transition-duration: 750ms;
      transition-duration: 750ms;
      -webkit-transition-delay: 420ms;
      -moz-transition-delay: 420ms;
      -o-transition-delay: 420ms;
      transition-delay: 420ms; }
    #mainMenu.menu-slide.items-visible nav > ul > li:nth-child(n+8) {
      -webkit-transition-duration: 750ms;
      -moz-transition-duration: 750ms;
      -o-transition-duration: 750ms;
      transition-duration: 750ms;
      -webkit-transition-delay: 480ms;
      -moz-transition-delay: 480ms;
      -o-transition-delay: 480ms;
      transition-delay: 480ms; }
  #mainMenu.menu-hover-background nav > ul > li {
    margin-left: 0; }
    #mainMenu.menu-hover-background nav > ul > li > a {
      background: none; }
    #mainMenu.menu-hover-background nav > ul > li.current, #mainMenu.menu-hover-background nav > ul > li:hover, #mainMenu.menu-hover-background nav > ul > li:focus {
      background-color: #1abc9c; }
      #mainMenu.menu-hover-background nav > ul > li.current > a, #mainMenu.menu-hover-background nav > ul > li:hover > a, #mainMenu.menu-hover-background nav > ul > li:focus > a {
        color: #ffffff !important; }
  #mainMenu.menu-hover-background nav > ul .dropdown-menu {
    margin-top: 0;
    border-radius: 0; }
  #mainMenu.menu-overlay nav {
    opacity: 0 !important;
    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100% !important;
    background: rgba(37, 37, 37, 0.99);
    transition: opacity 0.5s ease; }
    #mainMenu.menu-overlay nav > ul {
      transition: transform 1s ease;
      transform: translateY(-80%);
      top: -80%;
      position: relative; }
      #mainMenu.menu-overlay nav > ul > li {
        float: none;
        text-align: center;
        max-width: 400px;
        margin: 0 auto;
        padding: 0px;
        line-height: 40px; }
        #mainMenu.menu-overlay nav > ul > li > a {
          opacity: 0;
          font-size: 32px;
          padding-top: 12px;
          padding-bottom: 12px;
          letter-spacing: 2px;
          text-transform: none;
          color: #ffffff; }
        #mainMenu.menu-overlay nav > ul > li .dropdown-menu,
        #mainMenu.menu-overlay nav > ul > li .dropdown-submenu {
          display: none !important;
          visibility: hidden !important;
          opacity: 0 !important; }
  #mainMenu.menu-left nav {
    float: left;
    margin-left: 20px; }
    #mainMenu.menu-left nav > ul > li.mega-menu-item {
      position: static; }
      #mainMenu.menu-left nav > ul > li.mega-menu-item > .dropdown-menu {
        right: auto;
        left: 0; }
  #mainMenu.menu-center > .container {
    float: none !important;
    text-align: center; }
  #mainMenu.menu-center nav {
    float: none;
    display: inline-block;
    vertical-align: top; }
  #mainMenu.menu-onclick nav > ul > li:hover > ul,
  #mainMenu.menu-onclick nav > ul > li .dropdown-submenu:hover > ul {
    opacity: 0 !important;
    visibility: hidden !important; }
  #mainMenu.menu-onclick nav > ul > li.hover-active > ul,
  #mainMenu.menu-onclick nav > ul > li .hover-active.dropdown-submenu > ul {
    opacity: 1 !important;
    visibility: visible !important; }
  #mainMenu.menu-rounded-dropdown nav > ul > li .dropdown-menu {
    border-radius: 6px; }
  #mainMenu.menu-rounded-dropdown nav > ul > li > .dropdown-menu {
    margin-top: -6px !important; }

/* ----------------------------------------------------------------
Dots Menu
-----------------------------------------------------------------*/
#dotsMenu {
  position: fixed;
  right: 36px;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  z-index: 99; }
  #dotsMenu:before {
    content: "";
    background: rgba(37, 37, 37, 0.7);
    position: absolute;
    width: 32px;
    right: -8px;
    height: 100%;
    border-radius: 20px;
    top: -24px;
    box-shadow: 0 4px 9px 0 rgba(43, 43, 49, 0.1), 0 7px 14px 0 rgba(43, 43, 49, 0.13); }
  #dotsMenu ul {
    list-style: none;
    padding-bottom: 6px; }
    #dotsMenu ul li {
      text-align: right;
      padding: 0px;
      width: 15px;
      height: 15px;
      margin-bottom: 12px;
      background-color: #eeeeee;
      border-radius: 50%;
      transform: scale(0.6);
      transition: transform 0.3s, background-color 0.5s; }
      #dotsMenu ul li:hover {
        background-color: #1abc9c;
        transform: scale(1); }
      #dotsMenu ul li.current {
        background-color: #1abc9c; }
      #dotsMenu ul li a {
        position: absolute;
        width: 40px;
        height: 40px;
        line-height: 1.5;
        border-radius: 50%;
        left: -14px;
        top: -14px; }
        #dotsMenu ul li a span {
          position: absolute;
          width: auto;
          top: 8px;
          right: 25px;
          white-space: nowrap;
          margin-right: 14px;
          font-size: 12px;
          font-weight: 800;
          color: #ffffff;
          transition: transform 0.2s, opacity 0.2s;
          opacity: 0;
          transform-origin: 100% 50%;
          background-color: rgba(0, 0, 0, 0.6);
          border-radius: 100px;
          line-height: 26px;
          padding: 0 12px;
          text-transform: uppercase; }
        #dotsMenu ul li a:hover:before {
          transform: scale(1); }
        #dotsMenu ul li a:hover:before, #dotsMenu ul li a.active:before {
          background-color: #1abc9c; }
        #dotsMenu ul li a:hover > span, #dotsMenu ul li a.active > span {
          opacity: 1;
          transform: scale(1); }

/* ----------------------------------------------------------------
Overlay Menu
-----------------------------------------------------------------*/
.menu-overlay-active #topbar.topbar-transparent.topbar-fullwidth,
.menu-overlay-active #header.header-fullwidth.header-transparent #header-wrap,
.menu-overlay-active #header.header-fullwidth.header-transparent #header-wrap .header-extras {
  border: 0; }
.menu-overlay-active #header.header-sticky.dark #header-wrap {
  background-color: transparent !important; }
.menu-overlay-active #header #mainMenu {
  z-index: 200; }
  .menu-overlay-active #header #mainMenu nav {
    opacity: 1 !important;
    display: inherit !important;
    pointer-events: auto; }
    .menu-overlay-active #header #mainMenu nav > ul {
      transform: translateY(-50%);
      top: 50%; }
      .menu-overlay-active #header #mainMenu nav > ul > li > a {
        opacity: 1; }
.menu-overlay-active #header .header-extras a:not(#menu-overlay-trigger) {
  opacity: 0;
  visibility: hidden; }
.menu-overlay-active #header #menu-overlay-trigger .lines, .menu-overlay-active #header #menu-overlay-trigger .lines:before, .menu-overlay-active #header #menu-overlay-trigger .lines:after {
  background-color: #ffffff; }

/*Menu split*/
.header-logo-center #logo,
.menu-split #logo {
  position: absolute !important;
  width: 100%;
  text-align: center;
  margin: 0;
  float: none;
  height: 100px; }
  .header-logo-center #logo > a,
  .menu-split #logo > a {
    display: inline-block; }
.header-logo-center #mainMenu nav,
.menu-split #mainMenu nav {
  float: none;
  margin: 0;
  position: relative;
  z-index: 1; }
  .header-logo-center #mainMenu nav > ul,
  .menu-split #mainMenu nav > ul {
    z-index: 198; }
    .header-logo-center #mainMenu nav > ul:first-child,
    .menu-split #mainMenu nav > ul:first-child {
      float: left; }
    .header-logo-center #mainMenu nav > ul:last-child,
    .menu-split #mainMenu nav > ul:last-child {
      float: right; }

/*Menu Dark*/
.dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li:before,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li:before {
  color: #9d9d9d; }
.dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu {
  background-color: #252525;
  border: 1px solid #292929; }
  .dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li > a,
  .dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li > span,
  .dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a,
  #mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li > a,
  #mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li > span,
  #mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a {
    color: #9d9d9d; }
    .dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li > a:hover,
    .dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li > span:hover,
    .dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a:hover,
    #mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li > a:hover,
    #mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li > span:hover,
    #mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a:hover {
      color: #1abc9c; }
.dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li .dropdown-submenu:before,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li .dropdown-submenu:before {
  color: #9d9d9d; }
.dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li.mega-menu-item .mega-menu-content .mega-menu-title,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li.mega-menu-item .mega-menu-content .mega-menu-title {
  color: #eeeeee; }
.dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li.current > a,
.dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li.current > span, .dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li:hover > a,
.dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li:hover > span, .dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li:focus > a,
.dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li:focus > span, .dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li.hover-active > a,
.dark #mainMenu:not(.light):not(.menu-rounded) nav > ul > li.hover-active > span,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li.current > a,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li.current > span,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li:hover > a,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li:hover > span,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li:focus > a,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li:focus > span,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li.hover-active > a,
#mainMenu.dark:not(.light):not(.menu-rounded) nav > ul > li.hover-active > span {
  color: #1abc9c; }

#menu-overlay-trigger {
  z-index: 299 !important; }

/*Menu on header fullwidth */
#header.header-fullwidth #mainMenu:not(.menu-left):not(.menu-center) nav > ul > li.mega-menu-item > .dropdown-menu {
  left: auto;
  right: 0; }
#header.header-fullwidth #mainMenu.menu-center nav > ul > li.mega-menu-item > .dropdown-menu {
  right: auto;
  left: 50%;
  transform: translateX(-50%); }

/* ----------------------------------------------------------------
Page Menu
-----------------------------------------------------------------*/
.page-menu {
  position: relative;
  height: 46px;
  line-height: 46px;
  background-color: #f3f3f3; }
  .page-menu > .container {
    position: relative; }
  .page-menu .menu-title {
    float: left;
    color: #565656;
    font-size: 18px; }
    .page-menu .menu-title span {
      font-weight: 600; }
  .page-menu nav {
    float: right; }
    .page-menu nav ul {
      list-style: none;
      padding: 0;
      margin: 0;
      position: relative; }
    .page-menu nav li {
      position: relative;
      margin-right: 2px;
      border: 0;
      display: inline-block;
      float: none; }
      .page-menu nav li a {
        display: block;
        position: relative;
        padding: 8px 10px;
        font-size: 13px;
        line-height: 12px;
        color: #565656;
        border-radius: 50px;
        transition: all .3s ease;
        border-width: 2px;
        border-style: solid;
        border-color: transparent; }
      .page-menu nav li:hover a, .page-menu nav li.active a {
        color: #ffffff;
        background-color: #1abc9c; }
      .page-menu nav li:last-child {
        margin-right: 0; }
  .page-menu.menu-outline nav li:hover a, .page-menu.menu-outline nav li.active a {
    border-color: #1abc9c;
    color: #1abc9c;
    background-color: transparent; }
  .page-menu.menu-lines nav li a {
    border-radius: 0;
    border: none;
    background-color: transparent; }
    .page-menu.menu-lines nav li a:after {
      content: '';
      position: absolute;
      top: auto;
      bottom: -8px;
      left: 50%;
      width: 0;
      height: 2px;
      transition: all 0.3s ease; }
  .page-menu.menu-lines nav li:hover a, .page-menu.menu-lines nav li.active a {
    color: #1abc9c; }
    .page-menu.menu-lines nav li:hover a:after, .page-menu.menu-lines nav li.active a:after {
      width: 100%;
      left: 0%;
      background-color: #1abc9c; }
  .page-menu.menu-line-bottom {
    border-bottom: 2px solid #ebebeb;
    background-color: #ffffff !important; }
  .page-menu.menu-classic nav li a {
    color: #565656;
    border-radius: 4px; }
  .page-menu.menu-classic nav li:hover a, .page-menu.menu-classic nav li.active a {
    background-color: #1abc9c;
    color: #ffffff; }
  .page-menu.menu-light nav li a {
    color: #565656;
    background-color: transparent; }
  .page-menu.menu-light nav li:hover a, .page-menu.menu-light nav li.active a {
    border-color: #ebebeb; }
  .page-menu.menu-dark nav li a {
    color: #252525;
    background-color: transparent; }
  .page-menu.menu-dark nav li:hover a, .page-menu.menu-dark nav li.active a {
    border-color: #252525; }
  .page-menu.menu-default nav li a {
    color: none;
    border-radius: 0;
    background-color: transparent; }
  .page-menu.menu-default nav li:hover a, .page-menu.menu-default nav li.active a {
    background-color: transparent;
    color: #1abc9c; }
  .page-menu.menu-creative nav li a {
    border-radius: 0;
    border: none;
    background-color: transparent; }
    .page-menu.menu-creative nav li a:after {
      background: #252525;
      content: "";
      height: 2px;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 100%;
      transform: translateY(10px);
      transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
      width: 100%; }
  .page-menu.menu-creative nav li:hover a, .page-menu.menu-creative nav li.active a {
    color: #565656; }
    .page-menu.menu-creative nav li:hover a:after, .page-menu.menu-creative nav li.active a:after {
      opacity: 1;
      transform: translateY(0px); }
  .page-menu.menu-colored-background {
    background-color: #1abc9c; }
    .page-menu.menu-colored-background .menu-title {
      color: #ffffff; }
    .page-menu.menu-colored-background nav li a {
      color: #ffffff;
      border-width: 0; }
    .page-menu.menu-colored-background nav li:hover a, .page-menu.menu-colored-background nav li.active a {
      color: #1abc9c;
      background-color: #ffffff; }

#menu-responsive-icon {
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 5px;
  width: 36px;
  z-index: 12; }

body.top-search-active #mainMenu:not(#top-search),
body.top-search-active .logo,
body.top-search-active .nav-main-menu-responsive {
  opacity: 0; }

#header #top-search form {
  opacity: 0;
  z-index: -2;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  padding: 0 15px;
  margin: 0;
  top: 0;
  left: 0;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out; }

#header.header-fullwidth #top-search form {
  left: 15px; }

body.top-search-active #header #top-search form {
  opacity: 1;
  z-index: 10; }

body.top-search-active #mainMenu-trigger {
  display: none; }

#header #top-search form input {
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  box-shadow: none !important;
  font-size: 30px;
  height: 100%;
  letter-spacing: 2px;
  outline: 0 none;
  padding: 0 94px 0 0; }

#header.header-fullwidth #top-search form input {
  padding: 0 150px 0 0; }

/* ----------------------------------------------------------------
Side Push Panel
-----------------------------------------------------------------*/
#side-panel {
  background-color: #eeeeee;
  height: 100%;
  width: 300px;
  position: fixed;
  left: -300px;
  z-index: 399;
  height: 100%;
  transition: left 0.4s, right 0.4s;
  overflow: hidden;
  /*dark version*/ }
  #side-panel #close-panel {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100; }
    #side-panel #close-panel > i {
      cursor: pointer;
      display: block;
      width: 40px;
      height: 40px;
      font-size: 18px;
      line-height: 40px;
      color: #252525;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 0 0 0 2px; }
  #side-panel .side-panel-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    right: -18px;
    bottom: 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 30px; }
    #side-panel .side-panel-wrap .logo {
      margin-bottom: 20px; }
  #side-panel.dark {
    background-color: #252525;
    /*Main Menu*/ }
    #side-panel.dark #mainMenu nav > ul > li:before {
      color: #9d9d9d; }
    #side-panel.dark #mainMenu nav > ul > li > a {
      color: #eeeeee; }
      #side-panel.dark #mainMenu nav > ul > li > a .menu-sub-title {
        color: #eeeeee; }
    #side-panel.dark #close-panel > i {
      color: #eeeeee;
      background-color: rgba(255, 255, 255, 0.1); }

.side-panel {
  /* Static */
  /* Reval */ }
  .side-panel #wrapper {
    height: 100%;
    position: relative;
    transition: left 0.4s, right 0.4s;
    left: 0; }
    .side-panel #wrapper:after {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      background: rgba(0, 0, 0, 0.4);
      content: '';
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 9999; }
  .side-panel.side-panel-static #side-panel {
    left: 0 !important;
    right: auto !important; }
  .side-panel.side-panel-static #close-panel,
  .side-panel.side-panel-static #side-panel-trigger {
    display: none; }
  .side-panel.side-panel-static #wrapper {
    padding-left: 300px;
    transition: left 0.4s, right 0.4s; }
    .side-panel.side-panel-static #wrapper > section > .container,
    .side-panel.side-panel-static #wrapper > footer .footer-content > .container,
    .side-panel.side-panel-static #wrapper > footer .copyright-content > .container,
    .side-panel.side-panel-static #wrapper > div > .container {
      padding-left: 30px;
      width: auto;
      padding-right: 30px; }
    .side-panel.side-panel-static #wrapper #header.header-sticky #header-wrap > .container {
      padding-left: 330px; }
  .side-panel.side-panel-static.side-panel-right #side-panel {
    right: 0 !important;
    left: auto !important; }
  .side-panel.side-panel-static.side-panel-right #wrapper {
    padding-left: 0;
    padding-right: 300px; }
    .side-panel.side-panel-static.side-panel-right #wrapper #header.header-sticky #header-wrap > .container {
      padding-right: 330px;
      padding-left: 30px; }
  .side-panel.side-panel-reval #side-panel {
    z-index: -1;
    left: 0;
    right: auto; }
  .side-panel.side-panel-reval #wrapper {
    left: 0;
    right: auto;
    transition: left 0.4s, right 0.4s; }
  .side-panel.side-panel-active {
    /* Effect 1: Slide in on top */
    /* Effect 2: Reval */ }
    .side-panel.side-panel-active.side-panel-slide-in #side-panel {
      left: 0;
      right: auto; }
    .side-panel.side-panel-active.side-panel-slide-in #wrapper {
      cursor: url(../src/images/close.png), auto; }
      .side-panel.side-panel-active.side-panel-slide-in #wrapper:after {
        opacity: 1;
        width: 100%;
        height: 100%;
        z-index: 299; }
    .side-panel.side-panel-active.side-panel-reval #wrapper {
      left: 300px;
      right: auto;
      box-shadow: -7px -2px 12px 0 rgba(0, 0, 0, 0.1); }
      .side-panel.side-panel-active.side-panel-reval #wrapper #header.header-sticky #header-wrap {
        left: 300px;
        right: -300px; }
    .side-panel.side-panel-active.side-panel-push #side-panel {
      left: 0;
      right: auto; }
    .side-panel.side-panel-active.side-panel-push #wrapper {
      left: 300px;
      cursor: url(../src/images/close.png), auto; }
      .side-panel.side-panel-active.side-panel-push #wrapper:after {
        opacity: 1;
        width: 100%;
        height: 100%; }
      .side-panel.side-panel-active.side-panel-push #wrapper #header.header-sticky #header-wrap > .container {
        left: 300px;
        right: 0; }
  .side-panel.side-panel-right #side-panel {
    right: -300px;
    left: auto; }
    .side-panel.side-panel-right #side-panel #close-panel {
      left: 0;
      right: auto; }
  .side-panel.side-panel-right #wrapper {
    left: auto;
    right: 0; }
    .side-panel.side-panel-right #wrapper #header.header-sticky #header-wrap > .container {
      transition: left 0.4s, right 0.4s; }
  .side-panel.side-panel-right.side-panel-reval #side-panel {
    left: auto;
    right: 0; }
  .side-panel.side-panel-right.side-panel-reval #wrapper {
    left: auto;
    right: 0;
    transition: left 0.4s, right 0.4s;
    box-shadow: 12px -2px 12px 0 rgba(0, 0, 0, 0.1); }
  .side-panel.side-panel-right.side-panel-active.side-panel-slide-in #side-panel {
    right: 0;
    left: auto; }
  .side-panel.side-panel-right.side-panel-active.side-panel-reval #wrapper {
    right: 300px;
    left: auto; }
    .side-panel.side-panel-right.side-panel-active.side-panel-reval #wrapper #header.header-sticky #header-wrap {
      right: 300px;
      left: -300px; }
  .side-panel.side-panel-right.side-panel-active.side-panel-push #side-panel {
    right: 0;
    left: auto; }
  .side-panel.side-panel-right.side-panel-active.side-panel-push #wrapper {
    right: 300px;
    left: auto; }
    .side-panel.side-panel-right.side-panel-active.side-panel-push #wrapper #header.header-sticky #header-wrap > .container {
      transition: left 0.4s, right 0.4s;
      right: 300px;
      left: auto; }

body.side-panel-static #wrapper .container {
  max-width: 1210px;
  padding: 0 35px;
  width: auto !important; }

/* ----------------------------------------------------------------
Main menu - Vertical
-----------------------------------------------------------------*/
#mainMenu.menu-vertical,
#mainMenu.menu-center.menu-vertical {
  max-height: 3000px;
  clear: both;
  display: block;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  transition: max-height 0.8s cubic-bezier(0.79, 0.14, 0.15, 0.86), opacity 0.8s cubic-bezier(0.79, 0.14, 0.15, 0.86); }
  #mainMenu.menu-vertical > .container,
  #mainMenu.menu-center.menu-vertical > .container {
    text-align: left !important;
    width: 100% !important; }
  #mainMenu.menu-vertical nav,
  #mainMenu.menu-center.menu-vertical nav {
    line-height: 40px;
    float: none;
    width: 100%;
    padding-bottom: 20px; }
    #mainMenu.menu-vertical nav > ul,
    #mainMenu.menu-center.menu-vertical nav > ul {
      float: none;
      width: 100%; }
      #mainMenu.menu-vertical nav > ul > li,
      #mainMenu.menu-center.menu-vertical nav > ul > li {
        padding: 0;
        margin: 0;
        clear: both;
        float: none;
        display: block;
        border: 0; }
        #mainMenu.menu-vertical nav > ul > li.dropdown:before,
        #mainMenu.menu-center.menu-vertical nav > ul > li.dropdown:before {
          /*line-height: 40px;*/
          content: "\f105";
          font-family: fontawesome;
          font-size: 16px;
          opacity: 0.6;
          position: absolute;
          right: 2px;
          color: #262626;
          transition: .3s ease;
          transform: rotate(90deg); }
        #mainMenu.menu-vertical nav > ul > li > a,
        #mainMenu.menu-center.menu-vertical nav > ul > li > a {
          display: block;
          padding: 12px 0;
          font-size: 13px !important;
          border: 0;
          border-radius: 0; }
          #mainMenu.menu-vertical nav > ul > li > a:after,
          #mainMenu.menu-center.menu-vertical nav > ul > li > a:after {
            display: none; }
          #mainMenu.menu-vertical nav > ul > li > a .menu-sub-title,
          #mainMenu.menu-center.menu-vertical nav > ul > li > a .menu-sub-title {
            display: none !important; }
        #mainMenu.menu-vertical nav > ul > li .dropdown-menu,
        #mainMenu.menu-center.menu-vertical nav > ul > li .dropdown-menu {
          background-image: none !important;
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          transition: max-height 0.5s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.5s cubic-bezier(0.77, 0, 0.175, 1);
          position: static;
          clear: both;
          float: none;
          box-shadow: none;
          border: 0 !important;
          min-width: 100%;
          margin: 0;
          border-radius: 0px;
          box-shadow: none;
          padding: 0 16px; }
          #mainMenu.menu-vertical nav > ul > li .dropdown-menu > li > a,
          #mainMenu.menu-vertical nav > ul > li .dropdown-menu > li > span,
          #mainMenu.menu-vertical nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a,
          #mainMenu.menu-center.menu-vertical nav > ul > li .dropdown-menu > li > a,
          #mainMenu.menu-center.menu-vertical nav > ul > li .dropdown-menu > li > span,
          #mainMenu.menu-center.menu-vertical nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a {
            padding: 12px 0;
            display: block; }
        #mainMenu.menu-vertical nav > ul > li.mega-menu-item,
        #mainMenu.menu-center.menu-vertical nav > ul > li.mega-menu-item {
          position: relative; }
          #mainMenu.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content,
          #mainMenu.menu-center.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content {
            padding: 0 0 0 15px; }
            #mainMenu.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content .mega-menu-title,
            #mainMenu.menu-center.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content .mega-menu-title {
              padding-top: 12px;
              padding-bottom: 12px; }
            #mainMenu.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content > a,
            #mainMenu.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content > span,
            #mainMenu.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li > a,
            #mainMenu.menu-center.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content > a,
            #mainMenu.menu-center.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content > span,
            #mainMenu.menu-center.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li > a {
              padding: 10px 0px 10px 0px; }
            #mainMenu.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li:first-child > a,
            #mainMenu.menu-center.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li:first-child > a {
              padding-top: 12px; }
            #mainMenu.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li:last-child > a,
            #mainMenu.menu-center.menu-vertical nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li:last-child > a {
              padding-bottom: 12px; }
        #mainMenu.menu-vertical nav > ul > li .dropdown-submenu,
        #mainMenu.menu-center.menu-vertical nav > ul > li .dropdown-submenu {
          position: relative; }
          #mainMenu.menu-vertical nav > ul > li .dropdown-submenu:before,
          #mainMenu.menu-center.menu-vertical nav > ul > li .dropdown-submenu:before {
            right: 0;
            top: 0;
            font-size: 16px; }
      #mainMenu.menu-vertical nav > ul li > .dropdown-menu,
      #mainMenu.menu-center.menu-vertical nav > ul li > .dropdown-menu {
        border: 0 none;
        background-color: transparent;
        display: block; }
        #mainMenu.menu-vertical nav > ul li > .dropdown-menu > .dropdown-submenu,
        #mainMenu.menu-center.menu-vertical nav > ul li > .dropdown-menu > .dropdown-submenu {
          position: relative; }
          #mainMenu.menu-vertical nav > ul li > .dropdown-menu > .dropdown-submenu:before,
          #mainMenu.menu-center.menu-vertical nav > ul li > .dropdown-menu > .dropdown-submenu:before {
            right: 0;
            transform: rotate(90deg); }
      #mainMenu.menu-vertical nav > ul li.hover-active > .dropdown-menu,
      #mainMenu.menu-center.menu-vertical nav > ul li.hover-active > .dropdown-menu {
        visibility: visible;
        opacity: 1;
        max-height: 2000px; }
      #mainMenu.menu-vertical nav > ul [class*="col-md"],
      #mainMenu.menu-center.menu-vertical nav > ul [class*="col-md"] {
        width: 100%;
        padding: 0;
        margin: 0; }
  #mainMenu.menu-vertical.menu-sub-title nav > ul > li,
  #mainMenu.menu-center.menu-vertical.menu-sub-title nav > ul > li {
    line-height: unset !important; }
    #mainMenu.menu-vertical.menu-sub-title nav > ul > li .dropdown-submenu:before,
    #mainMenu.menu-center.menu-vertical.menu-sub-title nav > ul > li .dropdown-submenu:before {
      top: 0; }
  #mainMenu.menu-vertical.menu-slide nav > ul > li,
  #mainMenu.menu-center.menu-vertical.menu-slide nav > ul > li {
    visibility: visible;
    opacity: 1; }
  #mainMenu.menu-vertical.menu-rounded nav > ul > li.current > a, #mainMenu.menu-vertical.menu-rounded nav > ul > li:hover > a, #mainMenu.menu-vertical.menu-rounded nav > ul > li:focus > a,
  #mainMenu.menu-center.menu-vertical.menu-rounded nav > ul > li.current > a,
  #mainMenu.menu-center.menu-vertical.menu-rounded nav > ul > li:hover > a,
  #mainMenu.menu-center.menu-vertical.menu-rounded nav > ul > li:focus > a {
    background-color: unset !important; }
  #mainMenu.menu-vertical.menu-hover-background nav > ul > li.current, #mainMenu.menu-vertical.menu-hover-background nav > ul > li:hover, #mainMenu.menu-vertical.menu-hover-background nav > ul > li:focus,
  #mainMenu.menu-center.menu-vertical.menu-hover-background nav > ul > li.current,
  #mainMenu.menu-center.menu-vertical.menu-hover-background nav > ul > li:hover,
  #mainMenu.menu-center.menu-vertical.menu-hover-background nav > ul > li:focus {
    background-color: unset; }
    #mainMenu.menu-vertical.menu-hover-background nav > ul > li.current > a, #mainMenu.menu-vertical.menu-hover-background nav > ul > li:hover > a, #mainMenu.menu-vertical.menu-hover-background nav > ul > li:focus > a,
    #mainMenu.menu-center.menu-vertical.menu-hover-background nav > ul > li.current > a,
    #mainMenu.menu-center.menu-vertical.menu-hover-background nav > ul > li:hover > a,
    #mainMenu.menu-center.menu-vertical.menu-hover-background nav > ul > li:focus > a {
      color: unset !important; }

/* ----------------------------------------------------------------
HEADER: Responsive
-----------------------------------------------------------------*/
/*@media (min-width: 1550px) {
    #header:not(.menu-split).header-fullwidth {
        #mainMenu {
            >.container {
                float: right;
            }
            &.menu-left {
                >.container {
                    float: left !important;
                }
            }
        }
    }
}*/
@media (max-width: 991px) {
  /*TopBar*/
  #topbar.topbar-transparent.dark {
    background-color: #ffffff !important; }
    #topbar.topbar-transparent.dark .top-menu > li > a,
    #topbar.topbar-transparent.dark .social-icons li a {
      color: #565656 !important; }

  /*Header*/
  #header.header-fullwidth #header-wrap .container {
    padding: 0 15px; }
    #header.header-fullwidth #header-wrap .container .header-extras {
      border: 0px; }
  #header #logo {
    position: absolute !important;
    width: 100%;
    text-align: center;
    margin: 0 !important;
    float: none;
    height: 100px;
    left: 0;
    right: 0;
    padding: 0 !important; }
    #header #logo > a {
      display: inline-block; }
  #header.header-transparent + #slider,
  #header.header-transparent + #page-title:not(.page-title-classic),
  #header.header-transparent + .fullscreen,
  #header.header-transparent + .halfscreen,
  #header.header-transparent + #google-map, #header.header-light-transparent + #slider,
  #header.header-light-transparent + #page-title:not(.page-title-classic),
  #header.header-light-transparent + .fullscreen,
  #header.header-light-transparent + .halfscreen,
  #header.header-light-transparent + #google-map, #header.header-dark-transparent + #slider,
  #header.header-dark-transparent + #page-title:not(.page-title-classic),
  #header.header-dark-transparent + .fullscreen,
  #header.header-dark-transparent + .halfscreen,
  #header.header-dark-transparent + #google-map, #header.header-colored-transparent + #slider,
  #header.header-colored-transparent + #page-title:not(.page-title-classic),
  #header.header-colored-transparent + .fullscreen,
  #header.header-colored-transparent + .halfscreen,
  #header.header-colored-transparent + #google-map, #header.header-modern + #slider,
  #header.header-modern + #page-title:not(.page-title-classic),
  #header.header-modern + .fullscreen,
  #header.header-modern + .halfscreen,
  #header.header-modern + #google-map {
    top: 0 !important;
    margin-bottom: 0 !important; }
  #header .dark, #header.dark {
    /*Main Menu*/ }
    #header .dark #header-wrap, #header.dark #header-wrap {
      background-color: #252525; }
      #header .dark #header-wrap .lines,
      #header .dark #header-wrap .lines:before,
      #header .dark #header-wrap .lines:after, #header.dark #header-wrap .lines,
      #header.dark #header-wrap .lines:before,
      #header.dark #header-wrap .lines:after {
        background-color: #ffffff; }
    #header .dark #mainMenu nav > ul > li > a, #header.dark #mainMenu nav > ul > li > a {
      color: #eeeeee; }
      #header .dark #mainMenu nav > ul > li > a .menu-sub-title, #header.dark #mainMenu nav > ul > li > a .menu-sub-title {
        color: #eeeeee; }
    #header .dark #mainMenu nav > ul > li.current > a:after, #header .dark #mainMenu nav > ul > li:hover > a:after, #header .dark #mainMenu nav > ul > li:focus > a:after, #header.dark #mainMenu nav > ul > li.current > a:after, #header.dark #mainMenu nav > ul > li:hover > a:after, #header.dark #mainMenu nav > ul > li:focus > a:after {
      background-color: #ffffff; }
    #header .dark .header-extras i, #header.dark .header-extras i {
      color: #ffffff; }
    #header .dark .header-extras .lines,
    #header .dark .header-extras .lines:before,
    #header .dark .header-extras .lines:after, #header.dark .header-extras .lines,
    #header.dark .header-extras .lines:before,
    #header.dark .header-extras .lines:after {
      background-color: #ffffff; }
  #header.header-transparent, #header.dark.header-transparent, #header.dark.header-colored, #header.dark.header-colored-transparent {
    /*Main Menu*/ }
    #header.header-transparent #header-wrap, #header.dark.header-transparent #header-wrap, #header.dark.header-colored #header-wrap, #header.dark.header-colored-transparent #header-wrap {
      background-color: #ffffff; }
      #header.header-transparent #header-wrap .lines,
      #header.header-transparent #header-wrap .lines:before,
      #header.header-transparent #header-wrap .lines:after, #header.dark.header-transparent #header-wrap .lines,
      #header.dark.header-transparent #header-wrap .lines:before,
      #header.dark.header-transparent #header-wrap .lines:after, #header.dark.header-colored #header-wrap .lines,
      #header.dark.header-colored #header-wrap .lines:before,
      #header.dark.header-colored #header-wrap .lines:after, #header.dark.header-colored-transparent #header-wrap .lines,
      #header.dark.header-colored-transparent #header-wrap .lines:before,
      #header.dark.header-colored-transparent #header-wrap .lines:after {
        background-color: #252525; }
    #header.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li > a, #header.dark.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li > a, #header.dark.header-colored #mainMenu:not(.menu-overlay) nav > ul > li > a, #header.dark.header-colored-transparent #mainMenu:not(.menu-overlay) nav > ul > li > a {
      color: #252525; }
      #header.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li > a .menu-sub-title, #header.dark.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li > a .menu-sub-title, #header.dark.header-colored #mainMenu:not(.menu-overlay) nav > ul > li > a .menu-sub-title, #header.dark.header-colored-transparent #mainMenu:not(.menu-overlay) nav > ul > li > a .menu-sub-title {
        color: #252525; }
    #header.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu, #header.dark.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu, #header.dark.header-colored #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu, #header.dark.header-colored-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu {
      background-color: #ffffff; }
      #header.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > a,
      #header.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > span,
      #header.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a, #header.dark.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > a,
      #header.dark.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > span,
      #header.dark.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a, #header.dark.header-colored #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > a,
      #header.dark.header-colored #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > span,
      #header.dark.header-colored #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a, #header.dark.header-colored-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > a,
      #header.dark.header-colored-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > span,
      #header.dark.header-colored-transparent #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a {
        color: #252525; }
    #header.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li.current > a:after, #header.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li:hover > a:after, #header.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li:focus > a:after, #header.dark.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li.current > a:after, #header.dark.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li:hover > a:after, #header.dark.header-transparent #mainMenu:not(.menu-overlay) nav > ul > li:focus > a:after, #header.dark.header-colored #mainMenu:not(.menu-overlay) nav > ul > li.current > a:after, #header.dark.header-colored #mainMenu:not(.menu-overlay) nav > ul > li:hover > a:after, #header.dark.header-colored #mainMenu:not(.menu-overlay) nav > ul > li:focus > a:after, #header.dark.header-colored-transparent #mainMenu:not(.menu-overlay) nav > ul > li.current > a:after, #header.dark.header-colored-transparent #mainMenu:not(.menu-overlay) nav > ul > li:hover > a:after, #header.dark.header-colored-transparent #mainMenu:not(.menu-overlay) nav > ul > li:focus > a:after {
      background-color: #252525; }
    #header.header-transparent .header-extras i, #header.dark.header-transparent .header-extras i, #header.dark.header-colored .header-extras i, #header.dark.header-colored-transparent .header-extras i {
      color: #252525; }
    #header.header-transparent .header-extras .lines,
    #header.header-transparent .header-extras .lines:before,
    #header.header-transparent .header-extras .lines:after, #header.dark.header-transparent .header-extras .lines,
    #header.dark.header-transparent .header-extras .lines:before,
    #header.dark.header-transparent .header-extras .lines:after, #header.dark.header-colored .header-extras .lines,
    #header.dark.header-colored .header-extras .lines:before,
    #header.dark.header-colored .header-extras .lines:after, #header.dark.header-colored-transparent .header-extras .lines,
    #header.dark.header-colored-transparent .header-extras .lines:before,
    #header.dark.header-colored-transparent .header-extras .lines:after {
      background-color: #252525; }
  #header.header-modern {
    margin: 0 !important;
    width: 100% !important; }
    #header.header-modern #header-wrap {
      border-radius: 0px !important; }
  #header.header-logo-right #header-wrap .header-extras {
    float: right; }
  #header .header-extras [class*="social-"] {
    margin-left: 10px; }
  #header #mainMenu-trigger {
    position: relative;
    opacity: 1;
    visibility: visible; }
  #header #mainMenu:not(.menu-overlay) {
    max-height: 0;
    clear: both;
    display: block;
    width: 100%;
    opacity: 1;
    overflow: hidden;
    transition: max-height 0.8s cubic-bezier(0.79, 0.14, 0.15, 0.86), opacity 0.8s cubic-bezier(0.79, 0.14, 0.15, 0.86); }
    #header #mainMenu:not(.menu-overlay) > .container {
      text-align: left !important;
      width: 100%;
      max-width: 100%; }
    #header #mainMenu:not(.menu-overlay) nav {
      line-height: 40px;
      float: none;
      width: 100%;
      padding-bottom: 20px; }
      #header #mainMenu:not(.menu-overlay) nav > ul {
        float: none;
        width: 100%; }
        #header #mainMenu:not(.menu-overlay) nav > ul > li {
          padding: 0;
          margin: 0;
          clear: both;
          float: none;
          display: block;
          border: 0; }
          #header #mainMenu:not(.menu-overlay) nav > ul > li.dropdown:before {
            /*line-height: 40px;*/
            content: "\f105";
            font-family: fontawesome;
            font-size: 16px;
            opacity: 0.6;
            position: absolute;
            right: 2px;
            color: #262626;
            transition: .3s ease;
            transform: rotate(90deg); }
          #header #mainMenu:not(.menu-overlay) nav > ul > li > a {
            display: block;
            padding: 12px 0;
            font-size: 13px !important;
            border: 0;
            border-radius: 0; }
            #header #mainMenu:not(.menu-overlay) nav > ul > li > a:after {
              display: none; }
            #header #mainMenu:not(.menu-overlay) nav > ul > li > a .menu-sub-title {
              display: none !important; }
          #header #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu {
            background-image: none !important;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transition: max-height 0.5s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.5s cubic-bezier(0.77, 0, 0.175, 1);
            position: static;
            clear: both;
            float: none;
            box-shadow: none;
            border: 0 !important;
            min-width: 100%;
            margin: 0;
            border-radius: 0px;
            box-shadow: none;
            padding: 0 16px; }
            #header #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > a,
            #header #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li > span,
            #header #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-menu > li [class*="col-"] > ul > li > a {
              padding: 12px 0;
              display: block; }
          #header #mainMenu:not(.menu-overlay) nav > ul > li.mega-menu-item {
            position: relative; }
            #header #mainMenu:not(.menu-overlay) nav > ul > li.mega-menu-item .mega-menu-content {
              padding: 0 0 0 15px; }
              #header #mainMenu:not(.menu-overlay) nav > ul > li.mega-menu-item .mega-menu-content .mega-menu-title {
                padding-top: 12px;
                padding-bottom: 12px; }
              #header #mainMenu:not(.menu-overlay) nav > ul > li.mega-menu-item .mega-menu-content > a,
              #header #mainMenu:not(.menu-overlay) nav > ul > li.mega-menu-item .mega-menu-content > span,
              #header #mainMenu:not(.menu-overlay) nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li > a {
                padding: 10px 0px 10px 0px; }
              #header #mainMenu:not(.menu-overlay) nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li:first-child > a {
                padding-top: 12px; }
              #header #mainMenu:not(.menu-overlay) nav > ul > li.mega-menu-item .mega-menu-content [class*="col-"] > ul > li:last-child > a {
                padding-bottom: 12px; }
          #header #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-submenu {
            position: relative; }
            #header #mainMenu:not(.menu-overlay) nav > ul > li .dropdown-submenu:before {
              right: 0;
              top: 0;
              font-size: 16px; }
        #header #mainMenu:not(.menu-overlay) nav > ul li > .dropdown-menu {
          border: 0 none;
          background-color: transparent;
          display: block; }
          #header #mainMenu:not(.menu-overlay) nav > ul li > .dropdown-menu > .dropdown-submenu {
            position: relative; }
            #header #mainMenu:not(.menu-overlay) nav > ul li > .dropdown-menu > .dropdown-submenu:before {
              right: 0;
              transform: rotate(90deg); }
        #header #mainMenu:not(.menu-overlay) nav > ul li.hover-active > .dropdown-menu {
          visibility: visible;
          opacity: 1;
          max-height: 2000px; }
        #header #mainMenu:not(.menu-overlay) nav > ul [class*="col-md"] {
          width: 100%;
          padding: 0;
          margin: 0; }
    #header #mainMenu:not(.menu-overlay).menu-sub-title nav > ul > li {
      line-height: unset !important; }
      #header #mainMenu:not(.menu-overlay).menu-sub-title nav > ul > li .dropdown-submenu:before {
        top: 0; }
    #header #mainMenu:not(.menu-overlay).menu-slide nav > ul > li {
      visibility: visible;
      opacity: 1; }
    #header #mainMenu:not(.menu-overlay).menu-rounded nav > ul > li.current > a, #header #mainMenu:not(.menu-overlay).menu-rounded nav > ul > li:hover > a, #header #mainMenu:not(.menu-overlay).menu-rounded nav > ul > li:focus > a {
      background-color: unset !important; }
    #header #mainMenu:not(.menu-overlay).menu-hover-background nav > ul > li.current, #header #mainMenu:not(.menu-overlay).menu-hover-background nav > ul > li:hover, #header #mainMenu:not(.menu-overlay).menu-hover-background nav > ul > li:focus {
      background-color: unset; }
      #header #mainMenu:not(.menu-overlay).menu-hover-background nav > ul > li.current > a, #header #mainMenu:not(.menu-overlay).menu-hover-background nav > ul > li:hover > a, #header #mainMenu:not(.menu-overlay).menu-hover-background nav > ul > li:focus > a {
        color: unset !important; }
    #header #mainMenu:not(.menu-overlay).menu-left nav {
      margin-left: 0; }

  /*Main Menu*/
  #mainMenun.menu-center nav {
    margin: 0px; }

  .mainMenu-open {
    overflow-y: hidden; }
    .mainMenu-open #header #mainMenu {
      width: calc(100% + 20px);
      padding-right: 20px;
      display: block;
      opacity: 1;
      overflow-y: scroll; }
    .mainMenu-open #dotsMenu {
      z-index: -1; }

  #dotsMenu {
    right: 20px; }
    #dotsMenu:before {
      background: none; }

  #menu-responsive-icon {
    opacity: 1;
    pointer-events: auto; }

  /*Slide Panel*/
  #side-panel {
    width: 300px;
    left: -300px; }
    #side-panel .side-panel-wrap {
      padding: 30px;
      right: 0; }

  .side-panel {
    /* Static */ }
    .side-panel #side-panel-trigger {
      display: block !important; }
    .side-panel.side-panel-static #side-panel {
      left: -300px !important;
      right: auto !important; }
    .side-panel.side-panel-static #wrapper {
      padding-left: 0px; }
      .side-panel.side-panel-static #wrapper #header.header-sticky #header-wrap > .container {
        padding-left: auto; }
    .side-panel.side-panel-static.side-panel-right #side-panel {
      right: -300px !important;
      left: auto !important; }
    .side-panel.side-panel-static.side-panel-right #wrapper {
      padding-right: 0px; }
      .side-panel.side-panel-static.side-panel-right #wrapper #header.header-sticky #header-wrap > .container {
        padding-right: auto; }
    .side-panel.side-panel-active.side-panel-static {
      /*&.side-panel-right {
          &.side-panel-right {
              #side-panel {
                  right: -$side-panel-width !important;
                  left: auto !important;
              }
              #wrapper {
                  padding-right: 0px;
                  #header.header-sticky #header-wrap > .container {
                      padding-right: auto;
                  }
              }
          }
      }*/ }
      .side-panel.side-panel-active.side-panel-static #close-panel {
        display: block !important; }
      .side-panel.side-panel-active.side-panel-static #side-panel {
        left: 0 !important;
        right: auto; }
      .side-panel.side-panel-active.side-panel-static #wrapper {
        left: 300px;
        cursor: url(../src/images/close.png), auto; }
        .side-panel.side-panel-active.side-panel-static #wrapper:after {
          width: 100%;
          height: 100%;
          opacity: 1; }
        .side-panel.side-panel-active.side-panel-static #wrapper #header.header-sticky #header-wrap > .container {
          left: 300px;
          right: 0; }

  /*Page menu*/
  .page-menu nav {
    display: none;
    position: absolute;
    float: none;
    width: 210px;
    top: 40px;
    left: auto;
    right: 15px;
    height: auto;
    z-index: 11; }
    .page-menu nav ul {
      line-height: 20px; }
    .page-menu nav li {
      border-radius: 0;
      border: 0;
      float: none;
      width: 100%;
      border-bottom: 1px solid #f3f3f3 !important; }
      .page-menu nav li a {
        /* color: $body-color !important;*/
        border: medium none;
        border-radius: 0;
        padding: 10px 14px;
        line-height: 18px; }
        .page-menu nav li a:after {
          height: 0 !important; }
      .page-menu nav li:last-child {
        border-bottom: 0 !important; }
  .page-menu.page-menu-active nav {
    display: block;
    background-color: #ffffff !important;
    box-shadow: 0 4px 16px #e2e2e2;
    border-radius: 6px 0px 6px 6px; }
  .page-menu.page-menu-active #menu-responsive-icon {
    border-radius: 6px 6px 0 0;
    background-color: #ffffff !important; }
  .page-menu.menu-dark #menu-responsive-icon {
    background-color: #252525 !important; }
    .page-menu.menu-dark #menu-responsive-icon i {
      color: #9d9d9d !important; }
  .page-menu.menu-dark li {
    border-bottom: 1px solid #333 !important; }
    .page-menu.menu-dark li a {
      color: #9d9d9d !important; }
    .page-menu.menu-dark li:hover a, .page-menu.menu-dark li.active a {
      color: #1abc9c !important; }
  .page-menu.menu-dark.page-menu-active nav {
    background-color: #252525 !important; }
  .page-menu.menu-dark.page-menu-active #menu-responsive-icon {
    background-color: #252525 !important; }
  .page-menu.menu-colored-background {
    background-color: #1abc9c; }
    .page-menu.menu-colored-background .menu-title {
      color: #ffffff; }
    .page-menu.menu-colored-background nav li a {
      color: #252525;
      border-width: 0; }
    .page-menu.menu-colored-background nav li:hover a, .page-menu.menu-colored-background nav li.active a {
      color: #1abc9c;
      background-color: #ffffff; } }

/*# sourceMappingURL=header.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* ----------------------------------------------------------------
    SLIDER
-----------------------------------------------------------------*/
#slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  background-color: #fff;
  z-index: 1; }

#home {
  width: 100%;
  height: 100%;
  color: #FFF; }

.container-fullscreen {
  padding-top: 0px;
  height: 100%;
  width: 100%;
  display: table;
  position: relative; }

.fullscreen > .container,
.halfscreen > .container,
.fullscreen > .container-fluid,
.halfscreen > .container-fluid {
  height: 100%; }

/* ----------------------------------------------------------------
    BOXED SLIDER
-----------------------------------------------------------------*/
.boxed-slider {
  height: 600px;
  width: 100%; }
  .boxed-slider .owl-item {
    height: 600px; }
  .boxed-slider .owl-bg-img {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover; }

.owl-item img {
  height: 100%; }

/* ----------------------------------------------------------------
    INSPIRO SLIDER
-----------------------------------------------------------------*/
.inspiro-slider {
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease;
  z-index: 2;
  background-color: #252525;
  display: table;
  height: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  table-layout: fixed;
  width: 100%;
  z-index: 0; }
  .inspiro-slider.slider-fullscreen, .inspiro-slider.slider-fullwidth {
    height: 100%;
    width: 100%; }
  .inspiro-slider .owl-item {
    display: table;
    height: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    table-layout: fixed;
    width: 100%;
    z-index: 0; }
  .inspiro-slider .owl-stage-outer {
    height: 100%; }
  .inspiro-slider .slide {
    background-position: 50% 20%;
    background-repeat: repeat-y;
    background-size: cover;
    display: table-cell;
    height: 100%;
    position: relative;
    vertical-align: middle;
    width: 100%;
    z-index: 2; }
  .inspiro-slider .slide-captions {
    /* h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
        font-family: "Open Sans";
        font-weight: 600;
        letter-spacing: -0.5px;
    }*/ }
    .inspiro-slider .slide-captions h1 {
      font-size: 80px;
      font-weight: 700;
      line-height: 80px;
      margin-bottom: 30px;
      color: #ffffff; }
    .inspiro-slider .slide-captions h2 {
      font-size: 60px;
      font-weight: 700;
      line-height: 68px;
      margin-bottom: 30px;
      color: #ffffff; }
    .inspiro-slider .slide-captions h3,
    .inspiro-slider .slide-captions h4,
    .inspiro-slider .slide-captions h5,
    .inspiro-slider .slide-captions h6 {
      color: #ffffff; }
    .inspiro-slider .slide-captions .strong {
      color: #ffffff;
      display: block;
      font-family: "Open Sans";
      font-size: 12px !important;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 12px;
      margin-bottom: 20px;
      position: relative;
      text-transform: uppercase; }
      .inspiro-slider .slide-captions .strong::after {
        border-top: 3px solid #fff;
        content: "";
        display: block;
        margin-bottom: 0;
        margin-top: 8px;
        width: 26px; }
    .inspiro-slider .slide-captions > p {
      color: #ffffff;
      font-size: 34px;
      font-weight: 300;
      line-height: 50px;
      margin-bottom: 40px; }
    .inspiro-slider .slide-captions .text-dark.strong::after, .inspiro-slider .slide-captions.text-dark .strong::after {
      border-top-color: #111; }
    .inspiro-slider .slide-captions.text-center .strong::after {
      margin-left: auto;
      margin-right: auto; }
    .inspiro-slider .slide-captions.text-right .strong::after {
      margin-left: auto;
      margin-right: 0; }
    .inspiro-slider .slide-captions .slide-caption-hide {
      opacity: 0;
      visibility: hidden; }
    .inspiro-slider .slide-captions img {
      width: inherit !important; }
  .inspiro-slider.slider-loaded {
    visibility: visible;
    opacity: 1; }

.kenburns-bg {
  background-position: 50% 20%;
  background-repeat: repeat-y;
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 0;
  vertical-align: middle;
  width: 100%;
  z-index: -1;
  transform: scale(1);
  transition: transform 0s cubic-bezier(0, 0, 0, 0) 0s;
  transition-delay: 1s; }

.kenburns-bg.kenburns-bg-animate {
  transform: scale(1.11);
  transition: transform 10s cubic-bezier(0.2, 0, 0.7, 1) 0s;
  transition-delay: 1s; }

.zoom-out {
  -webkit-animation-name: zoom-out;
  -moz-animation-name: zoom-out;
  -o-animation-name: zoom-out;
  animation-name: zoom-out;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

@-webkit-keyframes zoom-out {
  0% {
    -webkit-transform: scale(1.5);
    opacity: 0.1; }
  100% {
    -webkit-transform: scale(1);
    opacity: 1; } }
@-moz-keyframes zoom-out {
  0% {
    -moz-transform: scale(1.5);
    opacity: 0.1; }
  100% {
    -moz-transform: scale(1);
    opacity: 1; } }
@-o-keyframes zoom-out {
  0% {
    -o-transform: scale(1.5);
    opacity: 0.1; }
  100% {
    -o-transform: scale(1);
    opacity: 1; } }
@keyframes zoom-out {
  0% {
    transform: scale(1.5);
    opacity: 0.1; }
  100% {
    transform: scale(1);
    opacity: 1; } }
/* ----------------------------------------------------------------
    Inspiro Slider - Responsive Classes
-----------------------------------------------------------------*/
@media (max-width: 992px) {
  .inspiro-slider .slide-captions h1,
  .inspiro-slider .slide-captions h2 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 14px; }
  .inspiro-slider .slide-captions > p {
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 16px; }
  .inspiro-slider .slide-captions .btn {
    padding: 6px 14px !important;
    font-size: 10px !important;
    line-height: 1.5; } }

/*# sourceMappingURL=sliders.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
 /* ----------------------------------------------------------------------
    Page title
-------------------------------------------------------------------------*/
#page-title {
  clear: both;
  padding: 90px 0;
  background-color: #F2F2F4;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  position: relative;
     /*
     min-height: 560px;
     height: 1px;
*/
  background-color: #252525;
  /* page title center*/
  /* page header right*/ }
  #page-title .container {
  /*      position: relative;
top: 50%;
  transform: translateY(-50%);*/ }
  #page-title .page-title {
    width: 70%;
    padding: 0;
    float: left; }
    #page-title .page-title > h1 {
      font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: -2px;
      color: #ffffff;
      margin-top: 0;
      margin-bottom: 20px; }
    #page-title .page-title > span {
      color: #ffffff;
      font-weight: 100;
      font-size: 16px;
      font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
      opacity: .7; }
  #page-title .breadcrumb {
    width: 30%;
    opacity: .6;
    float: right;
    text-align: right; }
    #page-title .breadcrumb ul li + li:before {
      background-color: #ffffff; }
    #page-title .breadcrumb ul li a {
      color: #ffffff; }
  #page-title.page-title-center .page-title {
    float: none;
    text-align: center;
    width: 100%;
    margin-bottom: 20px; }
  #page-title.page-title-center .breadcrumb {
    float: none;
    text-align: center;
    width: 100%; }
  #page-title.page-title-right .page-title {
    float: right;
    text-align: right; }
  #page-title.page-title-right .breadcrumb {
    float: left;
    text-align: left; }
  #page-title.page-title-classic {
    background-color: #F2F2F4;
    border-bottom: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
    padding: 50px 0; }
    #page-title.page-title-classic .page-title {
      float: left;
      width: 70%; }
      #page-title.page-title-classic .page-title > h1 {
        display: block;
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 400;
        text-transform: uppercase;
        color: #555555;
        letter-spacing: 0; }
      #page-title.page-title-classic .page-title > span {
        color: #252525;
        font-weight: 100;
        font-size: 16px;
        font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
        opacity: .7; }
    #page-title.page-title-classic .breadcrumb {
      opacity: .6;
      float: right;
      width: 30%;
      text-align: right; }
      #page-title.page-title-classic .breadcrumb ul li + li:before {
        background-color: #252525; }
      #page-title.page-title-classic .breadcrumb ul li a {
        color: #252525; }
  #page-title.dark .page-title > h1 {
    color: #252525; }
  #page-title.dark .page-title > span {
    color: #252525; }
  #page-title.dark .breadcrumb ul li + li:before {
    background-color: #252525; }
  #page-title.dark .breadcrumb ul li a {
    color: #252525; }

@media (max-width: 991px) {
  #page-title,
  #page-title.page-title-classic {
    padding: 50px 0 !important; }
    #page-title .page-title,
    #page-title.page-title-classic .page-title {
      float: none;
      width: 100%;
      text-align: left;
      display: block;
      margin-bottom: 10px; }
      #page-title .page-title > h1,
      #page-title.page-title-classic .page-title > h1 {
        font-size: 30px;
        margin-bottom: 0; }
    #page-title .breadcrumb,
    #page-title.page-title-classic .breadcrumb {
      float: none;
      width: 100%;
      text-align: left;
      display: block; } }

/*# sourceMappingURL=page-title.css.map */

#page-content {
  padding: 40px 0 40px 0; }

.page-content-shadow {
  box-shadow: 0 82px 53px 10px rgba(0, 0, 0, 0.3); }

/*--------------------------------------------------------
     Content
---------------------------------------------------------*/
#content {
  overflow: hidden;
  padding: 60px 0;
  position: relative; }
  #content .container {
    position: relative; }

.content {
  float: right;
  padding-left: 25px;
  margin-bottom: 26px; }

.sidebar {
  padding-right: 25px;
  float: left; }
  .sidebar:before {
    border-style: solid;
    border-color: #eee;
    border-width: 0;
    border-right-width: 1px;
    content: "";
    display: block;
    height: 99999px;
    position: absolute;
    right: 0;
    top: -300px;
    z-index: -10; }
  .sidebar.sidebar-modern:before {
    background-color: #fbfbfb;
    width: 3000px; }
  .sidebar.sidebar-plain:before {
    display: none; }

.sidebar-right .content {
  float: left;
  padding-left: 15px;
  padding-right: 25px; }
.sidebar-right .sidebar {
  float: right;
  padding-right: 15px;
  border-right-width: 0;
  padding-left: 25px; }
  .sidebar-right .sidebar:before {
    right: auto;
    border-right-width: 0;
    border-left-width: 1px;
    left: 0; }

.sidebar-both .content {
  float: left;
  padding-left: 25px;
  padding-right: 25px; }
  .sidebar-both .content + .sidebar {
    float: right;
    padding-right: 15px;
    border-right-width: 0;
    padding-left: 25px; }
    .sidebar-both .content + .sidebar:before {
      right: auto;
      border-right-width: 0;
      border-left-width: 1px;
      left: 0; }

@media (max-width: 991px) {
  .content,
  .sidebar {
    width: 100% !important;
    float: left;
    padding-left: 15px;
    padding-right: 15px; }

  .sidebar-right .sidebar,
  .sidebar-right .content,
  .sidebar-both .content,
  .sidebar-both .content + .sidebar {
    padding-right: 15px;
    padding-left: 15px; }

  .sidebar-both .sidebar:before,
  .sidebar-right .sidebar:before,
  .sidebar:before {
    right: 0;
    border-right-width: 0;
    border-left-width: 0;
    left: 0; }
  .sidebar-both .sidebar .widgets,
  .sidebar-right .sidebar .widgets,
  .sidebar .widgets {
    float: left; } }
/*.content-wrap {
    position: relative;
    padding: 80px 0;
}
.container > .post-content {
    padding-left: 0;
    padding-right: 0;
}
.post-content {
    padding-left: 15px;
    padding-right: 25px;
    &.float-right {
        padding-left: 25px;
        padding-right: 15px;
        &+ .sidebar {
            padding-left: 15px;
            padding-right: 25px;
        }
    }
    &.post-fullwidth,
    &.bothsidebar {
        padding-left: 15px;
        padding-right: 15px;
    }
    &.bothsidebar {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.post-content + sidebar {
    padding-left: 25px;
    padding-right: 15px;
}
body.device-sm,
body.device-xs,
body.device-xss {
    .sidebar {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.sidebar-modern {
    &:before {
        background-color: #fbfbfb;
        border-left: 1px solid $light-grey;
        border-right: 1px solid $light-grey;
        content: "";
        display: block;
        height: 99999px;
        position: absolute;
        right: 0;
        top: -100px;
        width: 3000px;
        z-index: -10;
    }
}
body.device-sm,
body.device-xs,
body.device-xss {
    .sidebar {
        &:before {
            border: 0px;
            background-color: transparent;
        }
    }
}
.post-content {
    .sidebar-modern:before {
        left: 0;
        right: auto;
    }
    &.float-right +.sidebar-modern:before {
        right: 0;
        left: auto;
    }
}
body.device-sm,
body.device-xs {
    .sidebar {
        margin-top: 40px;
        width: 100%;
    }
}*/
.sidebar-menu {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  margin-top: 30px;
  padding: 0; }
  .sidebar-menu ul {
    list-style: outside none none;
    margin: 0;
    padding: 0; }
    .sidebar-menu ul li {
      padding: 10px 20px;
      margin: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.07);
      color: #666;
      font-family: Roboto, Arial, Tahoma, sans-serif;
      padding: 10px 20px 10px 18px;
      -webkit-transition: all 0.2s ease-in-out 0s;
      transition: all 0.2s ease-in-out 0s;
      cursor: pointer; }
      .sidebar-menu ul li:hover {
        background: rgba(0, 0, 0, 0.05) !important;
        color: #606060;
        padding: 10px 20px 10px 26px; }
      .sidebar-menu ul li a {
        color: #444;
        display: block; }
  .sidebar-menu i {
    margin-right: 12px; }
  .sidebar-menu h4,
  .sidebar-menu h3 {
    background-color: #f8f8f8;
    padding: 14px 18px;
    margin: 0; }

@media (max-width: 991px) {
  #page-content {
    padding: 20px 0 20px 0; } }

/*# sourceMappingURL=content.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* ----------------------------------------------------------------
	Grid Layout
-----------------------------------------------------------------*/
.grid-layout,
.portfolioo,
.posts {
  position: relative;
  display: block;
  clear: both;
  padding: 0; }

.grid-layout {
  opacity: 0;
  margin-bottom: -2px !important; }

.grid-layout.grid-loaded {
  opacity: 1; }

.grid-mix-size .portfolio-item:nth-child(2n) .new-badge {
  right: 70px !important; }
.grid-mix-size .portfolio-item:nth-child(2n) > .portfolio-item-wrap {
  -webkit-transform: scale(0.75);
  transform: scale(0.8); }

/*Grid columns: 5*/
.grid-6-columns .grid-item,
.post-6-columns .post-item,
.portfolio-6-columns .portfolio-item {
  width: 16.6666666666%; }
  .grid-6-columns .grid-item.large-width,
  .post-6-columns .post-item.large-width,
  .portfolio-6-columns .portfolio-item.large-width {
    width: 33.333333333%; }

/*Grid columns: 5*/
.grid-5-columns .grid-item,
.post-5-columns .post-item,
.portfolio-5-columns .portfolio-item {
  width: 20%; }
  .grid-5-columns .grid-item.large-width,
  .post-5-columns .post-item.large-width,
  .portfolio-5-columns .portfolio-item.large-width {
    width: 40%; }

/*Grid columns: 4*/
.grid-4-columns .grid-item,
.post-4-columns .post-item,
.portfolio-4-columns .portfolio-item {
  width: 25%; }
  .grid-4-columns .grid-item.large-width,
  .post-4-columns .post-item.large-width,
  .portfolio-4-columns .portfolio-item.large-width {
    width: 50%; }

/*Grid columns: 3*/
.grid-3-columns .grid-item,
.post-3-columns .post-item,
.portfolio-3-columns .portfolio-item {
  width: 33.333333333%; }
  .grid-3-columns .grid-item.large-width,
  .post-3-columns .post-item.large-width,
  .portfolio-3-columns .portfolio-item.large-width {
    width: 66.6666666666%; }

/*Grid columns: 2*/
.grid-2-columns .grid-item,
.post-2-columns .post-item,
.portfolio-2-columns .portfolio-item {
  width: 50.00%; }
  .grid-2-columns .grid-item.large-width,
  .post-2-columns .post-item.large-width,
  .portfolio-2-columns .portfolio-item.large-width {
    width: 100%; }

/*Grid columns: 1*/
.grid-1-columns .grid-item,
.post-1-columns .post-item,
.portfolio-1-columns .portfolio-item {
  width: 100%; }
  .grid-1-columns .grid-item.large-width,
  .post-1-columns .post-item.large-width,
  .portfolio-1-columns .portfolio-item.large-width {
    width: 100%; }

@media (max-width: 1380px) {
  .grid-5-columns .grid-item,
  .post-5-columns .post-item,
  .portfolio-5-columns .portfolio-item {
    width: 25%; }
    .grid-5-columns .grid-item.large-width,
    .post-5-columns .post-item.large-width,
    .portfolio-5-columns .portfolio-item.large-width {
      width: 50%; } }
/*Tablet Landscape*/
@media (max-width: 991px) {
  /*Grid columns: 5,4*/
  .grid-5-columns .grid-item,
  .post-5-columns .post-item,
  .portfolio-5-columns .portfolio-item,
  .grid-4-columns .grid-item,
  .post-4-columns .post-item,
  .portfolio-4-columns .portfolio-item {
    width: 33.3333333333%; }
    .grid-5-columns .grid-item.large-width,
    .post-5-columns .post-item.large-width,
    .portfolio-5-columns .portfolio-item.large-width,
    .grid-4-columns .grid-item.large-width,
    .post-4-columns .post-item.large-width,
    .portfolio-4-columns .portfolio-item.large-width {
      width: 66.6666666666%; }

  /*Grid columns: 3,2*/
  .grid-3-columns .grid-item,
  .post-3-columns .post-item,
  .portfolio-3-columns .portfolio-item,
  .grid-2-columns .grid-item,
  .post-2-columns .post-item,
  .portfolio-2-columns .portfolio-item {
    width: 50%; }
    .grid-3-columns .grid-item.large-width,
    .post-3-columns .post-item.large-width,
    .portfolio-3-columns .portfolio-item.large-width,
    .grid-2-columns .grid-item.large-width,
    .post-2-columns .post-item.large-width,
    .portfolio-2-columns .portfolio-item.large-width {
      width: 100%; } }
/*Tablet Portrait*/
@media (max-width: 767px) {
  /*Grid columns: 5,4*/
  .grid-5-columns .grid-item,
  .post-5-columns .post-item,
  .portfolio-5-columns .portfolio-item,
  .grid-4-columns .grid-item,
  .post-4-columns .post-item,
  .portfolio-4-columns .portfolio-item {
    width: 50%; }
    .grid-5-columns .grid-item.large-width,
    .post-5-columns .post-item.large-width,
    .portfolio-5-columns .portfolio-item.large-width,
    .grid-4-columns .grid-item.large-width,
    .post-4-columns .post-item.large-width,
    .portfolio-4-columns .portfolio-item.large-width {
      width: 100%; }

  /*Grid columns: 3,2*/
  .grid-3-columns .grid-item,
  .post-3-columns .post-item,
  .portfolio-3-columns .portfolio-item,
  .grid-2-columns .grid-item,
  .post-2-columns .post-item,
  .portfolio-2-columns .portfolio-item {
    width: 50%; }
    .grid-3-columns .grid-item.large-width,
    .post-3-columns .post-item.large-width,
    .portfolio-3-columns .portfolio-item.large-width,
    .grid-2-columns .grid-item.large-width,
    .post-2-columns .post-item.large-width,
    .portfolio-2-columns .portfolio-item.large-width {
      width: 100%; } }
/*Tablet Portrait*/
@media (max-width: 480px) {
  /*Grid columns: 5,4,3,2*/
  .grid-5-columns .grid-item,
  .post-5-columns .post-item,
  .portfolio-5-columns .portfolio-item,
  .grid-4-columns .grid-item,
  .post-4-columns .post-item,
  .portfolio-4-columns .portfolio-item,
  .grid-3-columns .grid-item,
  .post-3-columns .post-item,
  .portfolio-3-columns .portfolio-item,
  .grid-2-columns .grid-item,
  .post-2-columns .post-item,
  .portfolio-2-columns .portfolio-item {
    width: 100%; }
    .grid-5-columns .grid-item.large-width,
    .post-5-columns .post-item.large-width,
    .portfolio-5-columns .portfolio-item.large-width,
    .grid-4-columns .grid-item.large-width,
    .post-4-columns .post-item.large-width,
    .portfolio-4-columns .portfolio-item.large-width,
    .grid-3-columns .grid-item.large-width,
    .post-3-columns .post-item.large-width,
    .portfolio-3-columns .portfolio-item.large-width,
    .grid-2-columns .grid-item.large-width,
    .post-2-columns .post-item.large-width,
    .portfolio-2-columns .portfolio-item.large-width {
      width: 100%; } }
/*Grid item */
.grid-item .post-item {
  width: 100%;
  padding: 0; }

.grid-item > img,
.grid-item > a > img {
  width: 100%; }

/* ----------------------------------------------------------------
	Grid Filter
-----------------------------------------------------------------*/
.grid-filter {
  clear: both;
  display: block;
  height: 34px;
  width: 100%;
  margin-bottom: 26px;
  position: relative; }
  .grid-filter ul {
    list-style: none;
    padding: 0;
    position: relative;
    height: auto; }
  .grid-filter li {
    position: relative;
    margin-right: 6px;
    border: 0;
    margin-bottom: -1px;
    display: inline-block;
    float: left; }
    .grid-filter li a {
      font-family: "Raleway", "Helvetica", "Arial", sans-serif;
      font-weight: 600;
      display: block;
      position: relative;
      padding: 8px 10px;
      font-size: 13px;
      line-height: 15px;
      color: #565656;
      border-radius: 50px;
      transition: all .3s ease;
      border-width: 2px;
      border-style: solid;
      border-color: transparent; }
    .grid-filter li:hover a, .grid-filter li.active a {
      color: #ffffff;
      background-color: #1abc9c; }
  .grid-filter.gf-outline li:hover a, .grid-filter.gf-outline li.active a {
    border-color: #1abc9c;
    color: #1abc9c;
    background-color: transparent; }
  .grid-filter.gf-lines li a {
    border-radius: 0;
    border: none;
    background-color: transparent; }
    .grid-filter.gf-lines li a:after {
      content: '';
      position: absolute;
      top: auto;
      bottom: -2px;
      left: 50%;
      width: 0;
      height: 2px;
      transition: all 0.3s ease; }
  .grid-filter.gf-lines li:hover a, .grid-filter.gf-lines li.active a {
    color: #1abc9c; }
    .grid-filter.gf-lines li:hover a:after, .grid-filter.gf-lines li.active a:after {
      width: 100%;
      left: 0%;
      background-color: #1abc9c; }
  .grid-filter.gf-line-bottom {
    border-bottom: 2px solid #ebebeb; }
  .grid-filter.gf-classic li a {
    color: #565656;
    border-radius: 4px; }
  .grid-filter.gf-classic li:hover a, .grid-filter.gf-classic li.active a {
    background-color: #1abc9c;
    color: #ffffff; }
  .grid-filter.gf-light li a {
    color: #ffffff;
    background-color: transparent; }
  .grid-filter.gf-light li:hover a, .grid-filter.gf-light li.active a {
    color: #ffffff;
    border-color: #ffffff; }
  .grid-filter.gf-dark li a {
    color: #252525;
    background-color: transparent; }
  .grid-filter.gf-dark li:hover a, .grid-filter.gf-dark li.active a {
    border-color: #252525; }
  .grid-filter.gf-default li a {
    color: none;
    border-radius: 0;
    background-color: transparent; }
  .grid-filter.gf-default li:hover a, .grid-filter.gf-default li.active a {
    background-color: transparent;
    color: #1abc9c; }
  .grid-filter.gf-creative li a {
    border-radius: 0;
    border: none;
    background-color: transparent; }
    .grid-filter.gf-creative li a:after {
      background: #252525;
      content: "";
      height: 2px;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 100%;
      transform: translateY(10px);
      transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
      width: 100%; }
  .grid-filter.gf-creative li:hover a, .grid-filter.gf-creative li.active a {
    color: #565656; }
    .grid-filter.gf-creative li:hover a:after, .grid-filter.gf-creative li.active a:after {
      opacity: 1;
      transform: translateY(0px); }
  .grid-filter.gf-list li {
    clear: both;
    margin-bottom: 3px; }
  .grid-filter.center li {
    text-align: center;
    float: none; }

.grid-active-title {
  font-family: "Raleway", "Helvetica", "Arial", sans-serif;
  font-size: 64px;
  font-weight: 100;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: -6px; }

.content .grid-active-title {
  font-size: 42px; }

.sidebar-both .grid-active-title {
  display: none; }

@media (max-width: 991px) {
  .grid-active-title {
    display: none; }

  .grid-filter li {
    width: 33.33%;
    margin-right: 0;
    margin-bottom: 4px; }
    .grid-filter li a {
      text-align: center;
      transition: none; } }
@media (max-width: 479px) {
  .grid-filter li {
    width: 50%;
    margin-right: 0; } }
/* ----------------------------------------------------------------
	Extras
-----------------------------------------------------------------*/
.infinite-scroll-message,
#showMore {
  height: 80px;
  padding-top: 36px;
  text-align: center; }
  .infinite-scroll-message p,
  #showMore p {
    margin: 0; }

/*# sourceMappingURL=grid.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.portfolio-item,
.grid-item {
  width: 100%;
  float: left;
  height: auto;
  cursor: pointer;
  padding: 0 0 20px 0;
  /*carousel*/
  /*hover state*/
  /*Portfolio Effects*/
  /*Styles*/ }
  .portfolio-item .portfolio-item-wrap,
  .portfolio-item .grid-item-wrap,
  .grid-item .portfolio-item-wrap,
  .grid-item .grid-item-wrap {
    position: relative;
    overflow: hidden; }
    .portfolio-item .portfolio-item-wrap > a,
    .portfolio-item .grid-item-wrap > a,
    .grid-item .portfolio-item-wrap > a,
    .grid-item .grid-item-wrap > a {
      z-index: 6;
      width: 100%;
      height: 100%;
      position: absolute; }
  .portfolio-item .portfolio-image,
  .portfolio-item .portfolio-video,
  .portfolio-item .grid-image,
  .portfolio-item .grid-video,
  .grid-item .portfolio-image,
  .grid-item .portfolio-video,
  .grid-item .grid-image,
  .grid-item .grid-video {
    position: relative;
    overflow: hidden; }
    .portfolio-item .portfolio-image:after,
    .portfolio-item .portfolio-video:after,
    .portfolio-item .grid-image:after,
    .portfolio-item .grid-video:after,
    .grid-item .portfolio-image:after,
    .grid-item .portfolio-video:after,
    .grid-item .grid-image:after,
    .grid-item .grid-video:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #000000;
      transition: all 0.3s ease-out;
      opacity: 0;
      content: ' ';
      z-index: 2; }
    .portfolio-item .portfolio-image img,
    .portfolio-item .portfolio-video img,
    .portfolio-item .grid-image img,
    .portfolio-item .grid-video img,
    .grid-item .portfolio-image img,
    .grid-item .portfolio-video img,
    .grid-item .grid-image img,
    .grid-item .grid-video img {
      position: relative;
      transition: all 0.5s ease-out;
      width: 100%;
      z-index: 1;
      display: block;
      height: auto; }
  .portfolio-item .portfolio-slider .owl-controls,
  .portfolio-item .grid-slider .owl-controls,
  .grid-item .portfolio-slider .owl-controls,
  .grid-item .grid-slider .owl-controls {
    height: 0; }
  .portfolio-item .portfolio-slider .owl-dots,
  .portfolio-item .grid-slider .owl-dots,
  .grid-item .portfolio-slider .owl-dots,
  .grid-item .grid-slider .owl-dots {
    bottom: 30px;
    margin-bottom: 0 !important;
    margin-top: 0 !important; }
  .portfolio-item .portfolio-slider .owl-stage-outer:after,
  .portfolio-item .grid-slider .owl-stage-outer:after,
  .grid-item .portfolio-slider .owl-stage-outer:after,
  .grid-item .grid-slider .owl-stage-outer:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    transition: all 0.3s ease-out;
    opacity: 0;
    content: ' ';
    z-index: 2; }
  .portfolio-item .portfolio-slider img,
  .portfolio-item .grid-slider img,
  .grid-item .portfolio-slider img,
  .grid-item .grid-slider img {
    position: relative;
    transition: all 0.5s ease-out;
    width: 100%;
    z-index: 1;
    display: block;
    height: auto; }
  .portfolio-item .portfolio-video,
  .portfolio-item .grid-video,
  .grid-item .portfolio-video,
  .grid-item .grid-video {
    margin-bottom: -7px; }
    .portfolio-item .portfolio-video video,
    .portfolio-item .portfolio-video iframe,
    .portfolio-item .grid-video video,
    .portfolio-item .grid-video iframe,
    .grid-item .portfolio-video video,
    .grid-item .portfolio-video iframe,
    .grid-item .grid-video video,
    .grid-item .grid-video iframe {
      width: 100%;
      background-color: #000000; }
  .portfolio-item .portfolio-description,
  .portfolio-item .grid-description,
  .grid-item .portfolio-description,
  .grid-item .grid-description {
    left: 0;
    margin: 0 auto;
    padding: 20px;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 48%;
    transform: translate3d(0px, -38%, 0px);
    transition: all 250ms ease-in-out 0s;
    visibility: hidden;
    width: 70%;
    z-index: 3; }
    .portfolio-item .portfolio-description h3,
    .portfolio-item .grid-description h3,
    .grid-item .portfolio-description h3,
    .grid-item .grid-description h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 18px;
      text-transform: uppercase;
      margin-bottom: 4px; }
    .portfolio-item .portfolio-description h3,
    .portfolio-item .portfolio-description p,
    .portfolio-item .portfolio-description span,
    .portfolio-item .grid-description h3,
    .portfolio-item .grid-description p,
    .portfolio-item .grid-description span,
    .grid-item .portfolio-description h3,
    .grid-item .portfolio-description p,
    .grid-item .portfolio-description span,
    .grid-item .grid-description h3,
    .grid-item .grid-description p,
    .grid-item .grid-description span {
      color: #ffffff; }
    .portfolio-item .portfolio-description a,
    .portfolio-item .grid-description a,
    .grid-item .portfolio-description a,
    .grid-item .grid-description a {
      margin: 3px; }
      .portfolio-item .portfolio-description a.btn,
      .portfolio-item .grid-description a.btn,
      .grid-item .portfolio-description a.btn,
      .grid-item .grid-description a.btn {
        margin-top: 10px; }
      .portfolio-item .portfolio-description a i,
      .portfolio-item .grid-description a i,
      .grid-item .portfolio-description a i,
      .grid-item .grid-description a i {
        background-color: #ffffff;
        color: #565656;
        border-radius: 50%;
        color: #444;
        font-size: 18px;
        height: 40px;
        line-height: 40px;
        width: 40px;
        transition: all 250ms ease-in-out 0s;
        border: 1px solid #ededed; }
      .portfolio-item .portfolio-description a:hover i,
      .portfolio-item .grid-description a:hover i,
      .grid-item .portfolio-description a:hover i,
      .grid-item .grid-description a:hover i {
        background-color: #1abc9c;
        color: #ffffff;
        border: 1px solid #1abc9c; }
    .portfolio-item .portfolio-description span,
    .portfolio-item .portfolio-description p,
    .portfolio-item .grid-description span,
    .portfolio-item .grid-description p,
    .grid-item .portfolio-description span,
    .grid-item .portfolio-description p,
    .grid-item .grid-description span,
    .grid-item .grid-description p {
      opacity: 0;
      transition: all 0.8s ease;
      transition-delay: 0.1s;
      margin-bottom: 0;
      padding-bottom: 0; }
    .portfolio-item .portfolio-description a > i,
    .portfolio-item .grid-description a > i,
    .grid-item .portfolio-description a > i,
    .grid-item .grid-description a > i {
      font-size: 14px !important; }
  .portfolio-item .owl-carousel .owl-nav [class*="owl-"],
  .grid-item .owl-carousel .owl-nav [class*="owl-"] {
    width: 32px;
    height: 32px;
    line-height: 32px; }
    .portfolio-item .owl-carousel .owl-nav [class*="owl-"] i,
    .grid-item .owl-carousel .owl-nav [class*="owl-"] i {
      line-height: 32px;
      font-size: 18px; }
  .portfolio-item .owl-carousel .owl-controls .owl-dots .owl-dot span,
  .grid-item .owl-carousel .owl-controls .owl-dots .owl-dot span {
    height: 10px !important;
    margin: 0 4px;
    width: 10px !important; }
  .portfolio-item:not(.no-overlay):hover,
  .grid-item:not(.no-overlay):hover {
    /*carousel*/ }
    .portfolio-item:not(.no-overlay):hover .portfolio-slider .owl-stage-outer:after,
    .portfolio-item:not(.no-overlay):hover .portfolio-image:after,
    .portfolio-item:not(.no-overlay):hover .portfolio-video:after,
    .portfolio-item:not(.no-overlay):hover .grid-slider .owl-stage-outer:after,
    .portfolio-item:not(.no-overlay):hover .grid-image:after,
    .portfolio-item:not(.no-overlay):hover .grid-video:after,
    .grid-item:not(.no-overlay):hover .portfolio-slider .owl-stage-outer:after,
    .grid-item:not(.no-overlay):hover .portfolio-image:after,
    .grid-item:not(.no-overlay):hover .portfolio-video:after,
    .grid-item:not(.no-overlay):hover .grid-slider .owl-stage-outer:after,
    .grid-item:not(.no-overlay):hover .grid-image:after,
    .grid-item:not(.no-overlay):hover .grid-video:after {
      opacity: 0.5; }
    .portfolio-item:not(.no-overlay):hover .portfolio-description,
    .portfolio-item:not(.no-overlay):hover .grid-description,
    .grid-item:not(.no-overlay):hover .portfolio-description,
    .grid-item:not(.no-overlay):hover .grid-description {
      transform: translate3d(0, -50%, 0);
      opacity: 1;
      visibility: visible; }
      .portfolio-item:not(.no-overlay):hover .portfolio-description h3,
      .portfolio-item:not(.no-overlay):hover .grid-description h3,
      .grid-item:not(.no-overlay):hover .portfolio-description h3,
      .grid-item:not(.no-overlay):hover .grid-description h3 {
        transform: translateY(0px); }
      .portfolio-item:not(.no-overlay):hover .portfolio-description span,
      .portfolio-item:not(.no-overlay):hover .portfolio-description p,
      .portfolio-item:not(.no-overlay):hover .grid-description span,
      .portfolio-item:not(.no-overlay):hover .grid-description p,
      .grid-item:not(.no-overlay):hover .portfolio-description span,
      .grid-item:not(.no-overlay):hover .portfolio-description p,
      .grid-item:not(.no-overlay):hover .grid-description span,
      .grid-item:not(.no-overlay):hover .grid-description p {
        opacity: 1; }
    .portfolio-item:not(.no-overlay):hover .owl-carousel .owl-next,
    .grid-item:not(.no-overlay):hover .owl-carousel .owl-next {
      left: auto;
      right: 10px;
      opacity: 1; }
    .portfolio-item:not(.no-overlay):hover .owl-carousel .owl-prev,
    .grid-item:not(.no-overlay):hover .owl-carousel .owl-prev {
      right: auto;
      left: 10px;
      opacity: 1; }
  .portfolio-item.text-bottom .portfolio-description,
  .portfolio-item.text-bottom .grid-description,
  .grid-item.text-bottom .portfolio-description,
  .grid-item.text-bottom .grid-description {
    bottom: 0;
    left: 0;
    top: auto;
    text-align: left;
    transform: translate3d(0, 0, 0);
    width: 100%; }
  .portfolio-item.text-bottom:hover .portfolio-description,
  .portfolio-item.text-bottom:hover .grid-description,
  .grid-item.text-bottom:hover .portfolio-description,
  .grid-item.text-bottom:hover .grid-description {
    transform: translate3d(0, -4%, 0);
    opacity: 1;
    visibility: visible; }
  .portfolio-item.img-zoom .portfolio-image img,
  .portfolio-item.img-zoom .grid-image img,
  .grid-item.img-zoom .portfolio-image img,
  .grid-item.img-zoom .grid-image img {
    transform: scale(1); }
  .portfolio-item.img-zoom:hover .portfolio-image img,
  .portfolio-item.img-zoom:hover .grid-image img,
  .grid-item.img-zoom:hover .portfolio-image img,
  .grid-item.img-zoom:hover .grid-image img {
    transform: scale(1.1); }
  .portfolio-item.img-zoom-out .portfolio-image img,
  .portfolio-item.img-zoom-out .grid-image img,
  .grid-item.img-zoom-out .portfolio-image img,
  .grid-item.img-zoom-out .grid-image img {
    transform: scale(1.1); }
  .portfolio-item.img-zoom-out:hover .portfolio-image img,
  .portfolio-item.img-zoom-out:hover .grid-image img,
  .grid-item.img-zoom-out:hover .portfolio-image img,
  .grid-item.img-zoom-out:hover .grid-image img {
    transform: scale(1); }
  .portfolio-item.img-rotate .portfolio-image img,
  .portfolio-item.img-rotate .grid-image img,
  .grid-item.img-rotate .portfolio-image img,
  .grid-item.img-rotate .grid-image img {
    transform: rotate(0deg) scale(1);
    transition: transform 0.7s ease 0s; }
  .portfolio-item.img-rotate:hover .portfolio-image img,
  .portfolio-item.img-rotate:hover .grid-image img,
  .grid-item.img-rotate:hover .portfolio-image img,
  .grid-item.img-rotate:hover .grid-image img {
    transform: rotate(-9deg) scale(1.2); }
  .portfolio-item.overlay-light .portfolio-image:after,
  .portfolio-item.overlay-light .portfolio-slider .owl-stage-outer:after,
  .portfolio-item.overlay-light .grid-image:after,
  .portfolio-item.overlay-light .grid-slider .owl-stage-outer:after,
  .grid-item.overlay-light .portfolio-image:after,
  .grid-item.overlay-light .portfolio-slider .owl-stage-outer:after,
  .grid-item.overlay-light .grid-image:after,
  .grid-item.overlay-light .grid-slider .owl-stage-outer:after {
    background-color: #ffffff; }
  .portfolio-item.overlay-light .portfolio-description h3,
  .portfolio-item.overlay-light .grid-description h3,
  .grid-item.overlay-light .portfolio-description h3,
  .grid-item.overlay-light .grid-description h3 {
    color: #000000; }
  .portfolio-item.overlay-light .portfolio-description p,
  .portfolio-item.overlay-light .portfolio-description span,
  .portfolio-item.overlay-light .grid-description p,
  .portfolio-item.overlay-light .grid-description span,
  .grid-item.overlay-light .portfolio-description p,
  .grid-item.overlay-light .portfolio-description span,
  .grid-item.overlay-light .grid-description p,
  .grid-item.overlay-light .grid-description span {
    color: #434343; }
  .portfolio-item.overlay-light:hover .portfolio-image:after,
  .portfolio-item.overlay-light:hover .portfolio-slider .owl-stage-outer:after,
  .portfolio-item.overlay-light:hover .grid-image:after,
  .portfolio-item.overlay-light:hover .grid-slider .owl-stage-outer:after,
  .grid-item.overlay-light:hover .portfolio-image:after,
  .grid-item.overlay-light:hover .portfolio-slider .owl-stage-outer:after,
  .grid-item.overlay-light:hover .grid-image:after,
  .grid-item.overlay-light:hover .grid-slider .owl-stage-outer:after {
    opacity: 0.9; }
  .portfolio-item.overlay-grey .portfolio-image:after,
  .portfolio-item.overlay-grey .portfolio-slider .owl-stage-outer:after,
  .portfolio-item.overlay-grey .grid-image:after,
  .portfolio-item.overlay-grey .grid-slider .owl-stage-outer:after,
  .grid-item.overlay-grey .portfolio-image:after,
  .grid-item.overlay-grey .portfolio-slider .owl-stage-outer:after,
  .grid-item.overlay-grey .grid-image:after,
  .grid-item.overlay-grey .grid-slider .owl-stage-outer:after {
    background-color: #F2F2F4; }
  .portfolio-item.overlay-grey .portfolio-description h3,
  .portfolio-item.overlay-grey .grid-description h3,
  .grid-item.overlay-grey .portfolio-description h3,
  .grid-item.overlay-grey .grid-description h3 {
    color: #565656; }
  .portfolio-item.overlay-grey .portfolio-description p,
  .portfolio-item.overlay-grey .portfolio-description span,
  .portfolio-item.overlay-grey .grid-description p,
  .portfolio-item.overlay-grey .grid-description span,
  .grid-item.overlay-grey .portfolio-description p,
  .grid-item.overlay-grey .portfolio-description span,
  .grid-item.overlay-grey .grid-description p,
  .grid-item.overlay-grey .grid-description span {
    color: #a8a8a8; }
  .portfolio-item.overlay-grey:hover .portfolio-image:after,
  .portfolio-item.overlay-grey:hover .portfolio-slider .owl-stage-outer:after,
  .portfolio-item.overlay-grey:hover .grid-image:after,
  .portfolio-item.overlay-grey:hover .grid-slider .owl-stage-outer:after,
  .grid-item.overlay-grey:hover .portfolio-image:after,
  .grid-item.overlay-grey:hover .portfolio-slider .owl-stage-outer:after,
  .grid-item.overlay-grey:hover .grid-image:after,
  .grid-item.overlay-grey:hover .grid-slider .owl-stage-outer:after {
    opacity: 1; }
  .portfolio-item.overlay-white .portfolio-image:after,
  .portfolio-item.overlay-white .portfolio-slider .owl-stage-outer:after,
  .portfolio-item.overlay-white .grid-image:after,
  .portfolio-item.overlay-white .grid-slider .owl-stage-outer:after,
  .grid-item.overlay-white .portfolio-image:after,
  .grid-item.overlay-white .portfolio-slider .owl-stage-outer:after,
  .grid-item.overlay-white .grid-image:after,
  .grid-item.overlay-white .grid-slider .owl-stage-outer:after {
    background-color: #ffffff; }
  .portfolio-item.overlay-white .portfolio-description h3,
  .portfolio-item.overlay-white .grid-description h3,
  .grid-item.overlay-white .portfolio-description h3,
  .grid-item.overlay-white .grid-description h3 {
    color: #565656; }
  .portfolio-item.overlay-white .portfolio-description p,
  .portfolio-item.overlay-white .portfolio-description span,
  .portfolio-item.overlay-white .grid-description p,
  .portfolio-item.overlay-white .grid-description span,
  .grid-item.overlay-white .portfolio-description p,
  .grid-item.overlay-white .portfolio-description span,
  .grid-item.overlay-white .grid-description p,
  .grid-item.overlay-white .grid-description span {
    color: #a8a8a8; }
  .portfolio-item.overlay-white:hover .portfolio-image:after,
  .portfolio-item.overlay-white:hover .portfolio-slider .owl-stage-outer:after,
  .portfolio-item.overlay-white:hover .grid-image:after,
  .portfolio-item.overlay-white:hover .grid-slider .owl-stage-outer:after,
  .grid-item.overlay-white:hover .portfolio-image:after,
  .grid-item.overlay-white:hover .portfolio-slider .owl-stage-outer:after,
  .grid-item.overlay-white:hover .grid-image:after,
  .grid-item.overlay-white:hover .grid-slider .owl-stage-outer:after {
    opacity: 1; }
  .portfolio-item.overlay-dark .portfolio-image:after,
  .portfolio-item.overlay-dark .portfolio-slider .owl-stage-outer:after,
  .portfolio-item.overlay-dark .grid-image:after,
  .portfolio-item.overlay-dark .grid-slider .owl-stage-outer:after,
  .grid-item.overlay-dark .portfolio-image:after,
  .grid-item.overlay-dark .portfolio-slider .owl-stage-outer:after,
  .grid-item.overlay-dark .grid-image:after,
  .grid-item.overlay-dark .grid-slider .owl-stage-outer:after {
    background-color: #000000; }
  .portfolio-item.overlay-dark .portfolio-description h3,
  .portfolio-item.overlay-dark .grid-description h3,
  .grid-item.overlay-dark .portfolio-description h3,
  .grid-item.overlay-dark .grid-description h3 {
    color: #ffffff; }
  .portfolio-item.overlay-dark .portfolio-description p,
  .portfolio-item.overlay-dark .portfolio-description span,
  .portfolio-item.overlay-dark .grid-description p,
  .portfolio-item.overlay-dark .grid-description span,
  .grid-item.overlay-dark .portfolio-description p,
  .grid-item.overlay-dark .portfolio-description span,
  .grid-item.overlay-dark .grid-description p,
  .grid-item.overlay-dark .grid-description span {
    color: #a8a8a8; }
  .portfolio-item.overlay-dark:hover .portfolio-image:after,
  .portfolio-item.overlay-dark:hover .portfolio-slider .owl-stage-outer:after,
  .portfolio-item.overlay-dark:hover .grid-image:after,
  .portfolio-item.overlay-dark:hover .grid-slider .owl-stage-outer:after,
  .grid-item.overlay-dark:hover .portfolio-image:after,
  .grid-item.overlay-dark:hover .portfolio-slider .owl-stage-outer:after,
  .grid-item.overlay-dark:hover .grid-image:after,
  .grid-item.overlay-dark:hover .grid-slider .owl-stage-outer:after {
    opacity: 1; }
  .portfolio-item.overlay-padding .portfolio-image:after,
  .portfolio-item.overlay-padding .grid-image:after,
  .grid-item.overlay-padding .portfolio-image:after,
  .grid-item.overlay-padding .grid-image:after {
    bottom: 15px;
    height: auto;
    left: 15px;
    right: 15px;
    top: 15px;
    width: auto; }
  .portfolio-item.overlay-border .portfolio-image:after,
  .portfolio-item.overlay-border .grid-image:after,
  .grid-item.overlay-border .portfolio-image:after,
  .grid-item.overlay-border .grid-image:after {
    opacity: 1;
    background-color: transparent;
    position: absolute; }
  .portfolio-item.overlay-border .portfolio-description,
  .grid-item.overlay-border .portfolio-description {
    display: flex;
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    border: 0 solid #00BCD4;
    transition: border .4s ease; }
  .portfolio-item.overlay-border:hover .portfolio-description,
  .grid-item.overlay-border:hover .portfolio-description {
    border-width: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4); }
  .portfolio-item.open-cursor .portfolio-wrap > a,
  .portfolio-item.open-cursor .grid-wrap > a,
  .grid-item.open-cursor .portfolio-wrap > a,
  .grid-item.open-cursor .grid-wrap > a {
    cursor: crosshair;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5; }
  .portfolio-item.no-overlay .portfolio-slider .owl-stage-outer:after,
  .portfolio-item.no-overlay .grid-slider .owl-stage-outer:after,
  .grid-item.no-overlay .portfolio-slider .owl-stage-outer:after,
  .grid-item.no-overlay .grid-slider .owl-stage-outer:after {
    display: none; }
  .portfolio-item.no-overlay .portfolio-image,
  .portfolio-item.no-overlay .grid-image,
  .grid-item.no-overlay .portfolio-image,
  .grid-item.no-overlay .grid-image {
    position: relative;
    overflow: hidden; }
    .portfolio-item.no-overlay .portfolio-image:after,
    .portfolio-item.no-overlay .grid-image:after,
    .grid-item.no-overlay .portfolio-image:after,
    .grid-item.no-overlay .grid-image:after {
      display: none; }
    .portfolio-item.no-overlay .portfolio-image img,
    .portfolio-item.no-overlay .grid-image img,
    .grid-item.no-overlay .portfolio-image img,
    .grid-item.no-overlay .grid-image img {
      position: relative;
      transition: all 0.5s ease-out;
      width: 100%;
      z-index: 1;
      display: block;
      height: auto; }
  .portfolio-item.no-overlay .portfolio-description,
  .portfolio-item.no-overlay .grid-description,
  .grid-item.no-overlay .portfolio-description,
  .grid-item.no-overlay .grid-description {
    opacity: 1;
    padding: 6px;
    position: relative;
    transform: translateY(-10px);
    visibility: visible;
    width: 100%; }
    .portfolio-item.no-overlay .portfolio-description h3,
    .portfolio-item.no-overlay .grid-description h3,
    .grid-item.no-overlay .portfolio-description h3,
    .grid-item.no-overlay .grid-description h3 {
      color: #565656;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 18px;
      margin-bottom: 4px;
      text-transform: uppercase; }
    .portfolio-item.no-overlay .portfolio-description span,
    .portfolio-item.no-overlay .grid-description span,
    .grid-item.no-overlay .portfolio-description span,
    .grid-item.no-overlay .grid-description span {
      color: #565656;
      font-family: "Raleway", "Helvetica", "Arial", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;
      text-transform: capitalize;
      opacity: 1; }
    .portfolio-item.no-overlay .portfolio-description p,
    .portfolio-item.no-overlay .grid-description p,
    .grid-item.no-overlay .portfolio-description p,
    .grid-item.no-overlay .grid-description p {
      font-family: "Raleway", "Helvetica", "Arial", sans-serif;
      color: #565656;
      font-weight: 500;
      font-size: 14px;
      opacity: 1; }
  .portfolio-item.shadow,
  .grid-item.shadow {
    box-shadow: none; }
    .portfolio-item.shadow .portfolio-item-wrap,
    .portfolio-item.shadow .grid-item-wrap,
    .grid-item.shadow .portfolio-item-wrap,
    .grid-item.shadow .grid-item-wrap {
      box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7); }
  .portfolio-item.outline .portfolio-item-wrap,
  .portfolio-item.outline .grid-item-wrap,
  .grid-item.outline .portfolio-item-wrap,
  .grid-item.outline .grid-item-wrap {
    border: 1px solid #ebebeb;
    padding: 1px; }
  .portfolio-item.light-bg .portfolio-item-wrap,
  .portfolio-item.light-bg .grid-item-wrap,
  .grid-item.light-bg .portfolio-item-wrap,
  .grid-item.light-bg .grid-item-wrap {
    background-color: #fff;
    border: 1px solid #f3f3f3; }
    .portfolio-item.light-bg .portfolio-item-wrap .portfoio-description,
    .portfolio-item.light-bg .grid-item-wrap .portfoio-description,
    .grid-item.light-bg .portfolio-item-wrap .portfoio-description,
    .grid-item.light-bg .grid-item-wrap .portfoio-description {
      margin-bottom: 20px; }
  .portfolio-item.grey-bg .portfolio-item-wrap,
  .portfolio-item.grey-bg .grid-item-wrap,
  .grid-item.grey-bg .portfolio-item-wrap,
  .grid-item.grey-bg .grid-item-wrap {
    background-color: #f3f3f3; }
    .portfolio-item.grey-bg .portfolio-item-wrap .portfoio-description,
    .portfolio-item.grey-bg .grid-item-wrap .portfoio-description,
    .grid-item.grey-bg .portfolio-item-wrap .portfoio-description,
    .grid-item.grey-bg .grid-item-wrap .portfoio-description {
      margin-bottom: 20px; }

.alterneting-size .portfolio-item:nth-child(2n) .portfolio-item-wrap,
.alterneting-size .grid-item:nth-child(2n) .grid-item-wrap {
  transform: scale(0.75); }

.portfolio-slider,
.grid-slider {
  width: 100%; }
  .portfolio-slider.shadow,
  .grid-slider.shadow {
    box-shadow: 10px 10px 10px rgba(235, 235, 235, 0.5); }
  .portfolio-slider.outline,
  .grid-slider.outline {
    border: 3px solid #fff; }

/*Sidebar version*/
.content.col-md-9 .portfolio-item .portfolio-description h3,
.content.col-md-9 .portfolio-item .grid-description h3,
.content.col-md-9 .grid-item .portfolio-description h3,
.content.col-md-9 .grid-item .grid-description h3 {
  font-size: 13px;
  line-height: 14px; }

.container-fluid .content.col-md-9 .portfolio-item .portfolio-description h3,
.container-fluid .content.col-md-9 .portfolio-item .grid-description h3,
.container-fluid .content.col-md-9 .grid-item .portfolio-description h3,
.container-fluid .content.col-md-9 .grid-item .grid-description h3 {
  font-size: 16px;
  line-height: 22px; }

.sidebar-both .portfolio-item .portfolio-description h3,
.sidebar-both .portfolio-item .grid-description h3,
.sidebar-both .grid-item .portfolio-description h3,
.sidebar-both .grid-item .grid-description h3 {
  font-size: 13px;
  line-height: 14px; }
.sidebar-both .portfolio-item .portfolio-description p,
.sidebar-both .portfolio-item .portfolio-description span,
.sidebar-both .portfolio-item .grid-description p,
.sidebar-both .portfolio-item .grid-description span,
.sidebar-both .grid-item .portfolio-description p,
.sidebar-both .grid-item .portfolio-description span,
.sidebar-both .grid-item .grid-description p,
.sidebar-both .grid-item .grid-description span {
  font-size: 12px; }

/*Portfolio page*/
.portfolio-content {
  position: relative; }
  .portfolio-content img {
    width: 100%; }

.portfolio-attributes {
  color: #1f1f1f;
  font-size: 12px;
  overflow: hidden;
  text-transform: uppercase;
  margin-bottom: 20px; }
  .portfolio-attributes .attribute {
    float: left;
    margin-bottom: 20px;
    padding-right: 20px;
    text-align: left;
    width: auto; }
    .portfolio-attributes .attribute strong {
      opacity: 0.6;
      border-bottom: 1px solid rgba(31, 31, 31, 0.05);
      display: block;
      width: auto;
      margin-bottom: 10px;
      font-size: 11px; }
  .portfolio-attributes.style1 .attribute {
    display: inline-flex;
    float: none;
    margin-bottom: 6px; }
    .portfolio-attributes.style1 .attribute strong {
      border: 0;
      margin-right: 8px;
      width: auto; }
  .portfolio-attributes.style2 .attribute {
    float: none;
    width: 100%; }
    .portfolio-attributes.style2 .attribute strong {
      border: 0;
      width: 100%; }

.project-description {
  margin-bottom: 40px; }
  .project-description h2 {
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px; }
  .project-description h3 {
    font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
    font-size: 22px;
    font-weight: 100;
    margin-bottom: 20px; }

/*Portfolio Ajax  Page*/
.portfolio-ajax-page [class*="col-"] {
  padding: 0; }
.portfolio-ajax-page .carousel {
  margin-bottom: 2px; }
.portfolio-ajax-page .project-description {
  padding: 24px 40px;
  margin-bottom: 0; }
  .portfolio-ajax-page .project-description > p {
    padding-bottom: 6px; }
  .portfolio-ajax-page .project-description .portfolio-attributes {
    margin-top: 20px; }
    .portfolio-ajax-page .project-description .portfolio-attributes .attribute {
      margin-bottom: 0;
      width: 100%; }
      .portfolio-ajax-page .project-description .portfolio-attributes .attribute > strong {
        min-width: 68px; }

/*# sourceMappingURL=portfolio.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/*Post Item*/
.post-item {
  float: left;
  width: 100%;
  height: auto;
  padding: 0 0 20px 0;
  /*Styles*/
  /*Types*/ }
  .post-item .post-item-wrap {
    background-color: #ffffff;
    position: relative;
    /*overflow: hidden;*/ }
  .post-item img {
    width: 100%;
    height: auto; }
  .post-item .post-image,
  .post-item .post-slider,
  .post-item .post-video,
  .post-item .post-audio,
  .post-item .post-quote-img {
    margin: 0;
    position: relative;
    padding: 0; }
    .post-item .post-image .post-meta-category,
    .post-item .post-slider .post-meta-category,
    .post-item .post-video .post-meta-category,
    .post-item .post-audio .post-meta-category,
    .post-item .post-quote-img .post-meta-category {
      background: #1abc9c;
      color: #ffffff;
      font-size: 13px;
      padding: 2px 16px;
      border-radius: 50px;
      position: absolute;
      right: 10px;
      top: 11px;
      z-index: 3;
      margin-right: 0; }
      .post-item .post-image .post-meta-category i,
      .post-item .post-slider .post-meta-category i,
      .post-item .post-video .post-meta-category i,
      .post-item .post-audio .post-meta-category i,
      .post-item .post-quote-img .post-meta-category i {
        margin-right: 4px; }
      .post-item .post-image .post-meta-category a,
      .post-item .post-slider .post-meta-category a,
      .post-item .post-video .post-meta-category a,
      .post-item .post-audio .post-meta-category a,
      .post-item .post-quote-img .post-meta-category a {
        color: #ffffff !important; }
  .post-item .post-quote-img .post-meta-category {
    background: #ffffff;
    color: #1abc9c; }
    .post-item .post-quote-img .post-meta-category a {
      color: #1abc9c !important; }
  .post-item .post-video video,
  .post-item .post-video iframe {
    width: 100%;
    background-color: #000000; }
  .post-item .post-audio > a > img {
    margin-bottom: -28px; }
  .post-item .post-audio audio,
  .post-item .post-audio iframe {
    width: 100%; }
  .post-item .post-audio audio {
    margin-bottom: -6px; }
  .post-item .post-item-description {
    padding: 24px;
    line-height: 28px;
    color: #676767; }
    .post-item .post-item-description > h2,
    .post-item .post-item-description > h2 > a {
      font-size: 18px;
      letter-spacing: -0.1px;
      line-height: 23px;
      margin-bottom: 16px;
      color: #444;
      font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
      font-weight: 600; }
    .post-item .post-item-description .post-meta-date,
    .post-item .post-item-description .post-meta-comments,
    .post-item .post-item-description .post-meta-category {
      color: #C2C2C2;
      position: relative;
      top: -4px;
      font-size: 12px;
      margin-right: 8px; }
      .post-item .post-item-description .post-meta-date i,
      .post-item .post-item-description .post-meta-comments i,
      .post-item .post-item-description .post-meta-category i {
        margin-right: 4px !important; }
      .post-item .post-item-description .post-meta-date a,
      .post-item .post-item-description .post-meta-comments a,
      .post-item .post-item-description .post-meta-category a {
        color: #C2C2C2; }
    .post-item .post-item-description .post-post-likes {
      float: right;
      font-size: 14px;
      color: #aaa; }
      .post-item .post-item-description .post-post-likes i {
        margin-right: 8px;
        color: #aaa; }
  .post-item.shadow {
    box-shadow: none; }
    .post-item.shadow .post-item-wrap {
      box-shadow: 0 1px 10px #efefef !important; }
  .post-item.grey-bg .post-item-wrap {
    background-color: #f7f7f7; }
  .post-item.border {
    border: none; }
    .post-item.border .post-item-wrap {
      border: 1px solid #f2f2f2; }
    .post-item.border > .post-item-wrap > .post-item-description {
      padding: 24px 10px !important;
      width: 100% !important; }
  .post-item.quote .post-item-wrap {
    background-color: #1abc9c;
    border-radius: 4px;
    border-width: 0; }
  .post-item.quote .post-quote-img img {
    width: auto;
    margin-left: 40px;
    margin-top: 40px; }
  .post-item.quote .post-item-description {
    padding: 40px; }
    .post-item.quote .post-item-description .post-meta-date,
    .post-item.quote .post-item-description .post-meta-comments,
    .post-item.quote .post-item-description .post-meta-category {
      color: #ffffff;
      opacity: 0.8; }
      .post-item.quote .post-item-description .post-meta-date a,
      .post-item.quote .post-item-description .post-meta-date p,
      .post-item.quote .post-item-description .post-meta-comments a,
      .post-item.quote .post-item-description .post-meta-comments p,
      .post-item.quote .post-item-description .post-meta-category a,
      .post-item.quote .post-item-description .post-meta-category p {
        color: #ffffff; }
    .post-item.quote .post-item-description > h3,
    .post-item.quote .post-item-description > h3 > a,
    .post-item.quote .post-item-description a,
    .post-item.quote .post-item-description p,
    .post-item.quote .post-item-description .item-link {
      color: #ffffff; }
    .post-item.quote .post-item-description .post-author {
      font-size: 14px;
      height: 40px; }
      .post-item.quote .post-item-description .post-author > img {
        height: 28px;
        margin-right: 8px;
        width: 28px; }

.post-items-transparent .post-item .post-item-wrap {
  border: 0;
  background-color: transparent; }
  .post-items-transparent .post-item .post-item-wrap .post-item-description > p,
  .post-items-transparent .post-item .post-item-wrap .post-item-description .post-meta-date,
  .post-items-transparent .post-item .post-item-wrap .post-item-description .post-meta-comments,
  .post-items-transparent .post-item .post-item-wrap .post-item-description .post-meta-category {
    opacity: .7; }

/*Single Post*/
.single-post .post-item {
  /*padding: 0 0 20px 0;*/ }
  .single-post .post-item img {
    width: 100%;
    height: auto; }
  .single-post .post-item .post-item-description {
    padding: 22px 0;
    line-height: 28px;
    color: #676767; }
    .single-post .post-item .post-item-description p {
      font-size: 16px; }
    .single-post .post-item .post-item-description > h2,
    .single-post .post-item .post-item-description > h2 > a {
      font-size: 34px;
      letter-spacing: 0;
      line-height: 38px;
      margin-bottom: 12px;
      margin-top: 8px;
      color: #252525; }
    .single-post .post-item .post-item-description .post-meta-date,
    .single-post .post-item .post-item-description .post-meta-comments,
    .single-post .post-item .post-item-description .post-meta-category {
      color: #777;
      font-size: 13px;
      margin-right: 16px; }
      .single-post .post-item .post-item-description .post-meta-date a,
      .single-post .post-item .post-item-description .post-meta-comments a,
      .single-post .post-item .post-item-description .post-meta-category a {
        color: #777; }
    .single-post .post-item .post-item-description .post-meta-share {
      float: right; }
      .single-post .post-item .post-item-description .post-meta-share .btn {
        display: inline-block;
        margin-right: 0; }
    .single-post .post-item .post-item-description .post-meta {
      border-bottom: 1px solid #f3f3f3;
      clear: both;
      display: block;
      margin-bottom: 26px;
      padding: 12px 0;
      position: relative;
      width: 100%; }
  .single-post .post-item .post-tags {
    display: block;
    margin-bottom: 40px;
    position: relative;
    width: 100%; }
    .single-post .post-item .post-tags a {
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      font-size: 12px;
      margin-bottom: 10px;
      padding: 3px 10px; }

/*Thumbnail Version*/
.post-thumbnails .post-item {
  width: 100%;
  padding: 0 0 20px 0; }
  .post-thumbnails .post-item .post-image,
  .post-thumbnails .post-item .post-slider,
  .post-thumbnails .post-item .post-video,
  .post-thumbnails .post-item .post-audio {
    float: left;
    width: 46%; }
  .post-thumbnails .post-item:not(.quote) .post-item-description {
    float: left;
    padding-top: 0;
    width: 54% !important; }

#blog .blog .post-item {
  width: 25%;
  padding: 0 20px 20px 0; }

/*Single Page*/
.post-post-title h1 {
  font-size: 48px;
  letter-spacing: -.03em;
  line-height: 1;
  font-weight: 700; }

.post-image {
  margin-bottom: 20px; }
  .post-image img {
    width: 100%; }

.post-metaa {
  margin-bottom: 20px;
  min-height: 60px; }
  .post-metaa div {
    border-right: 1px solid #f6f6f6;
    float: left;
    margin-right: 18px;
    padding: 8px 18px 8px 0; }
  .post-metaa :last-child {
    border-right: 0; }
  .post-metaa .social-icons {
    margin-top: 8px; }
  .post-metaa .post-comments i,
  .post-metaa .post-shares i {
    margin-right: 4px;
    font-size: 20px;
    float: left; }
  .post-metaa .post-comments .post-shares-number,
  .post-metaa .post-comments .post-comments-number,
  .post-metaa .post-shares .post-shares-number,
  .post-metaa .post-shares .post-comments-number {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    display: block; }
  .post-metaa .post-comments .post-shares-label,
  .post-metaa .post-comments .post-comments-label,
  .post-metaa .post-shares .post-shares-label,
  .post-metaa .post-shares .post-comments-label {
    color: #aaa;
    font-size: 12px;
    text-transform: uppercase;
    display: block; }

.post-author {
  border-top: 1px solid #eee;
  font-size: 14px;
  height: 40px;
  margin-top: 15px;
  padding-bottom: 0;
  padding-top: 16px; }
  .post-author a {
    color: #1abc9c; }
  .post-author p {
    position: relative; }
  .post-author > img {
    border-radius: 50%;
    height: 28px;
    float: left;
    margin-right: 8px;
    width: 28px; }

.post-content {
  clear: both;
  display: block;
  padding: 0 40px 0 0; }

.sidebar > h3:nth-of-type(2) {
  margin-top: 17px; }

.story-click {
  position: relative;
  width: 270px;
  position: relative;
  margin-bottom: 20px; }

.story-click img {
  height: 72px;
  margin-right: 10px; }

a.reco {
  position: absolute;
  line-height: 18px;
  font-weight: 700; }

span.rec-by {
  font-size: 11px;
  position: relative;
  top: 31px; }

.rec-n {
  position: absolute;
  text-transform: uppercase;
  background-color: #1abc9c;
  height: 32px;
  text-indent: 1px;
  width: 32px;
  display: block;
  color: #FFF;
  text-align: center;
  font-weight: 500;
  border-radius: 50%;
  border: 2px solid #FFF;
  font-size: 18px;
  line-height: 30px;
  position: absolute;
  left: -14px;
  bottom: 22px; }

/* ----------------------------------------------------------------------
  NEWS SECTIONS
-------------------------------------------------------------------------*/
.news-section-wrapper {
  clear: both;
  display: block;
  padding: 0 80px;
  position: relative; }

/* ----------------------------------------------------------------------
   GRID ARTICLE BOXES
-------------------------------------------------------------------------*/
.grid-articles {
  position: relative; }

.grid-articles .post-entry {
  float: left;
  width: 25%;
  position: relative; }

.grid-articles .post-entry:first-child {
  width: 50%; }

.grid-articles.carousel .post-entry:first-child {
  width: 100%; }

.grid-articles.grid-articles-v2 .post-entry:nth-child(1),
.grid-articles.grid-articles-v2 .post-entry:nth-child(2) {
  width: 50%; }

.grid-articles .post-entry-overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.grid-articles .post-entry .post-entry-meta {
  height: auto;
  bottom: 0;
  left: 0;
  padding: 0 30px 30px;
  position: absolute;
  width: 100%;
  z-index: 2; }

.grid-articles .post-entry:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 20%, rgba(0, 0, 0, 0.9) 100%);
  opacity: 0.4;
  transition: opacity 0.4s ease;
  z-index: 1; }

.grid-articles .post-entry:before {
  content: "";
  height: auto;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1; }

.grid-articles .post-entry:hover:before {
  opacity: 1;
  z-index: 1; }

.grid-articles .post-entry .post-entry-meta .post-entry-meta-category {
  margin-bottom: 10px; }

.grid-articles .post-entry .post-entry-meta .post-entry-meta-title h2,
.grid-articles .post-entry .post-entry-meta .post-entry-meta-title h2 a {
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0; }

.grid-articles .post-entry:first-child .post-entry-meta .post-entry-meta-title h2,
.grid-articles .post-entry:first-child .post-entry-meta .post-entry-meta-title h2 a,
.grid-articles.grid-articles-v2 .post-entry:nth-child(2) .post-entry-meta .post-entry-meta-title h2 a {
  font-size: 22px; }

/*with space*/
.grid-articles.grid-articles-space .post-entry .post-entry-overlay {
  border-color: #fff;
  border-style: solid;
  border-width: 4px; }

.grid-articles.grid-articles-space .post-entry:first-child .post-entry-overlay {
  border-width: 4px 4px 4px 0px; }

.grid-articles.grid-articles-space .post-entry:nth-child(3) .post-entry-overlay,
.grid-articles.grid-articles-space .post-entry:nth-child(5) .post-entry-overlay {
  border-width: 4px 0 4px 4px; }

.grid-articles .post-date {
  color: #fff;
  opacity: 0.6; }

.grid-articles .post-entry-meta .product-description,
.post-entry-meta .product-description a {
  color: #fff !important; }

/*---------------------------------------------------
      POST NEWS THUMBNAIL BOXES
 -------------------------------------------------*/
.post-thumbnail .post-thumbnail-entry > img {
  margin-bottom: 14px;
  max-height: 240px;
  width: 100%; }
.post-thumbnail .post-thumbnail-entry .post-thumbnail-content p {
  margin-bottom: 6px; }
.post-thumbnail ~ .post-thumbnail-list {
  border-top: 1px solid #F2F2F4; }
  .post-thumbnail ~ .post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a {
    font-weight: 400;
    opacity: 0.7;
    line-height: 20px; }
    .post-thumbnail ~ .post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a:hover {
      opacity: 1; }

/*Post thumbnail list*/
.post-thumbnail-list {
  position: relative; }
  .post-thumbnail-list .post-thumbnail-entry img + .post-thumbnail-content {
    padding-left: 90px; }
  .post-thumbnail-list .post-thumbnail-entry > img {
    float: left;
    display: block;
    height: 60px;
    width: 80px;
    margin-right: 16px;
    border-radius: 2px; }
  .post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a {
    font-size: 14px;
    font-weight: 600;
    margin: -4px 0 0; }

.post-thumbnail-entry {
  border-bottom: 1px solid #F2F2F4;
  float: left;
  margin-bottom: 16px;
  padding-bottom: 10px;
  position: relative;
  clear: both; }
  .post-thumbnail-entry:last-child {
    border-bottom: 0; }
  .post-thumbnail-entry .post-thumbnail-content > a {
    display: block; }
  .post-thumbnail-entry .post-thumbnail-content .post-date,
  .post-thumbnail-entry .post-thumbnail-content .post-category {
    color: #aaa;
    font-size: 13px; }
    .post-thumbnail-entry .post-thumbnail-content .post-date i,
    .post-thumbnail-entry .post-thumbnail-content .post-category i {
      margin-right: 2px; }
  .post-thumbnail-entry .post-thumbnail-content .post-date ~ .post-category {
    margin-left: 6px; }
  .post-thumbnail-entry .post-thumbnail-content .post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: 12px !important; }

/*Responsive*/
@media (max-width: 767px) {
  .post-thumbnails .post-item {
    width: 100%; }
    .post-thumbnails .post-item .post-image,
    .post-thumbnails .post-item .post-slider,
    .post-thumbnails .post-item .post-video,
    .post-thumbnails .post-item .post-audio {
      float: none;
      width: 100%; }
    .post-thumbnails .post-item:not(.quote) .post-item-description {
      padding-top: 24px;
      float: none;
      width: 100%; } }

/*# sourceMappingURL=blog.css.map */

/*------------------------------------------------------
     Comments
------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.comments {
  margin: 0; }
  .comments .comment_number {
    font-size: 18px;
    margin: 0 0 30px; }
    .comments .comment_number span {
      color: #1abc9c; }
  .comments .comment {
    padding: 10px 0;
    background-color: #fff; }
    .comments .comment .image {
      border-radius: 65px;
      display: block;
      float: left;
      height: 65px;
      overflow: hidden;
      width: 65px; }
    .comments .comment .text {
      padding: 0 0 0 86px;
      min-height: 80px;
      position: relative; }
      .comments .comment .text .name {
        display: block;
        color: #303030;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: -4px; }
    .comments .comment .comment_date {
      color: #888;
      font-size: 12px; }
    .comments .comment .text .comment-reply-link {
      color: #1abc9c;
      font-size: 9px;
      text-transform: uppercase;
      font-weight: 700;
      padding: 0 0 0 5px; }
    .comments .comment .text .text_holder {
      display: block;
      margin: 3px 0 0 0; }
    .comments .comment .comment {
      margin-left: 90px; }

.post-item .comments {
  padding: 40px 0;
  border-top: 1px solid #eee; }

.respond-form {
  padding-top: 40px; }

.respond-comment {
  font-size: 18px;
  margin: 0 0 30px; }
  .respond-comment span {
    color: #1abc9c; }

@media (max-width: 991px) {
  .comments {
    padding: 22px 0 30px;
    margin: 0; }
    .comments .comment .image {
      border-radius: 48px;
      height: 48px;
      width: 48px;
      margin-right: 14px; }
    .comments .comment .text {
      padding: 0; }
    .comments .comment .comment {
      margin-left: 30px; } }

/*# sourceMappingURL=comments.css.map */

.shop {
  position: relative; }

.product {
  margin-bottom: 40px;
  position: relative;
  display: block; }
  .product .product-image {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden; }
  .product .product-image > a,
  .product .product-image img {
    display: block;
    width: 100%;
    height: 100%; }
  .product .product-image > a,
  .product .product-wishlist a {
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease; }
  .product .product-image > a:nth-of-type(2) {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    position: absolute; }
  .product .product-image:hover > a:nth-of-type(2) {
    opacity: 1;
    z-index: 2; }
  .product .product-wishlist a {
    border: 2px solid #333;
    border-radius: 50%;
    height: 30px;
    left: 12px;
    line-height: 28px;
    position: absolute;
    text-align: center;
    top: 12px;
    width: 30px;
    z-index: -1;
    opacity: 0; }
  .product:hover .product-wishlist,
  .product:hover .product-wishlist a {
    z-index: 3;
    opacity: 1; }
  .product .product-description {
    padding: 8px 0 10px; }
  .product .product-title h3,
  .product .product-title h3 a {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0; }
  .product .product-category {
    display: block;
    opacity: 0.5; }
  .product .product-price {
    width: 26%;
    float: right;
    margin-bottom: 0;
    margin-top: -26px;
    text-align: right; }
    .product .product-price ins {
      color: #444;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 4px;
      text-decoration: none; }
    .product .product-price del {
      display: block;
      font-size: 12px;
      font-weight: 400;
      margin: -20px 0 -4px;
      opacity: 0.6; }
  .product .product-rate {
    height: 23px;
    float: left;
    color: #FFC300; }
  .product .product-reviews,
  .product .product-reviews a {
    color: #73848e;
    float: right;
    font-size: 13px; }
  .product .product-sale,
  .product .product-new,
  .product .product-hot,
  .product .product-out-stock {
    border-radius: 50%;
    color: #ffffff;
    font-size: 11px;
    font-weight: 600;
    height: 42px;
    right: 10px;
    line-height: 42px;
    position: absolute;
    text-align: center;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.14);
    top: 10px;
    width: 42px;
    z-index: 5; }
  .product .product-sale,
  .product .product-sale-off {
    background: #ffc300; }
  .product .product-new {
    background: #00c0e9; }
  .product .product-hot {
    background: #d6284b; }
  .product .product-out-stock {
    background: #a5a5a5 none repeat scroll 0 0;
    border-radius: 4px;
    padding: 0 18px;
    width: auto; }
  .product .product-sale-off {
    border-radius: 12px 0 0;
    bottom: 0px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    height: 42px;
    line-height: 42px;
    position: absolute;
    right: 0px;
    text-align: center;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.14);
    width: 88px;
    z-index: 5; }
  .product .product-overlay {
    position: absolute;
    width: 100%;
    height: 44px;
    line-height: 44px;
    top: auto;
    bottom: -44px;
    left: 0;
    z-index: 6; }
  .product .product-overlay,
  .product .product-overlay a {
    -webkit-transition: bottom .3s ease;
    -o-transition: bottom .3s ease;
    transition: bottom .3s ease; }
  .product:hover .product-overlay {
    bottom: 0; }
  .product .product-overlay a {
    color: #fff;
    display: block;
    font-size: 13px;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center; }
    .product .product-overlay a:hover {
      background-color: #111;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3); }

/*Grid Columns*/
.grid-5-columns .product .product-title h3,
.grid-5-columns .product .product-title h3 a,
.grid-5-columns .product .product-price ins,
.grid-6-columns .product .product-title h3,
.grid-6-columns .product .product-title h3 a,
.grid-6-columns .product .product-price ins {
  font-size: 14px; }
.grid-5-columns .product .product-reviews,
.grid-5-columns .product .product-reviews a,
.grid-6-columns .product .product-reviews,
.grid-6-columns .product .product-reviews a {
  font-size: 11px;
  float: left; }

.grid-5-columns .product .product-reviews,
.grid-5-columns .product .product-reviews a {
  float: right; }

.shop-category {
  position: relative; }

.shop-category-box {
  position: relative;
  margin-bottom: 20px; }
  .shop-category-box img {
    width: 100%; }
  .shop-category-box .shop-category-box-title {
    background-color: rgba(255, 255, 255, 0.9);
    bottom: 10px;
    padding: 4px 0;
    position: absolute;
    text-align: center;
    width: 100%; }

.shop-category-box-title h6 {
  margin-bottom: 0; }

.shop-cart th,
.shop-cart .table td {
  vertical-align: middle; }

.shop-cart .cart-product-remove {
  text-align: center; }
.shop-cart .cart-product-thumbnail > a {
  float: left;
  margin-right: 12px; }
.shop-cart .cart-product-thumbnail img {
  max-width: 52px; }
.shop-cart .cart-product-thumbnail .cart-product-thumbnail-name {
  padding: 24px;
  font-weight: 600; }

.cart-product-description {
  max-width: 200px;
  font-size: 12px; }
  .cart-product-description span {
    display: block; }

.cart-product-quantity .minus,
.cart-product-quantity .plus {
  border: 1px solid #eee;
  margin: 0 -4px;
  padding: 5px 12px; }
.cart-product-quantity .qty {
  border: 1px solid #eee;
  max-width: 40px;
  padding: 5px;
  text-align: center; }

.payment-method td {
  padding: 12px 20px !important; }

.product-page:not(.widget-shop) .product-title h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0; }

.product-size {
  display: block;
  list-style: outside none none;
  margin: 0 20px 15px 0;
  overflow: hidden;
  padding-left: 0; }
  .product-size li {
    float: left;
    margin: 0 -1px 0 0; }
    .product-size li label span {
      cursor: pointer;
      display: block;
      font-size: 1.2em;
      font-weight: 400;
      height: 40px;
      line-height: 37px;
      margin: 0;
      text-align: center;
      transition: all 0.3s ease 0s;
      width: 40px; }
    .product-size li label input {
      left: -9000px;
      position: absolute; }
    .product-size li label span {
      border: 1px solid #eee;
      color: #909090; }
      .product-size li label span:hover {
        background-color: #eee; }
    .product-size li label input:checked + span {
      background-color: #eee;
      border-color: #eee; }

.product .comment .product-rate {
  float: right; }

.ajax-quick-view .product {
  margin-bottom: 0px;
  margin-top: 20px; }

.widget-shop {
  position: relative; }
  .widget-shop .product {
    position: relative;
    border-bottom: 1px solid #eee;
    float: left;
    margin-bottom: 12px;
    min-height: 96px;
    padding-bottom: 12px; }
    .widget-shop .product:last-child {
      border-bottom: 0;
      margin-bottom: -4px;
      padding-bottom: 0; }
    .widget-shop .product .product-description {
      padding-top: 0px; }
    .widget-shop .product .product-image {
      float: left;
      padding-right: 10px;
      width: 26%; }
    .widget-shop .product .product-description {
      display: table-cell;
      padding-bottom: 0;
      vertical-align: top;
      width: 74%; }
    .widget-shop .product .product-title,
    .widget-shop .product .product-category {
      font-size: 12px;
      margin-bottom: 5px;
      margin-top: -8px; }
      .widget-shop .product .product-title h3,
      .widget-shop .product .product-title h3 a,
      .widget-shop .product .product-category h3,
      .widget-shop .product .product-category h3 a {
        font-size: 15px;
        line-height: 18px; }
    .widget-shop .product .product-category {
      margin-top: -6px; }
    .widget-shop .product .product-price {
      clear: both;
      float: none;
      margin-bottom: 0;
      margin-top: 0;
      text-align: left; }
      .widget-shop .product .product-price ins {
        font-size: 14px;
        margin-bottom: 4px; }
      .widget-shop .product .product-price del {
        display: unset;
        font-size: 12px;
        margin: -8px 5px -8px 0; }

.widget-tweeter .list-tweets {
  padding-left: 0; }

.widget-categories .list {
  padding: 0; }

#footer .widget-categories a {
  color: #888;
  font-size:16px;
  line-height:36px; }

/*

        .twitter-widget ul {
            margin: 0;
            padding: 0;
        }
        
        .twitter-widget li {
            list-style: none;
            clear: both;
        }
        
        .twitter-widget li img,
        .twitter-widget li .tweets_txt {
            float: left;
            margin-bottom: 10px;
        }
        
        .twitter-widget li img {
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
        }
        
        .twitter-widget li .tweets_txt {
            width: 350px;
            margin-left: 10px;
        }
        
        .twitter-widget li .tweets_txt span {
            font-style: italic;
            color: #666;
            display: block;
        }
*/
.shop-promo-box {
  min-height: 360px;
  background-repeat: no-repeat;
  padding: 50px;
  border: 8px solid #eee; }
  .shop-promo-box > h2 {
    font-weight: 800;
    margin-bottom: 0; }

/*# sourceMappingURL=shop.css.map */

.forum {
  border-radius: 4px;
  padding: 20px 26px;
  background-color: #fff; }
  .forum .fa {
    width: 1em;
    text-align: center; }
  .forum table th {
    width: 6em;
    width: 14em; }
  .forum thead tr > th {
    background-color: #1abc9c;
    color: #fff;
    border-bottom: 0; }
  .forum thead tr:first-child th:first-child {
    border-top-left-radius: 4px; }
  .forum thead tr:last-child th:last-child {
    border-top-right-radius: 4px; }
  .forum thead tr h3 {
    color: #fff;
    margin-bottom: 0px;
    font-size: 22px; }
  .forum h4 > small {
    display: block; }

/*# sourceMappingURL=forum.css.map */

.accordion {
  margin-bottom: 20px; }
  .accordion .ac-item .ac-title:before {
    font-family: fontawesome; }
  .accordion .ac-item .ac-title:before {
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 10px;
    display: block;
    padding: 3px 6px 2px;
    color: #ddd;
    content: "\f054";
    font-size: 12px;
    line-height: 12px;
    -webkit-transition: all 0.25s ease-in-out 0s;
    transition: all 0.25s ease-in-out 0s; }
  .accordion .ac-item > .ac-title > i {
    margin-right: 16px; }
  .accordion .ac-item .ac-active .ac-title:before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    ms-transform: rotate(90deg); }
  .accordion .ac-title {
    position: relative;
    padding: 12px 30px 12px 15px;
    font-size: 13px;
    line-height: 22px;
    font-weight: 600;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.06);
    margin-bottom: 2px; }
  .accordion .ac-content {
    overflow: hidden; }

.ac-content {
  padding: 16px 13px; }

.accordion.clean .ac-item .ac-title {
  border: 0px;
  background: transparent; }

.accordion .border .ac-item .ac-title {
  border: 1px solid #e7e7e7; }
.accordion .border-bottom .ac-item .ac-title {
  border-bottom: 1px solid #e7e7e7; }

.accordion.radius .ac-item .ac-title {
  border-radius: 4px; }

.accordion.fancy .ac-item {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #fff;
  border-color: #e7e7e7;
  border-style: solid;
  border-width: 1px 1px 0px 1px; }
  .accordion.fancy .ac-item .ac-title {
    margin: 0; }
  .accordion.fancy .ac-item .ac-active .ac-title,
  .accordion.fancy .ac-item :last-child {
    border-bottom: 1px solid #e7e7e7; }
.accordion.fancy.clean .ac-item.ac-active .ac-title {
  background-color: transparent; }
.accordion.fancy.radius .ac-item:first-child {
  border-radius: 4px 4px 0 0; }
.accordion.fancy.radius .ac-item:last-child {
  border-radius: 0 0 4px 4px; }
.accordion.fancy:last-child {
  border-bottom: 1px solid #eee; }
.accordion.color .ac-item .ac-title:before {
  color: #fff; }
.accordion.color .ac-item .ac-open {
  display: block; }
.accordion.accordion-transparent .ac-item {
  background-color: transparent !important;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1); }
  .accordion.accordion-transparent .ac-item .ac-title::before {
    color: #333; }
  .accordion.accordion-transparent .ac-item p {
    color: #111; }
.accordion.accordion-transparent.fancy .ac-item.ac-active .ac-title,
.accordion.accordion-transparent .ac-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

/*# sourceMappingURL=accordion-toggles.css.map */

/* ----------------------------------------------------------------------
	Buttons
-------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.btn {
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  outline: none;
  padding: 12px 20px;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  background-color: #1abc9c;
  border-color: #1abc9c;
  border-width: 1px;
  border-style: solid;
  color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 6px;
  outline: none;
  line-height: 14px; }
  .btn:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s; }
  .btn:not(.btn-outline):not(.btn-light) {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
  .btn i {
    text-shadow: none;
    margin-right: 6px; }
  .btn.btn-xs {
    font-size: 11px;
    height: 28px;
    line-height: 28px;
    padding: 0 14px; }
  .btn.btn-sm {
    font-size: 12px;
    height: 36px;
    line-height: 33px;
    padding: 0 18px; }
  .btn.btn-lg {
    font-size: 14px;
    height: 62px;
    letter-spacing: 2px;
    line-height: 60px;
    padding: 0 45px; }
  .btn.btn-outline {
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #1abc9c;
    color: #1abc9c; }
    .btn.btn-outline:hover, .btn.btn-outline:focus, .btn.btn-outline:active, .btn.btn-outline.active {
      background-color: #1abc9c;
      border-width: 2px;
      border-style: solid;
      border-color: #1abc9c;
      color: #fff; }
  .btn.btn-rounded {
    border-radius: 2em !important; }
  .btn.btn-block {
    width: 100%;
    display: block; }
  .btn.btn-light {
    background-color: #ffffff;
    border-color: #ebebeb;
    color: #4c5667; }
    .btn.btn-light:hover, .btn.btn-light:focus, .btn.btn-light:active, .btn.btn-light.active {
      background-color: #1abc9c;
      border-color: #1abc9c;
      color: #fff; }
    .btn.btn-light.btn-outline {
      border-color: #ffffff;
      background-color: transparent;
      color: #ffffff; }
      .btn.btn-light.btn-outline:hover, .btn.btn-light.btn-outline:focus, .btn.btn-light.btn-outline:active, .btn.btn-light.btn-outline.active {
        background-color: #ffffff;
        color: #4c5667; }
    .btn.btn-light.btn-light-hover:hover, .btn.btn-light.btn-light-hover:focus, .btn.btn-light.btn-light-hover:active, .btn.btn-light.btn-light-hover.active {
      background-color: #ffffff;
      border-color: #ebebeb;
      color: #4c5667; }
  .btn.btn-dark {
    background-color: #111111;
    border-color: #111111;
    color: #ffffff; }
    .btn.btn-dark:hover, .btn.btn-dark:focus, .btn.btn-dark:active, .btn.btn-dark.active {
      background-color: #1abc9c;
      border-color: #1abc9c;
      color: #fff; }
    .btn.btn-dark.btn-outline {
      background-color: transparent;
      color: #111111; }
      .btn.btn-dark.btn-outline:hover, .btn.btn-dark.btn-outline:focus, .btn.btn-dark.btn-outline:active, .btn.btn-dark.btn-outline.active {
        background-color: #111111;
        border-color: #111111;
        color: #ffffff; }
  .btn:hover, .btn:focus, .btn:active, .btn.active {
    outline: 0 !important;
    background-color: #148f77;
    border-color: #148f77;
    color: #fff; }
  .btn.btn-primary {
    background-color: #26B8F3;
    border-color: #26B8F3; }
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary.active {
      background-color: #0c9fda;
      border-color: #0c9fda;
      color: #fff; }
  .btn.btn-success {
    background-color: #81c868;
    border-color: #81c868; }
    .btn.btn-success:hover, .btn.btn-success:focus, .btn.btn-success:active, .btn.btn-success.active {
      background-color: #62b944;
      border-color: #62b944;
      color: #fff; }
  .btn.btn-info {
    background-color: #34d3eb;
    border-color: #34d3eb; }
    .btn.btn-info:hover, .btn.btn-info:focus, .btn.btn-info:active, .btn.btn-info.active {
      background-color: #15bdd7;
      border-color: #15bdd7;
      color: #fff; }
  .btn.btn-warning {
    background-color: #ffbd4a;
    border-color: #ffbd4a; }
    .btn.btn-warning:hover, .btn.btn-warning:focus, .btn.btn-warning:active, .btn.btn-warning.active {
      background-color: #ffaa17;
      border-color: #ffaa17;
      color: #fff; }
  .btn.btn-danger {
    background-color: #c30000;
    border-color: #c30000; }
    .btn.btn-danger:hover, .btn.btn-danger:focus, .btn.btn-danger:active, .btn.btn-danger.active {
      background-color: #900000;
      border-color: #900000;
      color: #fff; }
  .btn.btn-red {
    background-color: #CA0027;
    border-color: #CA0027; }
    .btn.btn-red:hover, .btn.btn-red:focus, .btn.btn-red:active, .btn.btn-red.active {
      background-color: #97001d;
      border-color: #97001d;
      color: #fff; }
  .btn.btn-slide {
    border-radius: 50px;
    display: table-cell;
    font-weight: 400;
    opacity: 1;
    overflow: hidden;
    padding: 22px;
    position: relative;
    transition: width 0.4s ease 0s, background 0.4s ease 0s;
    width: 46px; }
    .btn.btn-slide > i {
      float: left;
      left: 17px;
      position: absolute;
      top: 17px;
      transition: all 0.8s ease 0s; }
    .btn.btn-slide > span {
      left: 40px;
      position: absolute;
      text-align: left;
      top: 13px;
      transition: left 0.5s ease 0s, opacity 0.5s ease 0s;
      white-space: nowrap;
      opacity: 0; }
    .btn.btn-slide:hover {
      width: 140px;
      text-align: left; }
      .btn.btn-slide:hover > i {
        transform: rotate(360deg); }
      .btn.btn-slide:hover > span {
        opacity: 1; }
    .btn.btn-slide.btn-xs {
      padding: 14px;
      width: 30px; }
      .btn.btn-slide.btn-xs > i {
        left: 10px;
        top: 8px; }
      .btn.btn-slide.btn-xs > span {
        left: 28px;
        top: 0px; }
      .btn.btn-slide.btn-xs:hover {
        width: 110px; }
    .btn.btn-slide.btn-sm {
      padding: 18px;
      width: 36px; }
      .btn.btn-slide.btn-sm > i {
        left: 13px;
        top: 13px; }
      .btn.btn-slide.btn-sm > span {
        left: 36px;
        top: 0px; }
      .btn.btn-slide.btn-sm:hover {
        width: 110px; }
    .btn.btn-slide.btn-lg {
      padding: 30px; }
      .btn.btn-slide.btn-lg > i {
        left: 23px;
        top: 23px; }
      .btn.btn-slide.btn-lg > span {
        left: 54px;
        top: 3px; }
      .btn.btn-slide.btn-lg:hover {
        width: 200px; }
  .btn.btn-reveal {
    padding: 12px 34px; }
    .btn.btn-reveal span {
      left: 0;
      position: relative;
      transition: opacity 0.2s ease-out 0s, left 0.2s ease-out 0s; }
    .btn.btn-reveal i {
      line-height: 18px;
      margin-top: -9px;
      opacity: 0;
      position: absolute;
      right: 28px;
      top: 50%;
      transition: all 0.2s ease-out 0s;
      width: 18px; }
    .btn.btn-reveal:hover span {
      left: -10px; }
    .btn.btn-reveal:hover i {
      opacity: 1 !important;
      right: 18px; }
    .btn.btn-reveal.btn-xs {
      line-height: 2px; }
    .btn.btn-reveal.btn-sm {
      line-height: 12px; }
    .btn.btn-reveal.btn-lg {
      line-height: 26px; }
    .btn.btn-reveal.btn-reveal-left span {
      right: 0;
      left: auto;
      transition: opacity 0.2s ease-out 0s, right 0.2s ease-out 0s; }
    .btn.btn-reveal.btn-reveal-left i {
      left: 28px;
      right: auto; }
    .btn.btn-reveal.btn-reveal-left:hover span {
      right: -10px;
      left: auto; }
    .btn.btn-reveal.btn-reveal-left:hover i {
      left: 18px;
      right: auto; }
  .btn.btn-shadow {
    -webkit-box-shadow: 0 4px 16px #efefef;
    -moz-box-shadow: 0 4px 16px #efefef;
    box-shadow: 0 4px 16px #efefef; }
    .btn.btn-shadow:hover {
      -webkit-box-shadow: 4px 8px 16px #dedede;
      -moz-box-shadow: 4px 8px 16px #dedede;
      box-shadow: 4px 8px 16px #dedede; }
  .btn.btn-icon-holder {
    padding-right: 20px; }
    .btn.btn-icon-holder:before {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      content: "";
      height: 100%;
      position: absolute;
      right: 42px;
      top: 0; }
    .btn.btn-icon-holder.btn-outline:before {
      border-left: 2px solid #1abc9c; }
    .btn.btn-icon-holder.btn-outline.btn-dark:before {
      border-left: 2px solid #111; }
    .btn.btn-icon-holder.btn-light:before {
      border-left: 1px solid #f3f3f3; }
    .btn.btn-icon-holder.btn-light.btn-light-hover:before, .btn.btn-icon-holder.btn-light.btn-light-hover:hover {
      border-left: 1px solid #f3f3f3; }
    .btn.btn-icon-holder i {
      margin-left: 30px;
      margin-right: -2px; }
    .btn.btn-icon-holder.btn-xs {
      padding-right: 10px; }
      .btn.btn-icon-holder.btn-xs:before {
        right: 30px; }
      .btn.btn-icon-holder.btn-xs i {
        margin-left: 20px;
        margin-right: 2px; }
    .btn.btn-icon-holder.btn-sm {
      padding-right: 16px; }
      .btn.btn-icon-holder.btn-sm:before {
        right: 36px; }
      .btn.btn-icon-holder.btn-sm i {
        margin-left: 24px;
        margin-right: 0; }
    .btn.btn-icon-holder.btn-lg {
      padding-right: 28px; }
      .btn.btn-icon-holder.btn-lg:before {
        right: 50px; }
      .btn.btn-icon-holder.btn-lg i {
        margin-left: 34px;
        margin-right: -8px; }
  .btn.btn-creative {
    padding: 24px 40px;
    font-size: 15px;
    font-weight: 600; }
    .btn.btn-creative:before {
      right: 58px; }
    .btn.btn-creative i {
      margin-left: 54px;
      margin-right: -16px; }
  .btn.btn-facebook {
    background-color: #3b5998 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-twitter {
    background-color: #00aced !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-linkedin {
    background-color: #007bb6 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-dribbble {
    background-color: #ea4c89 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-googleplus {
    background-color: #dd4b39 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-instagram {
    background-color: #517fa4 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-pinterest {
    background-color: #cb2027 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-dropbox {
    background-color: #007ee5 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-flickr {
    background-color: #ff0084 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-tumblr {
    background-color: #32506d !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-skype {
    background-color: #00aff0 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-youtube {
    background-color: #bb0000 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn.btn-github {
    background-color: #171515 !important;
    color: #ffffff !important;
    border-width: 0; }
  .btn + .btn {
    margin-left: 2px; }

@media (max-width: 991px) {
  .btn.btn-reveal.btn-reveal-left:hover span {
    left: auto;
    right: auto; }
  .btn.btn-reveal.btn-reveal-left:hover i {
    display: none; } }
.dark #mainMenu:not(.light) .btn-shadow {
  -webkit-box-shadow: 0 4px 16px #0d0606;
  -moz-box-shadow: 0 4px 16px #0d0606;
  box-shadow: 0 4px 16px #0d0606; }
  .dark #mainMenu:not(.light) .btn-shadow:hover {
    -webkit-box-shadow: 4px 8px 16px #1f1f1f;
    -moz-box-shadow: 4px 8px 16px #1f1f1f;
    box-shadow: 4px 8px 16px #1f1f1f; }

/*# sourceMappingURL=buttons.css.map */

/* ----------------------------------------------------------------------
	Headings
-------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.heading-fancy {
  position: relative;
  margin-bottom: 30px;
  clear: both; }
  .heading-fancy h1,
  .heading-fancy h2,
  .heading-fancy h3,
  .heading-fancy h4,
  .heading-fancy h5,
  .heading-fancy h6 {
    background-color: #FFF;
    padding-right: 15px;
    margin-bottom: 0;
    position: relative;
    display: inline-block; }
  .heading-fancy h4,
  .heading-fancy h5,
  .heading-fancy h6 {
    padding-right: 10px; }
  .heading-fancy.heading-line:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    border-top: 1px solid #E5E5E5;
    left: auto;
    top: 49%;
    right: 0; }
  .heading-fancy.text-center h1,
  .heading-fancy.text-center h2,
  .heading-fancy.text-center h3,
  .heading-fancy.text-center h4,
  .heading-fancy.text-center h5,
  .heading-fancy.text-center h6 {
    padding-left: 15px; }
  .heading-fancy.text-right h1,
  .heading-fancy.text-right h2,
  .heading-fancy.text-right h3,
  .heading-fancy.text-right h4,
  .heading-fancy.text-right h5,
  .heading-fancy.text-right h6 {
    padding-left: 15px;
    padding-right: 0; }

.heading-jumbo {
  font-size: 70px;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 0.2em; }

.heading-title-border-bottom {
  border-bottom: 1px solid;
  border-bottom-color: #e7e7e7; }

.heading-title-simple {
  margin-bottom: 30px;
  display: block;
  padding: 0 0 10px; }
  .heading-title-simple h1,
  .heading-title-simple h2,
  .heading-title-simple h3,
  .heading-title-simple h4,
  .heading-title-simple h5,
  .heading-title-simple h6 {
    margin-bottom: 0;
    position: relative; }

.hr-title {
  border-top-style: solid;
  border-top-width: 1px;
  font-size: 16px;
  text-align: center;
  height: 10px;
  line-height: 20px;
  margin: 10px 0;
  height: auto;
  color: #bbbbbb; }
  .hr-title abbr {
    background-color: #ffffff; }
  .hr-title abbr {
    padding: 2px 10px;
    border-radius: 2px;
    position: relative;
    top: -10px;
    letter-spacing: .2em;
    text-transform: uppercase; }
  .hr-title i {
    position: relative;
    top: -2px;
    font-size: 8px; }
  .hr-title.hr-full {
    width: 100%; }
  .hr-title.hr-long {
    width: 50%;
    margin: 20px auto 30px; }
  .hr-title.hr-short {
    width: 25%;
    margin: 20px auto 30px; }
  .hr-title.hr-left {
    text-align: left; }
    .hr-title.hr-left abbr {
      padding-left: 0; }
    .hr-title.hr-left.hr-long {
      margin-left: 0; }
  .hr-title.hr-right {
    text-align: right; }
    .hr-title.hr-right abbr {
      padding-right: 0; }
    .hr-title.hr-right.hr-long {
      margin-right: 0; }
  .hr-title.hr-double {
    border-top: 4px double #ebebeb; }
    .hr-title.hr-double abbr {
      top: -12px; }

.heading {
  margin-bottom: 100px;
  text-align: center; }
  .heading > h1,
  .heading > h2 {
    font-size: 40px;
    font-family: "Nunito", "Helvetica", "Arial", sans-serif;
    text-transform: capitalize;
    line-height: 46px;
    font-weight: 300; }
  .heading > h1,
  .heading > h2,
  .heading > h4,
  .heading > p {
    margin-bottom: default-bottom-margin; }
  .heading.section-title > h1,
  .heading.section-title > h2 {
    font-size: 48px;
    line-height: 56px; }
  .heading.section-title > h1,
  .heading.section-title > h2,
  .heading.section-title > h4,
  .heading.section-title > p {
    margin-bottom: 20px; }
  .heading.section-title p {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px; }
    .heading.section-title p span {
      font-weight: 600; }

/*
.heading {
	margin-bottom: 80px;
	h1,
	h2,
	h3,
	h4 {
		color: #303030;
        font-weight: 300;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 10px;
	}
	&:after {
		border-top: 3px solid #303030;
        display: block;
        margin-top: 10px;
        width: 30px;
        content: "";
	}
	> span {
		display: block;
        color: #888;
	}
	h1 {
		font-size: 52px;
        line-height: 54px;
        + span,
        + span.lead {
        	font-size: 22px;
        }
	}
	h2 {
		font-size: 40px;
		line-height: 42px;
		+ span,
		+ span.lead {
			font-size: 20px;
		}
	}
	h3 {
		font-size: 28px;
		line-height: 30px;
		+ span,
		span.lead {
			font-size: 18px;
		}
	}
	h4 {
		font-size: 18px;
		line-height: 20px;
		+ span,
		span.lead {
			font-size: 15px;
		}
	}
    &.heading-center {
    text-align: center;
    float: none;
    }
	&.heading-center > span,
	&.title-center > span {
		max-width: 700px;
        margin-left: auto;
        margin-right: auto;
	}
	&.heading-center:after,
	&.title-center:after {
		margin: 30px auto 0;
	}
    
    

    
    
    
}*/
.heading.heading-light,
.text-light .heading,
.heading.heading-light p,
.heading.heading-light .lead,
.heading.heading-light h2,
.heading.heading-light h3 {
  color: #fff !important; }

.heading.heading-light:after,
.text-light .heading:after {
  border-top: 4px solid #fff; }

.heading-hero {
  border: 2px solid #ffffff;
  color: #ffffff;
  font-family: Raleway;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 8px 21px !important;
  position: relative;
  margin-bottom: 30px; }

.text-center a .title,
.text-right a .title {
  width: 100%;
  left: 0; }

.text-right a .title {
  left: auto;
  right: 30px;
  width: 100%; }

.fancy-title {
  position: relative;
  margin-bottom: 30px; }
  .fancy-title h1,
  .fancy-title h2,
  .fancy-title h3,
  .fancy-title h4,
  .fancy-title h5,
  .fancy-title h6 {
    position: relative;
    display: inline-block;
    background-color: #FFF;
    padding-right: 15px;
    margin-bottom: 0; }
  .fancy-title h4,
  .fancy-title h5,
  .fancy-title h6 {
    padding-right: 10px; }
  .fancy-title.title-double-border:before, .fancy-title.title-border:before, .fancy-title.title-border-color:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    border-top: 3px double #E5E5E5;
    left: auto;
    top: 46%;
    right: 0; }
  .fancy-title.title-border:before {
    top: 49%;
    border-top: 1px solid #EEE; }
  .fancy-title.title-border-color:before {
    top: 49%;
    border-top: 1px solid #1abc9c;
    opacity: 0.6; }

/* Fancy Title - Center Align
-----------------------------------------------------------------*/
.title-center {
  text-align: center; }
  .title-center h1,
  .title-center h2,
  .title-center h3 {
    padding: 0 15px; }
  .title-center h4,
  .title-center h5,
  .title-center h6 {
    padding: 0 10px; }

/* Fancy Title - Right Align
-----------------------------------------------------------------*/
.title-right {
  text-align: right; }
  .title-right h1,
  .title-right h2,
  .title-right h3 {
    padding: 0 0 0 15px; }
  .title-right h4,
  .title-right h5,
  .title-right h6 {
    padding: 0 0 0 10px; }

/*Creative fade heading*/
.heading-creative {
  font-family: "Raleway", "Helvetica", "Arial", sans-serif;
  font-size: 62px;
  font-weight: 100;
  position: relative;
  text-align: left;
  width: 100%; }
  .heading-creative strong {
    color: #1abc9c; }

/*# sourceMappingURL=headings.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.tabs-navigation {
  margin-bottom: 0px;
  padding-left: 0px;
  border-bottom-width: 0px;
  list-style: none; }
  .tabs-navigation::after, .tabs-navigation::before {
    content: ' ';
    display: table; }
  .tabs-navigation::after {
    clear: both; }
  .tabs-navigation li {
    position: relative;
    display: block;
    float: left;
    margin: 0px 0px -1px; }
    .tabs-navigation li a {
      border: 1px solid transparent;
      display: block;
      font-size: 13px;
      font-style: normal;
      font-weight: 100;
      letter-spacing: 0.5px;
      margin-right: -1px;
      padding: 10px 16px;
      position: relative;
      text-transform: uppercase; }
      .tabs-navigation li a i:first-child {
        margin-right: 8px; }
      .tabs-navigation li a i.tab-single-icon {
        margin: 0; }
      .tabs-navigation li a img {
        max-width: none; }
    .tabs-navigation li.active a {
      z-index: 3; }
      .tabs-navigation li.active a::after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        z-index: 1;
        height: 3px;
        transition: all 0.4s ease-in-out 0s;
        -webkit-transition: all 0.4s ease-in-out 0s; }
      .tabs-navigation li.active a:focus {
        cursor: default; }
  .tabs-navigation li a,
  .tabs-navigation li a:focus,
  .tabs-navigation li a:hover {
    text-decoration: none;
    outline: 0; }

.tabs-content {
  position: relative;
  z-index: 2;
  padding: 30px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  overflow: hidden; }
  .tabs-content > .tab-pane {
    display: none; }
  .tabs-content > .active {
    display: block; }

.tabs-vertical {
  overflow: hidden; }
  .tabs-vertical .tabs-navigation {
    float: left;
    width: 240px;
    margin-top: 0px; }
    .tabs-vertical .tabs-navigation li {
      float: none; }
      .tabs-vertical .tabs-navigation li a {
        padding: 12px 20px;
        margin: 0px;
        border: 1px solid #dddddd; }
    .tabs-vertical .tabs-navigation li.active a,
    .tabs-vertical .tabs-navigation li.active a:focus,
    .tabs-vertical .tabs-navigation li.active a:hover {
      border-right-width: 1px; }
    .tabs-vertical .tabs-navigation li.active a::after {
      top: -1px;
      left: -1px;
      right: auto;
      bottom: -1px;
      width: 3px;
      height: auto; }
  .tabs-vertical .tabs-content {
    left: -1px;
    margin: 0px 0px 40px 239px; }

.tabs-navigation li a {
  color: #70767a;
  border-color: #e5e8e8;
  background: #f3f6f6; }
  .tabs-navigation li a:hover {
    color: #1c202a;
    background: #f8fafa; }
.tabs-navigation li.active a,
.tabs-navigation li.active a:focus,
.tabs-navigation li.active a:hover {
  border-color: #e5e8e8 #e5e8e8 #f8f9fb;
  color: #1c202a;
  box-shadow: none;
  background: #f8f9fb; }

.tabs-navigation-transparent li.active a,
.tabs-navigation-transparent li.active a:focus,
.tabs-navigation-transparent li.active a:hover {
  background: 0px 0px; }

.tabs-vertical .tabs-navigation li.active a,
.tabs-vertical .tabs-navigation li.active a:focus,
.tabs-vertical .tabs-navigation li.active a:hover {
  border-right-color: white;
  border-bottom-color: #dddddd; }
.tabs-vertical.tabs-right .tabs-navigation {
  float: right; }
  .tabs-vertical.tabs-right .tabs-navigation li {
    float: none; }
.tabs-vertical.tabs-right .tabs-content {
  margin: 0px 239px 40px 0px;
  right: 0px;
  left: 0px; }
.tabs-vertical.tabs-right .tabs-navigation li.active a,
.tabs-vertical.tabs-right .tabs-navigation li.active a:focus,
.tabs-vertical.tabs-right .tabs-navigation li.active a:hover {
  border-color: #dddddd #dddddd #dddddd white;
  border-left-width: 1px;
  border-left-style: solid;
  margin-left: -1px !important; }
.tabs-vertical.tabs-right .tabs-navigation li.active a::after {
  left: auto;
  right: -1px; }
.tabs-vertical.tabs-right .tabs-navigation li a {
  margin-left: -1px;
  margin-right: auto; }

.tabs-content {
  border-color: #dddddd; }

.modern .tabs-navigation li:first-child {
  margin-left: 30px; }

.no-border .tabs-navigation li {
  border: 0; }
.no-border .tabs-content {
  border-style: solid none none;
  border-width: 1px 0px 0px; }

.simple .tabs-navigation li a {
  background-color: transparent;
  border-width: 0 0 2px;
  margin-bottom: -1px; }
.simple .tabs-navigation li.active a,
.simple .tabs-navigation li.active a:focus,
.simple .tabs-navigation li a:hover {
  border-color: #1abc9c;
  color: #1abc9c;
  background-color: transparent; }
.simple .tabs-content {
  border-width: 2px 0 0;
  padding: 20px 0px; }

.clean .tabs-navigation li {
  border: 0; }
.clean .tabs-content {
  border: 0; }
.clean .tabs-navigation li.active a,
.clean .tabs-navigation li.active a:focus,
.clean .tabs-navigation li.active a:hover,
.clean .tabs-navigation li a {
  border-width: 0; }

.border .tabs-navigation li.active a::after {
  background: #00aab3; }

.radius .tabs-navigation li a {
  border-radius: 8px 8px 0px 0px; }
.radius .tabs-navigation li.active a {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }
.radius .tabs-content {
  border-radius: 0px 8px 8px; }

.justified .tabs-navigation {
  width: 100%;
  margin-bottom: -1px; }
  .justified .tabs-navigation li {
    float: none;
    display: table-cell;
    width: 1%;
    text-align: left; }
    .justified .tabs-navigation li:last-child a {
      margin-right: 0; }

.tabs-right .tabs-navigation li {
  float: right; }

.tabs-bottom ul.tabs-navigation {
  margin-top: -21px; }
.tabs-bottom .tabs-navigation li.active a::after {
  top: auto;
  bottom: -1px; }
.tabs-bottom .tabs-navigation li.active a,
.tabs-bottom .tabs-navigation li.active a:focus,
.tabs-bottom .tabs-navigation li.active a:focus {
  border-color: transparent #dddddd #dddddd; }

.color .tabs-navigation li.active a::after {
  background: none; }
.color .tabs-navigation li.active a {
  color: white;
  background: #00c0e9; }

.linetriangle .tabs-navigation > li.active > a {
  border-color: transparent; }
.linetriangle .tabs-navigation li.active a,
.linetriangle .tabs-navigation li.active a:focus,
.linetriangle .tabs-navigation li.active a:hover,
.linetriangle .tabs-navigation li a {
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  background: transparent;
  background-color: transparent; }
.linetriangle .tabs-navigation > li.active > a:after,
.linetriangle .tabs-navigation > li.active > a:before {
  border: medium outset transparent;
  content: "";
  height: 0;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 100%;
  width: 0;
  z-index: 1; }
.linetriangle .tabs-navigation > li.active > a:after {
  border-top-color: #fafafa;
  border-width: 10px;
  margin-left: -10px; }
.linetriangle .tabs-navigation > li.active > a:before {
  border-top-color: rgba(0, 0, 0, 0.7);
  border-width: 11px;
  margin-left: -11px; }
.linetriangle.triangle-simple .tabs-content {
  background: #ffffff;
  border-color: #dddddd;
  border-width: 1px 0 0; }

@media screen and (max-width: 991px) {
  .tabs-navigation,
  .tabs-vertical .tabs-content,
  .tabs-vertical .tabs-navigation,
  .justified .tabs-navigation,
  .tabs-right .tabs-navigation {
    width: auto;
    float: none; }

  .tabs-content,
  .tabs-vertical .tabs-content,
  .justified .tabs-content,
  .tabs-right .tabs-content {
    margin-left: 0;
    min-height: 0 !important; }

  .tabs-vertical .tabs-content,
  .justified .tabs-content,
  .tabs-right .tabs-content {
    left: 0; }

  .tabs-vertical .tabs-content-transparent {
    border-left-width: 0;
    padding: 40px 0;
    border-top-width: 1px; }

  .tabs-navigation li,
  .tabs-vertical .tabs-navigation li,
  .justified .tabs-navigation li,
  .justified .tabs-navigation li,
  .tabs-right .tabs-navigation li,
  .tabs-right .tabs-navigation li {
    width: auto;
    float: none;
    display: block;
    text-align: left; }

  .tabs-navigation li a,
  .tabs-vertical .tabs-navigation li a {
    margin-right: 0; }

  .tabs-vertical .tabs-navigation li a:after {
    width: auto;
    height: 3px;
    right: -1px;
    bottom: auto; }

  .tabs-vertical .tabs-navigation li.active a {
    border-right-width: 1px; }

  .tabs-navigation li.active a,
  .tabs-navigation li.active a:hover,
  .tabs-vertical .tabs-navigation li.active a,
  .tabs-vertical .tabs-navigation li.active a:hover {
    border-right-color: #d1d7d9;
    border-bottom-color: #d1d7d9; }

  .tabs-navigation li.active a,
  .tabs-navigation li.active a:hover,
  .tabs-vertical .tabs-navigation li.active a,
  .tabs-vertical .tabs-navigation li.active a:hover {
    border-right-color: #dde1e1;
    border-bottom-color: #dde1e1; }

  .tabs-vertical.tabs-right .tabs-navigation {
    width: 100%; }

  .tabs-vertical.tabs-right .tabs-navigation li a {
    margin-left: 0px !important; }

  .tabs-vertical.tabs-right .tabs-navigation li.active a {
    margin-left: 0px !important; }

  .tabs-vertical.tabs-right .tabs-navigation li.active a:after {
    left: 0;
    right: auto; }

  .tabs-vertical.tabs-right .tabs-content {
    margin-right: 0 !important; }

  .radius .tabs-navigation li a {
    border-radius: 0px; }

  .radius .tabs-navigation li:first-child a {
    border-radius: 8px 8px 0 0; }

  .tabs.linetriangle .tabs-navigation li.active a:focus,
  .linetriangle .tabs-navigation li.active a:hover,
  .linetriangle .tabs-navigation li.active a,
  .linetriangle .tabs-navigation li a {
    border-width: 0 0 1px; } }

/*# sourceMappingURL=tabs.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.social-icons ul {
  padding: 0; }

.text-center.social-icons ul {
  display: inline-block; }

.social-icons li {
  float: left;
  list-style: none; }

.social-icons li a {
  float: left;
  height: 32px;
  width: 32px;
  line-height: 32px;
  font-size: 16px;
  text-align: center;
  margin: 0 4px 4px 0;
  border-radius: 4px;
  border: 0;
  background: transparent;
  color: #333;
  overflow: hidden;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease; }

.dark .social-icons li a {
  color: #eeeeee; }

.social-icons.social-icons-colored a,
.social-icons.social-icons-colored-hover a:hover {
  color: #fff;
  border: 0; }

.social-icons.social-icons-colored .social-rss a,
.social-icons.social-icons-colored-hover .social-rss a:hover {
  background-color: #faa33d; }

.social-icons.social-icons-colored .social-facebook a,
.social-icons.social-icons-colored-hover .social-facebook a:hover {
  background-color: #5d82d1; }

.social-icons.social-icons-colored .social-twitter a,
.social-icons.social-icons-colored-hover .social-twitter a:hover {
  background-color: #40bff5; }

.social-icons.social-icons-colored .social-vimeo a,
.social-icons.social-icons-colored-hover .social-vimeo a:hover {
  background-color: #35c6ea; }

.social-icons.social-icons-colored .social-myspace a,
.social-icons.social-icons-colored-hover .social-myspace a:hover {
  background-color: #008dde; }

.social-icons.social-icons-colored .social-youtube a,
.social-icons.social-icons-colored-hover .social-youtube a:hover {
  background-color: #ef4e41; }

.social-icons.social-icons-colored .social-instagram a,
.social-icons.social-icons-colored-hover .social-instagram a:hover {
  background-color: #548bb6; }

.social-icons.social-icons-colored .social-gplus a,
.social-icons.social-icons-colored-hover .social-gplus a:hover {
  background-color: #d68400; }

.social-icons.social-icons-colored .social-stumbleupon a,
.social-icons.social-icons-colored-hover .social-stumbleupon a:hover {
  background-color: #ff5c30; }

.social-icons.social-icons-colored .social-lastfm a,
.social-icons.social-icons-colored-hover .social-lastfm a:hover {
  background-color: #f34320; }

.social-icons.social-icons-colored .social-pinterest a,
.social-icons.social-icons-colored-hover .social-pinterest a:hover {
  background-color: #e13138; }

.social-icons.social-icons-colored .social-google a,
.social-icons.social-icons-colored-hover .social-google a:hover {
  background-color: #eb5e4c; }

.social-icons.social-icons-colored .social-evernote a,
.social-icons.social-icons-colored-hover .social-evernote a:hover {
  background-color: #9acf4f; }

.social-icons.social-icons-colored .social-dribbble a,
.social-icons.social-icons-colored-hover .social-dribbble a:hover {
  background-color: #f7659c; }

.social-icons.social-icons-colored .social-skype a,
.social-icons.social-icons-colored-hover .social-skype a:hover {
  background-color: #13c1f3; }

.social-icons.social-icons-colored .social-forrst a,
.social-icons.social-icons-colored-hover .social-forrst a:hover {
  background-color: #45ad76; }

.social-icons.social-icons-colored .social-linkedin a,
.social-icons.social-icons-colored-hover .social-linkedin a:hover {
  background-color: #238cc8; }

.social-icons.social-icons-colored .social-wordpress a,
.social-icons.social-icons-colored-hover .social-wordpress a:hover {
  background-color: #2592c3; }

.social-icons.social-icons-colored .social-grooveshark a,
.social-icons.social-icons-colored-hover .social-grooveshark a:hover {
  background-color: #ffb21d; }

.social-icons.social-icons-colored .social-delicious a,
.social-icons.social-icons-colored-hover .social-delicious a:hover {
  background-color: #377bda; }

.social-icons.social-icons-colored .social-behance a,
.social-icons.social-icons-colored-hover .social-behance a:hover {
  background-color: #1879fd; }

.social-icons.social-icons-colored .social-dropbox a,
.social-icons.social-icons-colored-hover .social-dropbox a:hover {
  background-color: #17a3eb; }

.social-icons.social-icons-colored .social-soundcloud a,
.social-icons.social-icons-colored-hover .social-soundcloud a:hover {
  background-color: #ff7e30; }

.social-icons.social-icons-colored .social-deviantart a,
.social-icons.social-icons-colored-hover .social-deviantart a:hover {
  background-color: #6a8a7b; }

.social-icons.social-icons-colored .social-yahoo a,
.social-icons.social-icons-colored-hover .social-yahoo a:hover {
  background-color: #ab47ac; }

.social-icons.social-icons-colored .social-flickr a,
.social-icons.social-icons-colored-hover .social-flickr a:hover {
  background-color: #ff48a3; }

.social-icons.social-icons-colored .social-digg a,
.social-icons.social-icons-colored-hover .social-digg a:hover {
  background-color: #75788d; }

.social-icons.social-icons-colored .social-blogger a,
.social-icons.social-icons-colored-hover .social-blogger a:hover {
  background-color: #ff9233; }

.social-icons.social-icons-colored .social-tumblr a,
.social-icons.social-icons-colored-hover .social-tumblr a:hover {
  background-color: #426d9b; }

.social-icons.social-icons-colored .social-quora a,
.social-icons.social-icons-colored-hover .social-quora a:hover {
  background-color: #ea3d23; }

.social-icons.social-icons-colored .social-github a,
.social-icons.social-icons-colored-hover .social-github a:hover {
  background-color: #3f91cb; }

.social-icons.social-icons-colored .social-amazon a,
.social-icons.social-icons-colored-hover .social-amazon a:hover {
  background-color: #ff8e2e; }

.social-icons.social-icons-colored .social-xing a,
.social-icons.social-icons-colored-hover .social-xing a:hover {
  background-color: #1a8e8c; }

.social-icons.social-icons-colored .social-wikipedia a,
.social-icons.social-icons-colored-hover .social-wikipedia a:hover {
  background-color: #b3b5b8; }

.social-icons.social-icons-border li a {
  border: 1px solid #d7d7d7;
  background: transparent;
  color: #333; }

.social-icons.social-icons-dark li a {
  background: #888;
  color: #fff; }

.social-icons.social-icons-light li a {
  background: #fff;
  color: #333;
  border: 1px solid #eee; }

.social-icons.social-icons-rounded li a {
  border-radius: 50%; }

.social-icons.social-icons-medium li a {
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-size: 18px; }

.social-icons.social-icons-large li a {
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-size: 18px; }

.social-icons:not(.social-icons-colored):not(.social-icons-colored-hover) li a:hover {
  background-color: #eee; }

.dark .social-icons:not(.social-icons-colored):not(.social-icons-colored-hover) li a:hover {
  background-color: #252525; }

.social-icons li:hover i {
  -webkit-animation: toTopFromBottom 0.3s forwards;
  -moz-animation: toTopFromBottom 0.3s forwards;
  animation: toTopFromBottom 0.3s forwards; }

@-webkit-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translateY(-100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(100%); }
  51% {
    opacity: 1; } }
@-moz-keyframes toTopFromBottom {
  49% {
    -moz-transform: translateY(-100%); }
  50% {
    opacity: 0;
    -moz-transform: translateY(100%); }
  51% {
    opacity: 1; } }
@keyframes toTopFromBottom {
  49% {
    transform: translateY(-100%); }
  50% {
    opacity: 0;
    transform: translateY(100%); }
  51% {
    opacity: 1; } }

/*# sourceMappingURL=social-icons.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.icon {
  margin-bottom: 16px; }

.icon-box {
  margin-bottom: 50px; }
  .icon-box .icon {
    height: 100px;
    position: absolute;
    width: 100px; }
    .icon-box .icon i {
      font-size: 28px;
      text-align: center;
      line-height: 66px;
      border-radius: 50%; }
  .icon-box .icon a,
  .icon-box .icon i {
    width: 100%;
    height: 100%;
    color: #333; }
  .icon-box h3,
  .icon-box h5 {
    margin: 0 12px 10px 83px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700; }
  .icon-box p {
    margin: 0 0 20px 83px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400; }

.icon-box-description > p {
  margin-bottom: 20px; }

.icon-box.effect .icon i {
  z-index: 1; }
  .icon-box.effect .icon i:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-sizing: content-box; }
.icon-box .icon i {
  -webkit-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s; }
  .icon-box .icon i:after {
    top: -4px;
    left: -4px;
    padding: 4px;
    box-shadow: 0 0 0 3px #333;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    transition: transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; }
.icon-box.effect.dark .icon i:after {
  box-shadow: 0 0 0 3px #1ABC9C; }
.icon-box.effect.dark .icon i:hover, .icon-box.effect.dark:hover .icon i {
  background-color: #1ABC9C; }
.icon-box.effect .icon i:hover:after, .icon-box.effect:hover .icon i:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.image-box .image-box-content p i.fa.fa-play {
  padding-left: 3px; }

.icon-box.small .icon {
  height: 46px;
  width: 46px; }
  .icon-box.small .icon i {
    font-size: 20px;
    line-height: 47px; }
.icon-box.small > h3,
.icon-box.small > h5 {
  font-size: 18px;
  margin: 0 12px 4px 60px; }
.icon-box.small > p {
  margin: 0 0 20px 60px;
  line-height: 1.6;
  font-size: 14px; }
.icon-box.medium > h3,
.icon-box.medium > h4,
.icon-box.medium > h5 {
  margin: 0 12px 10px 84px; }
.icon-box.medium > p {
  margin: 0 0 20px 84px; }
.icon-box.large .icon {
  height: 86px;
  width: 86px; }
  .icon-box.large .icon i {
    font-size: 38px;
    line-height: 87px; }
.icon-box.large > h3,
.icon-box.large > h5 {
  margin: 0 12px 10px 110px;
  font-size: 24px; }
.icon-box.large p {
  margin: 0 0 20px 110px; }
.icon-box.icon-box-right {
  padding: 0;
  text-align: right; }
  .icon-box.icon-box-right > h3,
  .icon-box.icon-box-right > h5 {
    margin: 0px 84px 0px 10px; }
  .icon-box.icon-box-right.medium > p {
    margin: 20px 84px 0px 10px; }
  .icon-box.icon-box-right .icon {
    right: 0; }
  .icon-box.icon-box-right p {
    font-size: 14px;
    margin-top: 15px; }
.icon-box.text-center, .icon-box.center {
  padding: 0;
  text-align: center; }
  .icon-box.text-center h3,
  .icon-box.text-center h5, .icon-box.center h3,
  .icon-box.center h5 {
    margin: 0 12px 10px 0px; }
  .icon-box.text-center p, .icon-box.center p {
    margin: 0 0 20px 0px; }
  .icon-box.text-center .icon, .icon-box.center .icon {
    margin: 0 auto 22px;
    position: relative; }
  .icon-box.text-center p, .icon-box.center p {
    font-size: 16px;
    margin-top: 15px; }
  .icon-box.text-center.small .icon, .icon-box.center.small .icon {
    clear: both !important;
    position: relative !important; }
.icon-box.border .icon {
  border: 3px solid #333;
  text-align: center;
  border-radius: 50%;
  background-color: transparent; }
  .icon-box.border .icon i {
    line-height: 56px; }
.icon-box.border:hover > .icon i {
  line-height: 62px; }
.icon-box.border:hover > .icon {
  border: 0px; }
.icon-box.border .icon i {
  background-color: transparent !important;
  color: #333; }
.icon-box.border.small .icon {
  height: 46px;
  position: absolute;
  top: 5px;
  width: 46px; }
  .icon-box.border.small .icon i {
    font-size: 20px;
    line-height: 42px; }
.icon-box.border.small:hover > .icon i {
  line-height: 49px; }
.icon-box.border.large .icon i {
  line-height: 81px; }
.icon-box.border.large:hover > .icon i {
  line-height: 86px; }
.icon-box.square.large:hover > .icon i {
  line-height: 86px; }
.icon-box.square .icon, .icon-box.square.effect .icon i:after,
.icon-box.square .icon i {
  border-radius: 0; }
.icon-box.square:hover > .icon i {
  line-height: 66px; }
.icon-box.square.border .icon i {
  line-height: 59px; }
.icon-box.square.border:hover > .icon i {
  line-height: 64px; }
.icon-box.square.border.small .icon i {
  line-height: 41px; }
.icon-box.square.border.small:hover > .icon i {
  line-height: 47px; }
.icon-box.square.border.large .icon i {
  line-height: 78px; }
.icon-box.square.border:large:hover > .icon i {
  line-height: 84px; }
.icon-box.square.small:hover > .icon i {
  line-height: 64px; }
.icon-box.light .icon i:after {
  box-shadow: 0 0 0 3px #e5e5e5; }
.icon-box.light .icon i {
  background-color: #e5e5e5; }
.icon-box.light .icon i {
  color: #808080; }
.icon-box.light.border .icon {
  border: 3px solid #e5e5e5; }
.icon-box.light.border.large .icon i {
  line-height: 87px; }
.icon-box.light.border.large:hover > .icon i {
  line-height: 88px; }
.icon-box.fancy .icon i {
  background-color: transparent;
  color: #222;
  font-size: 110px;
  opacity: 0.09;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 0px; }
.icon-box.fancy.small > .icon i {
  font-size: 68px; }
.icon-box.fancy.large .icon i {
  font-size: 120px; }
.icon-box.fancy.text-center .icon, .icon-box.fancy.center .icon {
  width: auto;
  margin-bottom: 30px; }
.icon-box.effect.fancy .icon i:after {
  box-shadow: 0 0 0 0px transparent; }
.icon-box.effect.fancy:hover > .icon i {
  font-size: 110px; }
.icon-box.effect.fancy.small:hover > .icon i {
  font-size: 76px; }
.icon-box.clean .icon i {
  background-color: none;
  color: #333;
  font-size: 50px; }
.icon-box.clean.small .icon i {
  font-size: 36px; }
.icon-box.effect.clean .icon i:after {
  box-shadow: 0 0 0 0px transparent; }
.icon-box.process {
  border: 1px solid #888;
  margin-top: 45px;
  padding: 0 30px 10px;
  border-radius: 4px; }
  .icon-box.process.effect .icon i:hover:after, .icon-box.process.effect:hover .icon i::after {
    opacity: 0;
    border: 0px; }
  .icon-box.process .icon {
    margin-top: 10px; }
  .icon-box.process.small .icon {
    margin-top: 10px; }
.icon-box.box-type {
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 20px; }
  .icon-box.box-type .icon {
    margin-top: 20px; }

/*Text Box*/
.text-box {
  padding: 40px;
  text-align: center;
  transition: background 0.4s ease 0s;
  cursor: pointer; }
  .text-box i {
    font-size: 42px;
    margin: 30px 0 20px; }
  .text-box i,
  .text-box h3,
  .text-box h5,
  .text-box p {
    font-weight: 100;
    color: #fff;
    transition: all 0.4s ease 0s; }
  .text-box:hover {
    background: #eeeeee; }
    .text-box:hover i,
    .text-box:hover h3,
    .text-box:hover h5,
    .text-box:hover p {
      color: #333; }
  .text-box.icon-boxx-light {
    border: 1px solid #d9d9d9;
    background: #ffffff; }
    .text-box.icon-boxx-light i,
    .text-box.icon-boxx-light h3,
    .text-box.icon-boxx-light h5,
    .text-box.icon-boxx-light p {
      color: #252525; }
    .text-box.icon-boxx-light:hover {
      background: #1abc9c; }
      .text-box.icon-boxx-light:hover i,
      .text-box.icon-boxx-light:hover h3,
      .text-box.icon-boxx-light:hover h5,
      .text-box.icon-boxx-light:hover p {
        color: #ffffff; }
  .text-box.hover-effect {
    padding: 30px 30px 10px 30px; }
    .text-box.hover-effect i {
      margin: 90px 0 20px; }
    .text-box.hover-effect p {
      opacity: 0; }
    .text-box.hover-effect:hover i {
      margin: 70px 0 6px 0; }
    .text-box.hover-effect:hover p {
      opacity: 1; }

/* ----------------------------------------------------------------
    Featured Boxes
-----------------------------------------------------------------*/
.feature-box {
  background-color: #fff;
  margin-bottom: 0px;
  padding: 44px;
  border-radius: 4px;
  background-clip: padding-box;
  box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
  transition: all 0.55s ease;
  text-align: center;
  min-height: 250px; }
  .feature-box.feature-box-transparent {
    background-color: transparent;
    box-shadow: none; }
    .feature-box.feature-box-transparent:hover {
      background-color: #ffffff; }
  .feature-box > i {
    font-size: 46px;
    margin-bottom: 20px; }
  .feature-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9); }
    .feature-box:hover h2,
    .feature-box:hover h3,
    .feature-box:hover h4 {
      color: #1abc9c; }
  .feature-box h2,
  .feature-box h3,
  .feature-box h4 {
    font-size: 14px;
    text-transform: uppercase;
    transition: all 0.33s;
    margin-top: 0px;
    margin-bottom: 10px; }
  .feature-box p {
    font-size: 14px;
    margin-bottom: 0; }

.col-md-2 .feature-box p {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 18px;
  opacity: 0.8; }

/*# sourceMappingURL=icon-boxes.css.map */

/* ----------------------------------------------------------------
Carousels
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* Owl Carousel - Controls
-----------------------------------------------------------------*/
.owl-carousel .owl-dots,
.owl-carousel .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  line-height: 1; }
.owl-carousel .owl-nav [class*="owl-"] {
  width: 42px;
  height: 42px;
  line-height: 42px;
  margin: -23px 0 0;
  display: block;
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  text-align: center;
  transition: all .2s ease 0s;
  color: #262626;
  background-color: #ffffff;
  text-align: center;
  z-index: 200;
  border: 2px solid #ffffff;
  box-sizing: initial;
  opacity: 0;
  border-radius: 50%; }
  .owl-carousel .owl-nav [class*="owl-"] i {
    line-height: 42px;
    color: #262626;
    font-size: 22px; }
  .owl-carousel .owl-nav [class*="owl-"]:hover {
    background-color: #1abc9c;
    border-color: #1abc9c; }
    .owl-carousel .owl-nav [class*="owl-"]:hover i {
      color: #ffffff; }
.owl-carousel .owl-nav .owl-next {
  right: 0px; }
  .owl-carousel .owl-nav .owl-next .fa-angle-right {
    margin-right: -2px; }
.owl-carousel .owl-nav .owl-prev {
  left: 0px; }
  .owl-carousel .owl-nav .owl-prev .fa-angle-left {
    margin-left: -2px; }
.owl-carousel:hover .owl-next {
  left: auto;
  right: 10px;
  opacity: 1; }
.owl-carousel:hover .owl-prev {
  right: auto;
  left: 10px;
  opacity: 1; }
.owl-carousel.arrows-visibile .owl-next {
  left: auto;
  right: 10px;
  opacity: 1; }
.owl-carousel.arrows-visibile .owl-prev {
  right: auto;
  left: 10px;
  opacity: 1; }
.owl-carousel.arrows-dark .owl-nav [class*="owl-"] {
  color: #ffffff;
  border-color: #252525;
  background-color: #252525; }
  .owl-carousel.arrows-dark .owl-nav [class*="owl-"] i {
    color: #ffffff; }
  .owl-carousel.arrows-dark .owl-nav [class*="owl-"]:hover {
    background-color: #252525; }
    .owl-carousel.arrows-dark .owl-nav [class*="owl-"]:hover i {
      color: #ffffff; }
.owl-carousel.arrows-grey .owl-nav [class*="owl-"] {
  color: #252525;
  border: 2px solid #9d9d9d;
  background-color: #9d9d9d; }
  .owl-carousel.arrows-grey .owl-nav [class*="owl-"] i {
    color: #9d9d9d; }
  .owl-carousel.arrows-grey .owl-nav [class*="owl-"]:hover {
    background-color: #9d9d9d; }
    .owl-carousel.arrows-grey .owl-nav [class*="owl-"]:hover i {
      color: #252525; }
.owl-carousel.arrows-large .owl-nav [class*="owl-"] {
  width: 78px;
  height: 78px;
  line-height: 78px; }
  .owl-carousel.arrows-large .owl-nav [class*="owl-"] i {
    line-height: 78px;
    font-size: 28px; }
.owl-carousel.arrows-creative .owl-nav [class*="owl-"] {
  background-color: transparent;
  border: 0; }
  .owl-carousel.arrows-creative .owl-nav [class*="owl-"] i {
    color: #ffffff; }
  .owl-carousel.arrows-creative .owl-nav [class*="owl-"]:hover {
    color: #262626;
    background-color: #1abc9c; }
.owl-carousel.arrows-only .owl-nav [class*="owl-"] {
  border: 0; }
  .owl-carousel.arrows-only .owl-nav [class*="owl-"] i {
    font-size: 28px; }

@media (max-width: 991px) {
  .owl-carousel .owl-nav [class*="owl-"] {
    width: 32px;
    height: 32px;
    line-height: 32px; }
    .owl-carousel .owl-nav [class*="owl-"] i {
      line-height: 32px;
      font-size: 18px; } }
/* Carousel dots */
.owl-carousel {
  /* dots inside */
  /* dots top */
  /* dots dark */
  /* dots dark */ }
  .owl-carousel .owl-controls .owl-dots {
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative; }
    .owl-carousel .owl-controls .owl-dots .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline; }
      .owl-carousel .owl-controls .owl-dots .owl-dot span {
        position: relative;
        display: block;
        float: left;
        margin: 0 4px;
        width: 10px;
        height: 10px;
        cursor: pointer;
        border-radius: 50%;
        overflow: hidden;
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #cccccc;
        transition: background 0.3s ease; }
        .owl-carousel .owl-controls .owl-dots .owl-dot span:after {
          outline: medium none;
          text-indent: -999em;
          border-radius: 50%;
          overflow: hidden;
          content: '';
          position: absolute;
          bottom: 0;
          height: 0;
          left: 0;
          width: 100%;
          background-color: #cccccc;
          box-shadow: 0 0 1px #cccccc;
          transition: height 0.3s ease; }
      .owl-carousel .owl-controls .owl-dots .owl-dot.active span:after {
        height: 100%; }
  .owl-carousel.dots-inside .owl-controls {
    height: 0; }
  .owl-carousel.dots-inside .owl-dots {
    bottom: 30px;
    margin-bottom: 0;
    margin-top: 0; }
  .owl-carousel.dots-inside-top .owl-dots {
    position: absolute;
    right: 20px;
    top: 20px; }
  .owl-carousel.dots-outside-top .owl-dots {
    position: absolute;
    right: 0;
    top: -46px; }
  .owl-carousel.dots-dark .owl-dots .owl-dot span {
    box-shadow: inset 0 0 0 2px #252525; }
    .owl-carousel.dots-dark .owl-dots .owl-dot span:after {
      background-color: #252525; }
  .owl-carousel.dots-dark .owl-dots .owl-dot.active span:after {
    height: 100%; }
  .owl-carousel.dots-grey .owl-dots .owl-dot span {
    box-shadow: inset 0 0 0 2px #9d9d9d; }
    .owl-carousel.dots-grey .owl-dots .owl-dot span:after {
      background-color: #9d9d9d; }
  .owl-carousel.dots-grey .owl-dots .owl-dot.active span:after {
    height: 100%; }
  .owl-carousel.dots-creative .owl-controls {
    height: 0; }
  .owl-carousel.dots-creative .owl-dots {
    bottom: 30px;
    margin-bottom: 0;
    margin-top: 0; }
    .owl-carousel.dots-creative .owl-dots .owl-dot span {
      opacity: 0.5;
      box-shadow: none;
      border-radius: 0;
      background-color: #ffffff;
      height: 4px;
      width: 30px; }
      .owl-carousel.dots-creative .owl-dots .owl-dot span:after {
        border-radius: 0;
        background-color: #ffffff; }
    .owl-carousel.dots-creative .owl-dots .owl-dot.active span {
      opacity: 1; }

.carousel-promotion .owl-stage-outer {
  overflow: unset !important; }
  .carousel-promotion .owl-stage-outer .owl-item {
    opacity: .3;
    transition: opacity 400ms ease; }
    .carousel-promotion .owl-stage-outer .owl-item.active {
      opacity: 1; }

/* Owl Carousel - Settings
-----------------------------------------------------------------*/
.carousel {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s ease; }
  .carousel.carousel-loaded {
    opacity: 1;
    visibility: visible; }

.item-video {
  height: 420px; }

/*# sourceMappingURL=carousel.css.map */

/* ----------------------------------------------------------------------
	Counters
-------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.counter {
  margin-bottom: 14px; }
  .counter span {
    font-size: 50px;
    line-height: 40px; }
  .counter.small > span {
    font-size: 32px !important; }
  .counter.medium > span {
    font-size: 50px !important; }
  .counter.large > span {
    font-size: 62px !important; }

.icon-box.fancy > .counter span {
  margin: 0 12px 10px 56px; }
.icon-box.fancy.medium > .counter span {
  margin: 0 12px 10px 80px; }
.icon-box.effect:not(.center) > .counter span {
  margin: 0 12px 10px 82px; }
.icon-box.effect.large:not(.center) > .counter span {
  margin: 0 12px 10px 107px; }
.icon-box.effect.medium:not(.center) > .counter span {
  margin: 0 12px 10px 82px; }
.icon-box.effect.small:not(.center) > .counter span {
  margin: 0 12px 10px 58px;
  font-size: 32px !important; }

/*# sourceMappingURL=counters.css.map */

.countdown-container {
  display: table;
  margin: 0 auto; }

.countdown {
  color: #333;
  overflow: hidden; }
  .countdown .countdown-box {
    display: block;
    float: left;
    margin: 30px;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: auto; }
  .countdown .number {
    font-size: 80px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 10px;
    text-align: center; }
  .countdown span {
    display: block;
    font-family: "Open Sans", sans-serif;
    font-size: 21px;
    font-weight: 400;
    overflow: hidden;
    padding-top: 10px;
    position: relative;
    text-align: center; }
    .countdown span:before {
      background: none repeat scroll 0 0 #333;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 30px; }
  .countdown.circle .countdown-box {
    border: 3px solid #333;
    border-radius: 50%;
    color: #333;
    height: 160px;
    padding: 34px 5px 0 0;
    width: 160px;
    margin: 20px 15px; }
    .countdown.circle .countdown-box .number {
      font-size: 52px;
      font-weight: 800px; }
    .countdown.circle .countdown-box span {
      font-size: 18px; }
  .countdown.rectangle .countdown-box {
    border: 4px solid;
    padding: 24px;
    width: 170px;
    margin-left: 15px;
    margin-right: 15px; }
    .countdown.rectangle .countdown-box .number {
      font-size: 52px;
      font-weight: 600; }
    .countdown.rectangle .countdown-box span {
      font-size: 18px; }
  .countdown.medium .countdown-box, .countdown.circle.medium .countdown-box, .countdown.rectangle.medium .countdown-box {
    height: 130px;
    margin: 10px;
    padding: 20px 4px 0 0;
    width: 130px; }
  .countdown.medium .number, .countdown.circle.medium .number, .countdown.rectangle.medium .number {
    font-size: 44px;
    margin-bottom: 12px; }
  .countdown.medium span, .countdown.circle.medium span, .countdown.rectangle.medium span {
    font-size: 18px; }
  .countdown.circle.medium .countdown-box, .countdown.rectangle.medium .countdown-box {
    border: solid 2px; }
  .countdown.small .countdown-box, .countdown.circle.small .countdown-box, .countdown.rectangle.small .countdown-box {
    margin: 10px;
    height: 70px;
    padding: 10px 0px 0 0;
    width: 70px;
    margin: 5px 5px; }
  .countdown.small .number, .countdown.circle.small .number, .countdown.rectangle.small .number {
    font-size: 16px;
    margin-bottom: 6px; }
  .countdown.small span, .countdown.circle.small span, .countdown.rectangle.small span {
    font-size: 14px;
    padding-top: 2px; }
  .countdown.circle.small .countdown-box, .countdown.rectangle.small .countdown-box {
    border: solid 2px; }
  .countdown.light .countdown-box {
    border-color: #fff;
    color: #fff;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3); }
  .countdown.countdown-light span:before {
    background: #fff; }
  .countdown.countdown-light span,
  .countdown.countdown-light .number {
    color: #fff !important; }
  .countdown.countdown-light.circle .countdown-box {
    border-color: #fff; }
  .countdown.countdown-light.rectangle .countdown-box {
    border-color: #fff; }
  .countdown.countdown-dark span,
  .countdown.countdown-dark .number {
    color: #000 !important; }

/*# sourceMappingURL=countdown-timers.css.map */

/* ----------------------------------------------------------------
    Dropcat & Highlight
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.dropcap {
  background: none repeat scroll 0 0 #eeeeee;
  border-radius: 4px;
  display: inline-block;
  float: left;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  margin: 8px 20px 0 0;
  text-align: center;
  width: 40px; }
  .dropcap.dropcap-circle {
    border-radius: 50%; }
  .dropcap.dropcap-large {
    font-size: 44px;
    height: 80px;
    line-height: 80px;
    width: 80px; }
  .dropcap.dropcap-small {
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    margin: 3px 5px -3px 0;
    width: 21px; }
  .dropcap.dropcap-colored {
    background-color: #1abc9c;
    color: #ffffff; }

span.highlight {
  padding: 0 5px; }

.highlight a {
  color: #333; }
.highlight.highlight-large {
  font-size: 20px; }
.highlight.highlight-small {
  font-size: 12px;
  padding: 0 7px; }
.highlight.highlight-colored {
  background-color: #1abc9c;
  color: #ffffff; }

/*# sourceMappingURL=dropcat-highlight.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* ----------------------------------------------------------------
    Team Members
-----------------------------------------------------------------*/
.team-members .team-member {
  background-color: #ffffff;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 4px; }
  .team-members .team-member .team-image {
    margin-bottom: 20px; }
    .team-members .team-member .team-image > img {
      width: 100%;
      border-radius: 4px; }
  .team-members .team-member .team-desc > h3 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0; }
  .team-members .team-member .team-desc > span {
    font-size: 13px;
    color: #9d9d9d;
    line-height: 18px; }
  .team-members .team-member .team-desc > p {
    margin-top: 10px;
    font-size: 14px; }
.team-members.team-members-circle .team-member {
  background-color: transparent;
  padding: 20px; }
  .team-members.team-members-circle .team-member .team-image > img {
    border-radius: 50%; }
.team-members.team-members-card .team-member {
  border: 1px solid #eeeeee;
  border-radius: 4px; }
  .team-members.team-members-card .team-member .team-image > img {
    border-radius: 0; }
  .team-members.team-members-card .team-member .team-desc {
    background-color: #fdfdfd;
    border-radius: 0px 0px 4px 4px;
    padding: 10px; }
.team-members.team-members-shadow .team-member {
  border: 1px solid #eeeeee;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.03); }
  .team-members.team-members-shadow .team-member .team-image > img {
    border-radius: 0; }
  .team-members.team-members-shadow .team-member .team-desc {
    padding: 20px; }
.team-members.team-members-transparent .team-member {
  background-color: transparent; }
  .team-members.team-members-transparent .team-member .team-desc > h3 {
    color: #ffffff; }
  .team-members.team-members-transparent .team-member .team-desc > span {
    color: #ffffff; }
  .team-members.team-members-transparent .team-member .team-desc > p {
    color: #ffffff; }
.team-members.team-members-left .team-member {
  text-align: left; }
  .team-members.team-members-left .team-member .team-image {
    float: left;
    width: 40%;
    margin-bottom: 0; }
  .team-members.team-members-left .team-member .team-desc {
    overflow: hidden;
    position: relative;
    width: 60%;
    padding-left: 30px; }
    .team-members.team-members-left .team-member .team-desc > h3 {
      margin-top: 0; }

/*# sourceMappingURL=team-members.css.map */

/* ----------------------------------------------------------------------
	Progress Bar
-------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.progress-bar-container {
  width: 100%;
  height: 40px;
  margin: 20px 0 20px 0;
  overflow: hidden;
  background-color: #eee; }

.progress-bar {
  height: 100%;
  padding: 10px;
  background-color: #1abc9c;
  box-shadow: none; }
  .progress-bar .progress-number,
  .progress-bar .progress-type {
    color: #fff;
    float: right;
    margin-top: -2px; }

.progress-title {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  text-align: left; }
  .progress-title i {
    margin-right: 8px; }

.progress-bar-container.radius,
.progress-bar-container.radius .progress-bar {
  border-radius: 4px; }
.progress-bar-container.title-up .progress-title {
  color: #333;
  margin-left: -10px;
  margin-top: -32px; }
.progress-bar-container.title-up:not(:first-child) {
  margin-top: 40px; }
.progress-bar-container.title-up .progress-bar .progress-number,
.progress-bar-container.title-up .progress-bar .progress-type {
  color: #333;
  margin-top: -32px;
  position: absolute;
  right: 15px; }
.progress-bar-container.title-up .progress-bar .progress-number {
  right: 26px; }
.progress-bar-container.medium {
  height: 20px; }
  .progress-bar-container.medium .progress-bar {
    padding: 0; }
  .progress-bar-container.medium .progress-title {
    margin-left: 0;
    margin-top: -20px; }
  .progress-bar-container.medium .progress-bar .progress-number,
  .progress-bar-container.medium .progress-bar .progress-type {
    margin-top: -18px; }
.progress-bar-container.small {
  height: 10px; }
  .progress-bar-container.small .progress-bar {
    padding: 0; }
  .progress-bar-container.small .progress-title {
    margin-left: 0;
    margin-top: -20px; }
  .progress-bar-container.small .progress-bar .progress-number,
  .progress-bar-container.small .progress-bar .progress-type {
    margin-top: -18px; }
.progress-bar-container.no-bg {
  background: none !important; }
.progress-bar-container.extra-small {
  height: 2px; }
  .progress-bar-container.extra-small .progress-bar {
    padding: 0; }
  .progress-bar-container.extra-small .progress-title {
    margin-left: 0;
    margin-top: -20px; }
  .progress-bar-container.extra-small .progress-bar .progress-number,
  .progress-bar-container.extra-small .progress-bar .progress-type {
    margin-top: -18px; }
.progress-bar-container.transparent {
  background: none !important; }
  .progress-bar-container.transparent .progress-bar {
    background-color: rgba(0, 0, 0, 0.35); }
  .progress-bar-container.transparent.title-up .progress-bar .progress-title,
  .progress-bar-container.transparent.title-up .progress-bar .progress-number,
  .progress-bar-container.transparent.title-up .progress-bar .progress-type {
    color: #fff; }

/*# sourceMappingURL=progress-bars.css.map */

.pie-animated {
  opacity: 1; }

.pie-chart {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 50px;
  position: relative;
  text-align: center; }
  .pie-chart canvas {
    position: absolute;
    top: 0;
    left: 0; }
  .pie-chart span,
  .pie-chart i {
    display: inline-block;
    font-size: 28px;
    z-index: 2;
    font-weight: 600; }
  .pie-chart i {
    font-size: 38px; }
  .pie-chart span.percent:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .8em; }
  .pie-chart.transparent span,
  .pie-chart.transparent span.percent,
  .pie-chart.transparent i {
    color: #fff; }

/*# sourceMappingURL=pie-charts.css.map */

hr {
  margin-bottom: 10px;
  margin-top: 10px;
  clear: both; }
  hr.space {
    border: none;
    height: 50px; }
  hr.space-xs {
    border: none;
    height: 20px; }
  hr.space-md {
    border: none;
    height: 40px; }
  hr.space-lg {
    border: none;
    height: 80px; }
  hr.space-xlg {
    border: none;
    height: 120px; }
  hr.space-xxlg {
    border: none;
    height: 200px; }

body.device-sm hr.space {
  height: 26px; }
body.device-xs hr.space, body.device-xxs hr.space {
  height: 16px; }
body.device-xxs hr.space-lg, body.device-xs hr.space-lg {
  height: 40px; }
body.device-xxs hr.space-xlg, body.device-xs hr.space-xlg {
  height: 40px; }
body.device-xxs hr.space-xxlg, body.device-xs hr.space-xxlg {
  height: 80px; }

.space {
  width: 100%;
  display: block;
  clear: both;
  height: 20px;
  position: relative; }

/*# sourceMappingURL=horizontal-rules.css.map */

/* ----------------------------------------------------------------------
	Seperator
-------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.seperator,
.separator {
  color: #ccc;
  width: 100%;
  margin: 30px auto;
  overflow: hidden;
  text-align: center;
  line-height: 1.2em; }

.seperator-simple {
  margin: 14px auto; }

.seperator::before,
.seperator::after,
.separator::before,
.separator::after {
  border-bottom: 1px solid #eee;
  content: "";
  display: inline-block;
  height: 0.65em;
  margin: 0 -4px 0 -100%;
  vertical-align: top;
  width: 50%; }

.seperator.seperator-dark::before,
.seperator.seperator-dark::after,
.separator.seperator-dark::before,
.separator.seperator-dark::after {
  border-bottom: 1px solid #333 !important; }

.seperator.seperator-grey::before,
.seperator.seperator-grey::after,
.separator.seperator-grey::before,
.separator.seperator-grey::after {
  border-bottom: 1px solid #ccc; }

.seperator.seperator-light::before,
.seperator.seperator-light::after,
.separator.seperator-light::before,
.separator.seperator-light::after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.seperator::after,
.separator::after {
  margin: 0 -100% 0 0; }

.seperator i,
.separator i {
  font-size: 18px; }

.seperator i,
.seperator span,
.seperator a,
.separator i,
.separator span,
.separator a {
  margin: 0 20px 0 24px;
  display: inline-block; }

.seperator.dotted:before,
.seperator.dotted:after,
.separator.dotted:before,
.separator.dotted:after {
  border-bottom: 2px dotted #ccc; }

.seperator.seperator-left,
.separator.seperator-left {
  float: left;
  margin: 2px 36px 0 0; }

.seperator.left i,
.separator.left i {
  float: left;
  margin: 2px 36px 0 0; }

.seperator.right i,
.separator.right i {
  float: right;
  margin: 0 0 2px 36px; }

.seperator.seperator-bold:before,
.seperator.seperator-bold:after,
.separator.seperator-bold:before,
.separator.seperator-bold:after {
  border-bottom-width: 6px; }

.seperator.seperator-small,
.separator.seperator-small {
  width: 10%;
  margin: 5px auto; }

.seperator.seperator-medium,
.separator.seperator-medium {
  width: 20%;
  margin: 10px auto; }

.text-seperator {
  background-color: #111;
  display: block;
  height: 4px;
  margin: 20px 0; }

.text-seperator-small {
  width: 10%; }

.text-seperator-medium {
  width: 30%; }

.text-seperator-large {
  width: 60%; }

.seperator.seperator-image:before,
.seperator.seperator-image:after,
.separator.seperator-image:before,
.separator.seperator-image:after {
  border: 0; }

.seperator.seperator-image,
.separator.seperator-image {
  background-position: center center;
  background-repeat: repeat-x;
  margin: 0;
  padding: 0; }

.seperator.seperator-over-top,
.separator.seperator-over-top {
  margin-bottom: -17px;
  position: relative;
  top: -13px !important; }

.triangle-divider-bottom {
  background-image: url("../src/images/triangle-divider-down.png");
  background-position: left center;
  background-repeat: repeat-x;
  bottom: 0;
  height: 12px;
  position: absolute;
  width: 100%; }

.triangle-divider-top {
  background-image: url("../src/images/triangle-divider-top.png");
  background-position: left center;
  background-repeat: repeat-x;
  top: 0;
  height: 12px;
  position: absolute;
  width: 100%; }

/*# sourceMappingURL=seperator.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
blockquote {
  border-left: 3px solid #1abc9c;
  font-size: 20px; }

.blockquote-simple {
  border: 0 none !important; }

.blockquote-fancy,
.blockquote-color,
.blockquote-dark {
  border: 0 none !important; }

.blockquote-color {
  background-color: #1abc9c; }
  .blockquote-color small {
    color: #ffffff; }

.blockquote-fancy::before {
  color: rgba(0, 0, 0, 0.04);
  content: '"';
  display: block;
  font-size: 100px;
  left: 4px;
  position: absolute;
  top: 14px;
  font-family: Arial; }
.blockquote-fancy > small {
  float: right; }

.blockquote-dark {
  background-color: #333; }

.blockquote-color,
.blockquote-dark {
  padding: 28px;
  color: #ffffff; }

/*# sourceMappingURL=blockquotes.css.map */

/* ----------------------------------------------------------------------
Forms
-------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
form .btn {
  padding: 11px 20px; }

.form-control {
  border-radius: 0;
  box-shadow: none;
  height: auto;
  line-height: 1.33333;
  padding: 8px 18px;
  /*border-radius: 4px;*/
  border: 2px solid #ebebeb;
  margin-bottom: 6px; }
  .form-control:focus {
    box-shadow: none; }
  .form-control.input-lg {
    resize: none;
    font-size: 0.9em; }
  .form-control.rounded {
    border-radius: 4px; }

.input-group-addon {
  border: 2px solid #ebebeb; }

.form-group label {
  color: #444;
  cursor: pointer;
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-size: 13px; }

.form-inline button {
  margin: 0px; }

.form-group > label {
  display: block; }

.has-feedback label ~ .form-control-feedback {
  top: 44px; }

.form-gray-fields .form-control {
  background-color: rgba(238, 238, 238, 0.6);
  border-color: transparent;
  color: #333; }
  .form-gray-fields .form-control:focus {
    background-color: #eee; }

.form-transparent-fields .form-control {
  background-color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.4); }
  .form-transparent-fields .form-control:focus {
    background-color: rgba(0, 0, 0, 0.7); }

.form-transparent-light-fields .form-control {
  background-color: rgba(255, 255, 255, 0.06);
  border-color: rgba(255, 255, 255, 0.1); }
  .form-transparent-light-fields .form-control:focus {
    background-color: rgba(255, 255, 255, 0.7); }

label.error {
  color: #b71828;
  display: none !important;
  font-weight: 400; }

.form-control.error,
.sm-form-control.error {
  border-color: #b71828; }

.form-control.error {
  color: #b71828; }
.form-control::-moz-placeholder, .form-control::-ms-input-placeholder, .form-control::-webkit-input-placeholder {
  color: #bbbbbb; }

/*form select*/
select {
  border: 2px solid #ebebeb;
  color: #777;
  cursor: pointer;
  display: block;
  line-height: 1.33333;
  max-width: 100%;
  min-width: 100%;
  padding: 10px;
  border-radius: 4px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: url(../src/images/dropdown-arrow.png);
  background-repeat: no-repeat;
  background-position: right center;
  padding: 6px 18px;
  color: #999; }

select[multiple] {
  background-image: none; }

.order-select > h6 {
  margin-bottom: 0; }
.order-select p {
  font-size: 13px; }

.button-search {
  background-color: #fff;
  border-color: #e9e9e9 #e9e9e9 #e9e9e9;
  border-style: solid;
  border-width: 1px 1px 1px 0 !important;
  box-shadow: none;
  margin-left: -6px !important;
  z-index: 2 !important; }

/* ----------------------------------------------------------------------
 Reservation form
-------------------------------------------------------------------------*/
.reservation-form-over {
  top: -80px;
  z-index: 10;
  margin-bottom: -80px;
  overflow: inherit; }

.reservation-form {
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #eee;
  min-height: 160px;
  padding: 30px;
  position: relative;
  z-index: 9999 !important; }
  .reservation-form label {
    color: #555; }
  .reservation-form input,
  .reservation-form select {
    border: 0px;
    border-radius: 4px;
    background-color: #fff;
    border: 2px solid #ddd;
    width: 100%; }
  .reservation-form .date input {
    border-radius: 4px 0 0 4px;
    border-width: 2px 0 2px 2px; }
  .reservation-form .input-group-addon {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fff !important;
    border-color: #ddd;
    border-image: none;
    border-style: solid;
    border-width: 2px 2px 2px 0; }
  .reservation-form input,
  .reservation-form select {
    color: #555;
    display: block;
    font-size: 15px;
    line-height: 1.42857;
    padding: 8px 14px;
    width: 100%; }

/*reservation form style 2*/
#book {
  background-color: rgba(0, 0, 0, 0.4);
  border: 10px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  padding: 40px; }
  #book label {
    color: #fff; }
  #book .date input {
    border-radius: 4px 0 0 4px; }
  #book input,
  #book select {
    color: #555;
    display: block;
    font-size: 15px;
    line-height: 1.42857;
    padding: 8px 14px;
    width: 100%; }
  #book .input-group-addon {
    background-color: #fff !important;
    border: 0 none; }
  #book input,
  #book select {
    border: 0 none; }

/*checkboxes*/
.checkbox.checkbox-creative {
  margin-top: 0; }
  .checkbox.checkbox-creative label {
    cursor: pointer;
    padding-right: 10px;
    padding-left: 0;
    margin-top: 0 !important; }
  .checkbox.checkbox-creative input[type="checkbox"] {
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    width: 0;
    z-index: -1; }
  .checkbox.checkbox-creative .checkbox-item {
    position: relative;
    top: 3px;
    vertical-align: middle; }
    .checkbox.checkbox-creative .checkbox-item .check {
      border: 2px solid #e6e6e6;
      border-radius: 2px;
      display: inline-block;
      height: 17px;
      overflow: hidden;
      position: relative;
      width: 17px;
      z-index: 1; }
      .checkbox.checkbox-creative .checkbox-item .check:before {
        box-shadow: 0 0 0 0px, 0 0 0 0px, 0 0 0 0px, 0 0 0 0px, 0 0 0 0px, 0 0 0 0px, 0 0 0 0 inset;
        content: "";
        display: block;
        height: 0;
        margin-left: 4px;
        margin-top: -6px;
        position: absolute;
        transform: rotate(45deg);
        width: 0; }
  .checkbox.checkbox-creative input[type="checkbox"]:focus + .checkbox-item .check::after {
    opacity: 0.2; }
  .checkbox.checkbox-creative input[type="checkbox"]:focus:checked + .checkbox-item .check::before {
    animation: 0.3s ease 0s normal forwards 1 running checkbox-on; }
  .checkbox.checkbox-creative input[type="checkbox"]:checked + .checkbox-item .check {
    border-color: #009688;
    color: #009688; }
  .checkbox.checkbox-creative input[type="checkbox"]:checked + .checkbox-item .check::before {
    box-shadow: 0 0 0 11px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    color: #009688; }

@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 11px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px; }
  50% {
    box-shadow: 0 0 0 11px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px; }
  100% {
    box-shadow: 0 0 0 11px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px; } }
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 11px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset; }
  50% {
    box-shadow: 0 0 0 11px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    height: 0;
    margin-left: 4px;
    margin-top: -6px;
    transform: rotate(45deg);
    width: 0; }
  100% {
    box-shadow: 0 0 0 0px, 0 0 0 0px, 0 0 0 0px, 0 0 0 0px, 0 0 0 0px, 0 0 0 0px, 0 0 0 0 inset;
    height: 17px;
    margin-left: -2px;
    margin-top: -2px;
    transform: rotate(0deg);
    width: 17px; } }

/*# sourceMappingURL=forms.css.map */

.label-default {
  background: -webkit-linear-gradient(top, #f37054 0px, #e66346 100%) repeat scroll 0 0 #e66346;
  background: linear-gradient(to bottom, #f37054 0px, #e66346 100%) repeat scroll 0 0 #e66346;
  color: #ffffff; }

.main-menu .label,
.sidebar-menu .label {
  padding: 1px 3px;
  margin: 0 3px; }

.badge {
  margin-right: 10px; }

/*# sourceMappingURL=labels-badgets.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }
  .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader,
.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }
button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 36px;
  height: 36px;
  line-height: 36px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 22px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-arrow:before, .mfp-arrow:after,
  .mfp-arrow .mfp-b,
  .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after,
  .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before,
  .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after,
  .mfp-arrow-left .mfp-a {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before,
  .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after,
  .mfp-arrow-right .mfp-a {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before,
  .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
   /**
* Remove all paddings around the image on small screen
*/
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }
.mfp-ie7 .mfp-img {
  padding: 0; }
.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }
.mfp-ie7 .mfp-container {
  padding: 0; }
.mfp-ie7 .mfp-content {
  padding-top: 44px; }
.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

.lightbox-open {
  position: relative;
  overflow: hidden !important; }

.mfp-ajax-holder .mfp-content {
  background: #F8F8F8;
  max-width: 1000px; }
.mfp-ajax-holder .ajax-quick-view {
  padding: 20px 30px 35px; }
  .mfp-ajax-holder .ajax-quick-view .quick-view-title {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
    position: relative; }
    .mfp-ajax-holder .ajax-quick-view .quick-view-title h2 {
      font-weight: 600; }

.mfp-ajax-holder .mfp-close:hover {
  background: #1abc9c;
  color: #ffffff; }

/*settings*/
.portfolio-ajax-page iframe {
  width: 100%; }
.portfolio-ajax-page .col-md-8 {
  margin-bottom: -8px !important; }

/*# sourceMappingURL=light-box.css.map */

/* ----------------------------------------------------------------------
	Lists & Bullet Lists
-------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.list {
  clear: both;
  display: block;
  position: relative; }

.list-lines,
.icon-list,
.list-simple,
.list-posts,
.list ul {
  padding: 0px; }

.list,
.list-lines,
.icon-list,
.list-simple,
.list-posts,
.list-tweets li {
  list-style: none; }

.icon-list li,
.list-arrow-icons li,
.list-tweets li {
  padding-left: 0px; }

.list-medium li {
  margin: 12px 0; }

.list-large li {
  margin: 18px 0; }

.list-large li:first-child,
.list-medium li:first-child {
  margin-top: 0; }

.list-large li:last-child,
.list-medium li:last-child {
  margin-bottom: 0; }

.list-posts small {
  display: block;
  font-style: italic;
  opacity: 0.7; }

.list-lines {
  list-style: none; }
  .list-lines li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0 10px 2px; }
    .list-lines li:last-child {
      border-bottom: 0px; }

.list-icon {
  list-style: none;
  padding-left: 0; }
  .list-icon ul {
    list-style: none; }
  .list-icon ul {
    padding: 0; }
  .list-icon li {
    margin-bottom: 10px;
    margin-left: 20px; }
  .list-icon i {
    font-size: 15px;
    margin-right: 14px;
    margin-left: -20px; }
  .list-icon.icon-list-colored li:before, .list-icon.icon-list-colored i {
    color: #1abc9c; }
  .list-icon li:before {
    margin-left: -20px;
    position: absolute;
    font-family: fontawesome;
    font-size: 15px; }
  .list-icon.list-icon-arrow li:before {
    content: "\f105";
    margin-left: -16px; }
  .list-icon.list-icon-arrow-circle li:before {
    content: "\f18e"; }
  .list-icon.list-icon-check li:before {
    content: "\f00c"; }
  .list-icon.list-icon-caret li:before {
    content: "\f0da"; }
  .list-icon.list-icon-uncheck li:before {
    content: "\f05c"; }
  .list-icon.list-icon-plus li:before {
    content: "\f055"; }
  .list-icon.list-icon-info li:before {
    content: "\f05a"; }
  .list-icon.list-icon-circle li:before {
    content: "\f10c"; }

/*.icon-list-colored li:before,
.icon-list-colored i {
    color: $base-color;
}

.icon-list-arrow .icon-list-arrow-circle li:before {
    margin-left: -18px;
    position: absolute;
    font-family: fontawesome;
    content: "\f18e";
}
*/

/*# sourceMappingURL=lists.css.map */

/* ----------------------------------------------------------------
    Maps
-----------------------------------------------------------------*/
.map {
  width: 100%;
  height: 370px;
  min-height: 100%; }

/*# sourceMappingURL=maps.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.pagination-wrap {
  display: block;
  clear: both; }

.pagination {
  clear: both;
  display: block;
  width: 100%; }
  .pagination ul {
    display: block;
    padding: 0;
    text-align: center; }
    .pagination ul li {
      list-style: none;
      display: inline; }
    .pagination ul li > a,
    .pagination ul li > span {
      color: #b0b0b0;
      padding: 8px 14px; }
    .pagination ul i {
      font-size: 18px;
      color: #b9b9b9; }
  .pagination.pagination-simple {
    margin: 0; }
    .pagination.pagination-simple li > a,
    .pagination.pagination-simple li > span {
      border: 0;
      background-color: transparent !important; }
    .pagination.pagination-simple li > a:hover i,
    .pagination.pagination-simple li > a:hover,
    .pagination.pagination-simple li > span:hover,
    .pagination.pagination-simple li > a:focus,
    .pagination.pagination-simple li > span:focus {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #333; }
    .pagination.pagination-simple .active > a,
    .pagination.pagination-simple .active > span,
    .pagination.pagination-simple .active > a:hover,
    .pagination.pagination-simple .active > span:hover,
    .pagination.pagination-simple .active > a:focus,
    .pagination.pagination-simple .active > span:focus {
      background: transparent;
      color: #1abc9c;
      font-size: 28px;
      font-weight: 600;
      padding-top: 3px; }
  .pagination.pagination-fancy > li a {
    border-bottom: 3px solid rgba(0, 0, 0, 0.08); }

.pagination.pagination-fancy > li:first-child > a,
.pagination-fancy > li:first-child > span {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  margin-left: 0; }

.pagination.pagination-fancy > li:last-child > a,
.pagination-fancy > li:last-child > span {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  margin-right: 0; }

.pagination .pagination-rounded > li > a,
.pagination .pagination-rounded > li > span {
  padding: 8px 14px 9px; }

.pagination.pagination-rounded > li > a,
.pagination-rounded > li > span {
  border-radius: 50%;
  margin: 0 2px; }

.pagination.pagination-rounded > li:first-child > a,
.pagination-rounded > li:first-child > span {
  background: none !important;
  border: 0 none !important;
  margin-right: 10px;
  padding-top: 10px; }

.pagination.pagination-rounded > li:last-child > a,
.pagination-rounded > li:last-child > span {
  background: none !important;
  border: 0 none !important;
  margin-left: 10px;
  padding-top: 10px; }

.pager > li > a,
.pager > li > span {
  color: #b0b0b0;
  padding: 8px 14px; }
.pager i {
  font-size: 18px;
  color: #fff; }
.pager.pager-fancy > li a {
  background-color: #00c0e9;
  border-color: #00c0e9;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
  .pager.pager-fancy > li a:hover {
    opacity: 0.9; }
.pager.pager-rounded > li a {
  background-color: #00c0e9;
  border-color: #00c0e9;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%; }
.pager.pager-rounded > li a:hover {
  opacity: 0.9; }

.pager.pager-fancy > li:first-child > a,
.pager-fancy > li:first-child > span {
  border-radius: 6px 0 0 6px;
  margin-left: 0; }

.pager.pager-fancy > li:last-child > a,
.pager-fancy > li:last-child > span {
  border-radius: 0 6px 6px 0;
  margin-right: 0; }

.pager.pager-fancy > li:last-child > a i,
.pager-fancy > li:last-child > span i {
  margin-left: 6px; }

.pager.pager-fancy > li:first-child > a i,
.pager-fancy > li:first-child > span i {
  margin-right: 6px; }

.pager.pager-rounded > li:last-child > a i,
.pager-rounded > li:last-child > span i {
  margin-left: 6px; }

.pager.pager-rounded > li:first-child > a i,
.pager-rounded > li:first-child > span i {
  margin-right: 6px; }

.pager-modern {
  background-color: #fbfbfb;
  text-transform: uppercase;
  width: 100%;
  margin: 0; }
  .pager-modern i {
    margin: 0 10px;
    vertical-align: middle; }
  .pager-modern a,
  .pager-modern a:visited {
    color: #555; }
  .pager-modern a:hover {
    color: #111; }
  .pager-modern a span {
    cursor: pointer;
    display: inline-block;
    height: 60px;
    line-height: 59px;
    padding: 0 4%;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s; }
  .pager-modern span:hover {
    background-color: #f6f6f6; }

.pager.pager-modern i {
  color: #222; }

.pager-prev {
  text-align: left; }

.pager-all {
  text-align: center; }

.pager-all i {
  font-size: 38px; }

.pager-next {
  text-align: right; }

/*Post Nav*/
.post-navigation {
  border-top: 1px solid #eee;
  display: inline-block;
  list-style: outside none none;
  min-height: 84px;
  padding: 20px 0 0;
  position: relative;
  width: 100%;
  background-color: #ffffff; }
  .post-navigation .post-next,
  .post-navigation .post-prev {
    position: absolute;
    font-size: 16px;
    max-width: 40%;
    height: 44px; }
    .post-navigation .post-next span,
    .post-navigation .post-prev span {
      display: block;
      color: #565656;
      font-size: 11px;
      text-transform: uppercase;
      opacity: 0.7;
      margin-bottom: -6px;
      margin-top: 3px; }
  .post-navigation a:hover {
    transition: all 0.3s ease;
    color: #1abc9c !important; }
  .post-navigation .post-next {
    right: 25px;
    text-align: right; }
    .post-navigation .post-next:before {
      font-family: fontawesome;
      font-size: 20px;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      transition: all 0.3s ease;
      right: 0;
      content: "\f105";
      padding-top: 2px; }
    .post-navigation .post-next:hover:before {
      transform: translate3d(5px, -50%, 0); }
  .post-navigation .post-prev {
    left: 25px; }
    .post-navigation .post-prev:before {
      font-family: fontawesome;
      font-size: 20px;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      transition: all 0.3s ease;
      padding-top: 2px;
      left: 0;
      content: "\f104"; }
    .post-navigation .post-prev:hover:before {
      transform: translate3d(-5px, -50%, 0); }
  .post-navigation .post-prev-title {
    padding-left: 40px; }
  .post-navigation .post-next-title {
    padding-right: 40px; }
  .post-navigation .post-all {
    font-size: 24px;
    left: auto;
    position: absolute;
    right: 50%;
    text-align: center;
    top: 32px;
    width: 12px;
    opacity: 0.7; }

.single-post .post-navigation .post-prev {
  left: 0px; }
.single-post .post-navigation .post-next {
  right: 0px; }

@media (max-width: 991px) {
  .post-prev-title,
  .post-next-title {
    display: none; } }

/*# sourceMappingURL=pagination-pager.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
 /* ----------------------------------------------------------------------
Breadcrumbs
-------------------------------------------------------------------------*/
.breadcrumb {
  background-color: transparent;
  font-size: 12px;
  margin: 0 0 10px 0;
  padding: 0;
  text-align: left; }
  .breadcrumb ul {
    display: inline;
    margin: 0;
    padding: 0; }
    .breadcrumb ul li {
      display: inline;
      position: relative;
      margin-left: 10px; }
      .breadcrumb ul li:first-child {
        margin-left: 0px; }
      .breadcrumb ul li + li:before {
        content: "";
        display: block;
        width: 2px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: -7px;
        background-color: #9d9d9d; }
      .breadcrumb ul li a {
        color: #9d9d9d; }
  .breadcrumb.fancy {
    overflow: hidden;
    font-size: 12px;
    line-height: 1.3em;
    background-color: transparent;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 14px; }
    .breadcrumb.fancy li {
      line-height: 32px;
      margin: 0 2px 0 10px; }
      .breadcrumb.fancy li + li:before {
        content: none; }
  .breadcrumb.radius li + li:before {
    content: none; }
  .breadcrumb.fancy li:first-child i {
    margin-right: -6px; }
  .breadcrumb.fancy a {
    background: #eee;
    padding: .7em 1em;
    text-decoration: none;
    position: relative; }
    .breadcrumb.fancy a:hover {
      background: #ddd;
      color: #333; }
  .breadcrumb.fancy .active a {
    background: #ddd;
    color: #333; }
  .breadcrumb.fancy a::before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    border-width: 1.5em 0 1.5em 1.1em;
    border-style: solid;
    border-color: #eee #eee #eee transparent;
    left: -1em; }
  .breadcrumb.fancy li:first-child {
    margin: 0 2px 0 0; }
    .breadcrumb.fancy li:first-child a::before {
      border-width: 0; }
  .breadcrumb.fancy a:hover::before,
  .breadcrumb.fancy .active a:before {
    border-color: #ddd #ddd #ddd transparent; }
  .breadcrumb.fancy a::after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    border-top: 1.5em solid transparent;
    border-bottom: 1.5em solid transparent;
    border-left: 1em solid #eee;
    right: -1em; }
  .breadcrumb.fancy a:hover::after {
    border-left-color: #ddd; }
  .breadcrumb.fancy .active a:after {
    border-left-color: #ddd; }
  .breadcrumb.radius {
    background: transparent;
    overflow: hidden;
    font-size: 12px; }
    .breadcrumb.radius li {
      margin-right: 16px; }
    .breadcrumb.radius a:hover,
    .breadcrumb.radius .active a {
      background: #ddd;
      color: #333; }
    .breadcrumb.radius a,
    .breadcrumb.radius .active a {
      background: #eee;
      padding: 4px 8px;
      text-decoration: none;
      position: relative; }
    .breadcrumb.radius a:hover {
      background: #ddd; }
    .breadcrumb.radius a::before, .breadcrumb.radius a::after {
      background: none repeat scroll 0 0 #eee;
      bottom: 0;
      content: "";
      height: 25px;
      position: absolute;
      top: 0;
      -webkit-transform: skew(-8deg);
      -ms-transform: skew(-8deg);
      transform: skew(-8deg);
      width: 10px; }
    .breadcrumb.radius a::before {
      left: -.5em;
      border-radius: 5px 0 0 5px; }
    .breadcrumb.radius a:hover::before {
      background: #ddd; }
    .breadcrumb.radius a::after {
      right: -.5em;
      border-radius: 0 5px 5px 0; }
    .breadcrumb.radius a:hover::after {
      background: #ddd; }
    .breadcrumb.radius .active:hover {
      background: #ddd; }
    .breadcrumb.radius a:hover::after,
    .breadcrumb.radius .active a:after {
      border-left-color: #ddd; }

/*# sourceMappingURL=breadcrumbs.css.map */

.navbar .form-control {
  height: 34px;
  padding: 6px 12px; }
.navbar .btn {
  border-radius: 0;
  padding: 5px 16px 7px; }

/*# sourceMappingURL=nav-navbar.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* ----------------------------------------------------------------------
    call-to-action (call to action)
-------------------------------------------------------------------------*/
.call-to-action {
  position: relative;
  overflow: hidden;
  padding: 36px 40px 24px;
  margin-bottom: 40px; }
  .call-to-action .container {
    position: relative; }
  .call-to-action h3 {
    margin-top: 10px;
    font-weight: 600;
    margin-bottom: 10px; }
    .call-to-action h3 span {
      color: #1abc9c !important; }
  .call-to-action p {
    margin-top: 0; }
  .call-to-action a.btn {
    margin-top: 20px; }
  .call-to-action.call-to-action-border {
    background: #fff;
    border: 1px solid #eee; }
  .call-to-action.call-to-action-colored {
    background-color: #1abc9c; }
    .call-to-action.call-to-action-colored h3,
    .call-to-action.call-to-action-colored p {
      color: #ffffff; }
    .call-to-action.call-to-action-colored h3 span {
      color: #ffffff !important; }
  .call-to-action.call-to-action-dark {
    background-color: #252525; }
    .call-to-action.call-to-action-dark h3,
    .call-to-action.call-to-action-dark p {
      color: #ffffff; }
  .call-to-action.cta-center {
    text-align: center; }
    .call-to-action.cta-center [class*="col-"] {
      width: 100%;
      clear: both;
      display: block; }
  .call-to-action.cta-right {
    text-align: right; }
    .call-to-action.cta-right [class*="col-"]:first-child {
      float: right; }

@media (max-width: 991px) {
  .call-to-action {
    padding: 26px 12px 18px; }

  [class*="col-"] > .call-to-action {
    padding: 26px 26px 18px; } }

/*# sourceMappingURL=call-to-action.css.map */

.tooltip-inner {
  padding: 12px 20px; }

.tooltip.left .tooltip-arrow {
  right: 1px; }

.tooltip-inner {
  white-space: pre-wrap; }

.popover {
  border-radius: 0;
  border: none;
  background-color: #fcfcfc;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  color: #667;
  padding: 3px 3px 8px; }
  .popover > .arrow {
    border-color: transparent !important; }

.popover-content {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400 !important;
  padding-top: 0; }

.popover-title {
  padding: 18px 14px 0;
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  background-color: inherit;
  border-bottom: none;
  border-radius: 0; }
  .popover-title::after {
    border-top: 1px solid #eee;
    content: "";
    display: block;
    height: 1px;
    margin: 12px 0 5px;
    width: 100%; }

.popover-content > img,
.popover-content iframe {
  width: 100%;
  height: auto !important;
  margin-top: 10px; }
.popover-content iframe {
  background: url(../src/images/loading.gif) center center no-repeat; }

/*# sourceMappingURL=tooltop-popover.css.map */

.modal-content {
  border-radius: 4px;
  padding: 10px; }

.modal-title::after {
  border-top: 1px solid #eee;
  content: "";
  display: block;
  height: 1px;
  margin: 16px 0 -12px;
  width: 100%; }

.modal-header,
.modal-footer {
  border: none; }

.modal-backdrop {
  bottom: 0; }

/*Strip*/
.modal-strip {
  background-color: #1abc9c;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 18px;
  position: fixed;
  width: 100%;
  display: inline-block;
  transform: translate3d(0px, 200px, 0px);
  transition: all 0.7s ease 0s;
  z-index: 999 !important; }
  .modal-strip .btn,
  .modal-strip h5,
  .modal-strip h4,
  .modal-strip h3,
  .modal-strip h2,
  .modal-strip h1 {
    margin-bottom: 0; }
  .modal-strip a {
    color: #fff; }
  .modal-strip.modal-top {
    bottom: auto;
    top: 0;
    transform: translate3d(0px, -200px, 0px); }
  .modal-strip.modal-left {
    bottom: auto;
    top: auto;
    left: 0;
    right: auto;
    width: 30%;
    transform: none; }
  .modal-strip.modal-right {
    transform: translate3d(0px, 0px, 0px);
    bottom: auto;
    top: 0; }
  .modal-strip.modal-active {
    transform: translate3d(0px, 0px, 0px); }

/* Zoom-out effect*/
.mfp-zoom-out {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-zoom-out .mfp-content {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3); }
  .mfp-zoom-out.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out; }
  .mfp-zoom-out.mfp-ready .mfp-content {
    opacity: 1;
    transform: scale(1); }
  .mfp-zoom-out.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-zoom-out.mfp-removing .mfp-content {
    transform: scale(1.3);
    opacity: 0; }
  .mfp-zoom-out.mfp-removing.mfp-bg {
    opacity: 0; }

.mfp-ready .modal {
  background: #fff;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  max-width: 500px;
  padding: 40px;
  position: relative;
  z-index: 1050; }

.modal .video-wrap video,
.modal .iframe-wrap iframe,
.modal .audio-wrap audio {
  width: 100%; }

.modal iframe {
  margin-bottom: -10px; }

/*# sourceMappingURL=modal.css.map */

/* ----------------------------------------------------------------------
    Clients Logo
-------------------------------------------------------------------------*/
.grid li {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0.8; }

.clients-carousel a {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0.8; }
  .clients-carousel a img {
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    opacity: 0.8; }

.parallax .clients-carousel a {
  opacity: 1; }

.grid li:hover {
  opacity: 1;
  cursor: pointer; }

.clients-carousel a:hover {
  opacity: 1;
  cursor: pointer; }
  .clients-carousel a:hover img {
    opacity: 1;
    cursor: pointer; }

.client-carousel .owl-item img, .clients-logo .owl-item img {
  padding: 20px; }

.grid {
  padding: 0;
  list-style: none;
  overflow: hidden; }
  .grid li {
    float: left;
    position: relative;
    width: 20%;
    padding: 20px; }
  .grid.grid-2-columns li, .grid.grid-2-columns figure {
    width: 50%; }
  .grid.grid-3-columns li, .grid.grid-3-columns figure {
    width: 33.333333%; }
  .grid.grid-4-columns li, .grid.grid-4-columns figure {
    width: 25%; }
  .grid.grid-5-columns li, .grid.grid-5-columns figure {
    width: 20%; }
  .grid.grid-6-columns li, .grid.grid-6-columns figure {
    width: 16.6666666%; }
  .grid li:hover {
    cursor: pointer;
    background-color: #f8f8f8; }
  .grid li a, .grid li img {
    display: block;
    width: 75%;
    margin-left: auto;
    margin-right: auto; }
  .grid li a img {
    width: 100%; }
  .grid li:before, .grid li:after {
    content: '';
    position: absolute; }
  .grid li:before {
    height: 100%;
    top: 0;
    left: -1px;
    border-left: 1px solid #eee; }
  .grid li:after {
    width: 100%;
    height: 0;
    top: auto;
    left: 0;
    bottom: -1px;
    border-bottom: 1px solid #eee; }
  .grid.clients-grey {
    border: 1px solid #ddd; }
    .grid.clients-grey li {
      background-color: #eee; }
      .grid.clients-grey li:before {
        border-left: 1px solid #ccc; }
      .grid.clients-grey li:after {
        border-bottom: 1px solid #ccc; }
  .grid.clients-border {
    border-width: 10px; }

/*client options*/
/*carousel logo*/
.carousel-description-clients .carousel {
  margin-top: 40px; }
.carousel-description-clients .owl-prev, .carousel-description-clients .owl-next {
  margin-top: -40px !important; }

/*Clients Responsive li*/
body.device-sm .carousel-description-clients .owl-controls, body.device-xs .carousel-description-clients .owl-controls, body.device-xxs .carousel-description-clients .owl-controls {
  display: none; }
body.device-sm .grid li, body.device-xs .grid li {
  width: 33.33%; }
body.device-xxs .grid li {
  width: 50%; }

/*# sourceMappingURL=client-logos.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.pricing-table {
  padding-top: 50px;
  margin: 0;
  padding: 0;
  cursor: pointer; }
  .pricing-table .plan {
    margin-bottom: 60px;
    transition: all .3s ease; }
    .pricing-table .plan:hover {
      margin-top: -10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
    .pricing-table .plan .plan-header {
      position: relative;
      background-color: #ffffff;
      padding: 40px 22px 22px 22px;
      text-align: center; }
    .pricing-table .plan .plan-header {
      border-width: 1px;
      position: relative;
      text-align: center; }
      .pricing-table .plan .plan-header .plan-price {
        font-size: 70px;
        font-weight: 600;
        position: relative;
        text-align: center;
        padding: 20px 0; }
        .pricing-table .plan .plan-header .plan-price sup {
          font-size: 24px;
          position: relative;
          top: -30px;
          color: #bdc3c7; }
        .pricing-table .plan .plan-header .plan-price span {
          font-size: 16px;
          color: #bdc3c7; }
  .pricing-table .plan-header > h4 {
    margin-bottom: 0; }
  .pricing-table .plan-list {
    background-color: #ffffff;
    border-width: 0 1px 1px;
    padding: 20px 0 40px; }
    .pricing-table .plan-list ul {
      display: table;
      margin: 0 auto;
      padding: 0 40px; }
      .pricing-table .plan-list ul li {
        list-style: none;
        border-bottom: 1px solid #EAECEB;
        line-height: 42px; }
        .pricing-table .plan-list ul li:last-child {
          border: none; }
        .pricing-table .plan-list ul li i {
          margin-right: 12px; }
        .pricing-table .plan-list ul li span {
          color: #34495e; }
    .pricing-table .plan-list .plan-button {
      margin-top: 20px !important;
      text-align: center; }
  .pricing-table .plan.featured {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-top: -30px;
    padding-bottom: 0; }
    .pricing-table .plan.featured .plan-list {
      padding: 20px 0 40px; }
  .pricing-table .plan .plan-featured-item {
    background-color: #f8f8f8;
    border: 1px solid #e3e3e3 !important;
    font-weight: 600;
    margin-top: 21px;
    padding: 0 20px; }

.pricing-table .plan .btn,
.plan .button {
  margin: 0; }

.pricing-table.colored .plan-header {
  background-color: #1abc9c;
  border-color: rgba(0, 0, 0, 0.05);
  color: #fff; }
.pricing-table.colored .text-muted {
  color: #fff; }
.pricing-table.colored .plan-header h4,
.pricing-table.colored .plan-header .plan-price sup,
.pricing-table.colored .plan-header .plan-price span {
  color: #fff; }
.pricing-table.transparent .plan-header,
.pricing-table.transparent .plan-list {
  background-color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.6); }
.pricing-table.transparent .plan-list ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
.pricing-table .plan .plan-featured-item {
  background-color: transparent; }

.section-grey .pricing-table .plan-list {
  background-color: #f8f8f8; }

section .pricing-table.full-width {
  padding-left: 30px;
  padding-right: 30px; }

body.device-lg .pricing-table .col-md-2, body.device-md .pricing-table .col-md-2 {
  width: 20%; }
body.device-lg .pricing-table .col-md-2 .plan-list ul li, body.device-md .pricing-table .col-md-2 .plan-list ul li {
  font-size: 13px; }

/*# sourceMappingURL=pricing-tables.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
/* ----------------------------------------------------------------
    Testimonial
-----------------------------------------------------------------*/
.testimonial .testimonial-item {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 46px;
  text-align: center; }
  .testimonial .testimonial-item > img {
    border-radius: 50%;
    display: block;
    float: none;
    height: 72px;
    margin: 0 auto 10px;
    width: 72px; }
  .testimonial .testimonial-item > p {
    line-height: 26px;
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 20px;
    font-weight: 300;
    margin-top: 10px;
	padding:30px 50px; }
  .testimonial .testimonial-item > span {
    font-weight: 600;
    font-size: 15px;
    display: block;
    line-height: 22px; }
    .testimonial .testimonial-item > span ~ span {
      font-weight: 500;
      color: #9d9d9d;
      display: block;
      font-size: 13px;
      font-style: italic; }
.testimonial.testimonial-box .testimonial-item {
  padding: 46px;
  background-color: #ffffff;
  border-radius: 8px; }
  .testimonial.testimonial-box .testimonial-item p {
    font-size: 18px; }
.testimonial.testimonial-single .testimonial-item {
  padding: 30px 80px;
  background-color: transparent;
  border: 0; }
  .testimonial.testimonial-single .testimonial-item > img {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06); }
  .testimonial.testimonial-single .testimonial-item p {
    font-size: 20px; }
.testimonial.testimonial-left .testimonial-item {
  text-align: left; }
  .testimonial.testimonial-left .testimonial-item > img {
    display: inherit;
    float: left;
    margin: 0 0 40px 0;
    height: 160px;
    width: 160px; }
  .testimonial.testimonial-left .testimonial-item > p,
  .testimonial.testimonial-left .testimonial-item > span {
    margin-left: 220px; }
.testimonial.testimonial-blockquote .testimonial-item > p {
  font-family: "Merriweather";
  font-style: italic;
  font-size: 24px;
  line-height: 32px;
  padding: 30px 10px; }

[data-arrows="false"].testimonial-single.testimonial-blockquote .testimonial-item {
  padding: 0 !important; }
  [data-arrows="false"].testimonial-single.testimonial-blockquote .testimonial-item > p {
    padding: 10px !important; }

.equalize.testimonial-box .testimonial-item > span {
  clear: both; }
  .equalize.testimonial-box .testimonial-item > span ~ span {
    clear: both;
    margin-bottom: 70px; }

.widget .testimonial .testimonial-item {
  text-align: left;
  padding: 0; }
  .widget .testimonial .testimonial-item > img {
    display: inherit;
    float: left;
    margin: 0 0 40px 0;
    height: 48px;
    width: 48px; }
  .widget .testimonial .testimonial-item > p {
    font-size: 15px;
    padding-bottom: 14px; }
  .widget .testimonial .testimonial-item > p,
  .widget .testimonial .testimonial-item > span {
    margin-left: 68px; }
  .widget .testimonial .testimonial-item > span {
    font-size: 13px; }
.widget .testimonial.owl-carousel .owl-controls .owl-dots {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  position: absolute !important;
  top: -46px !important;
  right: 0 !important; }

/* ----------------------------------------------------------------
    Testimonial - Responsive
-----------------------------------------------------------------*/
@media (max-width: 992px) {
  .testimonial.testimonial-left .testimonial-item {
    text-align: left; }
    .testimonial.testimonial-left .testimonial-item > img {
      display: inherit;
      float: none;
      margin: 20px auto;
      height: 80px;
      width: 80px; }
    .testimonial.testimonial-left .testimonial-item > p,
    .testimonial.testimonial-left .testimonial-item > span {
      margin-left: 0;
      text-align: center; }
  .testimonial.testimonial-single .testimonial-item {
    padding: 10px 20px; }
    .testimonial.testimonial-single .testimonial-item > img {
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06); }
    .testimonial.testimonial-single .testimonial-item p {
      font-size: 14px; } }

/*# sourceMappingURL=testimonials.css.map */

/* ----------------------------------------------------------------------
TIMELINE
-------------------------------------------------------------------------*/
.timeline {
  margin-bottom: 60px;
  list-style: none;
  padding: 20px 0 20px;
  position: relative; }

.timeline-circles {
  list-style: none;
  padding: 20px 0 20px;
  position: relative; }

.timeline:before {
  background-color: #ccc;
  bottom: 0;
  content: " ";
  left: 50%;
  margin-left: -1.5px;
  position: absolute;
  top: 0;
  width: 4px; }

.timeline-circles:before, .timeline-circles:after {
  background-color: #ccc;
  border-radius: 10px;
  content: " ";
  height: 20px;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 20px; }
.timeline-circles:before {
  top: -25px; }
.timeline-circles:after {
  bottom: -40px; }

.timeline li.timeline-date {
  margin-bottom: 25px; }
.timeline li:nth-child(2) {
  margin-top: 0 !important; }
.timeline li:before {
  content: " ";
  display: table; }
.timeline li:after {
  content: " ";
  display: table;
  clear: both; }

/*.timeline li:not(.timeline-date) {
	margin-top: -36%;
}
body.device-sm .timeline li:not(.timeline-date) {
	margin-top: -52%;
}*/
.timeline-date {
  background-color: #eee;
  border-radius: 6px;
  color: #888;
  display: block;
  font-size: 16px;
  line-height: 32px;
  margin: 0 auto;
  text-align: center;
  width: 200px; }

.timeline li .timeline-block {
  padding: 0 10px;
  position: relative;
  width: 48%;
  float: left; }
.timeline.timeline-transparent .timeline-block .post-item {
  background-color: #fff;
  padding: 20px; }
.timeline li .timeline-block:before {
  position: absolute;
  top: 8px;
  right: -4%;
  display: inline-block;
  border-top: 15px solid transparent;
  border-right: 15px solid #ccc;
  border-left: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " "; }
.timeline li .timeline-block .timeline-block-content {
  height: 100% !important;
  overflow: hidden; }

/** panel arrows **/
.timeline-block-content h3 {
  margin-top: 0; }

.timeline-block-image, .timeline-block-embed {
  border-bottom: 5px solid #ddd;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  width: 100%; }

.timeline-block-image {
  max-height: 200px; }
  .timeline-block-image img {
    margin-top: -10%;
    width: 100%; }
  .timeline-block-image iframe {
    width: 100%;
    height: 100%; }

.timeline li:nth-child(2n+1) .timeline-block {
  padding: 20px 0 0 10px;
  float: right; }
  .timeline li:nth-child(2n+1) .timeline-block:before {
    border-right-width: 0;
    border-left-width: 15px;
    left: -4%;
    right: auto;
    top: 22px; }
  .timeline li:nth-child(2n+1) .timeline-block:after {
    border-left-width: 0;
    border-right-width: 15px;
    left: -4%;
    right: auto; }

/*Responsive*/
body.device-xs .timeline:before {
  left: 0; }
body.device-xs .timeline-circles:before, body.device-xs .timeline-circles:after {
  left: 0; }
body.device-xs .timeline li:not(.timeline-date)::before {
  left: 0; }
body.device-xxs .timeline:before {
  left: 0; }
body.device-xxs .timeline-circles:before, body.device-xxs .timeline-circles:after {
  left: 0; }
body.device-xxs .timeline li:not(.timeline-date)::before {
  left: 0; }
body.device-xs .timeline-date, body.device-xxs .timeline-date {
  margin-left: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }
body.device-xs .timeline li .timeline-block, body.device-xxs .timeline li .timeline-block {
  float: left;
  margin-left: 10px;
  width: 100% !important;
  padding-left: 10px; }
body.device-xs .timeline li .timeline-block, body.device-xxs .timeline li .timeline-block, body.device-xs .timeline li:nth-child(2n+1) .timeline-block, body.device-xxs .timeline li:nth-child(2n+1) .timeline-block {
  padding: 20px 10px 0 20px; }
body.device-xs .timeline li .timeline-block:before, body.device-xxs .timeline li .timeline-block:before {
  left: -8px;
  right: auto;
  border-left-width: 15px;
  border-right-width: 0; }
body.device-xs .timeline li .timeline-block:after, body.device-xxs .timeline li .timeline-block:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto; }
body.device-xs .timeline.timeline-simple .timeline-date, body.device-xxs .timeline.timeline-simple .timeline-date {
  margin-left: -1px; }

/*# sourceMappingURL=timeline.css.map */



/*# sourceMappingURL=yt-player.css.map */

.box-fancy > .container {
  padding: 0 30px; }
.box-fancy [class*="col-"] {
  padding: 5% !important; }
.box-fancy.fancy-box-over {
  top: -100px; }

body.device-xxs .box-fancy [class*="col-"] {
  padding: 10% !important; }

.over-slider-top {
  top: -100px; }

.site-map i {
  margin-right: 8px; }
.site-map ul li {
  padding-bottom: 2px; }

.animated.infinite {
  animation-duration: 3s;
  animation-fill-mode: both; }

/*# sourceMappingURL=elements-extras.css.map */

/* ----------------------------------------------------------------------
	Parallax
-------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.parallax-container {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -100; }

.parallax-container.parallax-responsive {
  background-size: cover !important;
  background-attachment: scroll !important;
  background-position: center center !important; }

section[data-parallax-image] {
  background-color: transparent; }

/*# sourceMappingURL=parallax.css.map */

.hidden,
.animated {
  opacity: 0; }

.visible {
  opacity: 1; }

.text-center {
  text-align: center !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

.center {
  float: none !important;
  margin: 0 auto; }

.block {
  display: block; }

.align-center {
  display: inline-flex;
  text-align: center; }

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none; }

.vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.text-middle {
  display: table-cell;
  vertical-align: middle;
  margin: auto;
  z-index: 3;
  position: relative; }

.text-bottom {
  display: table-cell;
  vertical-align: bottom;
  margin: auto;
  z-index: 3;
  position: relative; }

.text-background-light {
  background-color: #fff;
  padding: 0 4px; }

.text-background-dark {
  background-color: #444;
  padding: 0 4px; }

.text-shadow-light {
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.53); }

.text-shadow-dark {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.53); }

.background-white,
.background-light {
  background-color: #fff !important; }

.background-grey {
  background-color: #f7f7f7 !important; }

.background-dark {
  background-color: #101010 !important; }

.background-pattern {
  background-image: url(../src/images/overlay-pattern/gplay.html); }

.background-pattern-1 {
  background-image: url(../src/images/overlay-pattern/3px-tile.html); }

.background-pattern-2 {
  background-image: url(../src/images/overlay-pattern/asfalt-dark.html); }

.background-pattern-3 {
  background-image: url(../src/images/overlay-pattern/axiom-pattern.html); }

.shadow {
  box-shadow: 0 4px 16px #e2e2e2; }

.post-shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); }

.shadow-bottom {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1); }

.shadow-inside {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); }

.shadow-inside-top {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.08); }

.shadow-inside-bottom {
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.08); }

.text-medium-light {
  font-size: 50px !important;
  font-weight: 100;
  line-height: 1.1;
  font-family: "Open Sans"; }

.text-medium {
  font-size: 50px !important;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 20px; }

body.device-xs .text-medium,
body.device-xs .text-medium-light {
  font-size: 40px !important; }

body.device-xxs .text-medium,
body.device-xxs .text-medium-light {
  font-size: 30px !important; }

.text-large-light,
.text-large-light span,
.text-large-light span span {
  font-size: 90px !important;
  line-height: 100px !important;
  font-weight: 120 !important; }

.text-large,
.text-large span,
.text-large span span {
  font-size: 90px !important;
  line-height: 100px !important;
  font-weight: 800 !important; }

.text-extra-large,
.text-extra-large span {
  font-size: 120px !important;
  font-weight: 800;
  line-height: 120px; }

.text-lg,
.text-lg span {
  color: #ffffff;
  font-size: 80px !important;
  line-height: 80px !important;
  font-weight: 800 !important;
  margin-bottom: 10px; }

.text-lg-x2,
.text-lg-x2 span {
  color: #ffffff;
  font-size: 120px !important;
  line-height: 140px !important;
  font-weight: 100;
  margin-bottom: 10px; }

.text-lg-x3,
.text-lg-x3 span {
  color: #ffffff;
  font-size: 230px;
  line-height: 250px;
  font-weight: 100; }

.text-small {
  font-size: 16px !important;
  font-weight: 800;
  line-height: 1.1; }

.text-light,
.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light .lead,
.text-light p,
.text-light a:not(.btn),
.text-light a:not(.btn):hover:not(.btn),
.text-light i,
.text-light li,
.text-light label,
.text-light div:not(.alert),
.text-light span:not(.btn-label) {
  color: #fff !important; }

.text-dark,
.text-dark h1,
.text-dark h2,
.text-dark h3,
.text-dark h4,
.text-dark h5,
.text-dark h6,
.text-dark p,
.text-dark .lead,
.text-dark a:not(.button),
.text-dark a:not(.btn):hover,
.text-dark i,
.text-dark li,
.text-dark label,
.text-dark div:not(.alert),
.text-dark span:not(.btn-label) {
  color: #111 !important; }

.text-grey,
.text-grey h1,
.text-grey h2,
.text-grey h3,
.text-grey h4,
.text-grey h5,
.text-grey h6,
.text-grey .lead,
.text-grey p,
.text-grey a:not(.button),
.text-grey a:not(.btn-light):hover,
.text-grey i,
.text-grey li,
.text-grey label,
.text-grey div:not(.alert),
.text-grey span:not(.btn-label) {
  color: #ccc; }

.with-errors li {
  color: #981a1a !important; }

.text-muted {
  color: #c2c2c5; }

.text-bold {
  font-weight: bold !important; }

.text-azure {
  color: #0095C8 !important; }

.text-orange {
  color: #FF6600 !important; }

.text-green {
  color: #1FBBA6 !important; }

.text-blue {
  color: #5F8295 !important; }

.text-pink {
  color: #DD5A82 !important; }

.text-purple {
  color: #DD5A82 !important; }

.text-bricky {
  color: #894550 !important; }

.text-yellow {
  color: #FFB848 !important; }

.text-red {
  color: #CA0027 !important; }

/*font weight*/
.font-weight-100 {
  font-weight: 100 !important; }

.font-weight-300 {
  font-weight: 300 !important; }

.font-weight-400 {
  font-weight: 400 !important; }

.font-weight-500 {
  font-weight: 500 !important; }

.font-weight-600 {
  font-weight: 600 !important; }

.font-weight-700 {
  font-weight: 700 !important; }

.font-weight-800 {
  font-weight: 800 !important; }

.border-box {
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 30px; }

.border-left {
  border-left: 1px solid #eee; }

.border-top {
  border-top: 1px solid #eee; }

.border-right {
  border-width: 0 1px 0 0;
  border-color: #eee;
  border-style: solid; }

.border-bottom {
  border-bottom: 1px solid #eee; }

.border-dotted {
  border-style: dotted; }

.slider-size-half {
  max-height: 560px; }

.no-margin {
  margin: 0px !important; }

.m-b-0 {
  margin-bottom: 0px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.m-b-60 {
  margin-bottom: 60px !important; }

.m-b-70 {
  margin-bottom: 70px !important; }

.m-b-80 {
  margin-bottom: 80px !important; }

.m-b-90 {
  margin-bottom: 90px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.m-b-150 {
  margin-bottom: 150px !important; }

.m-b-200 {
  margin-bottom: 200px !important; }

.m-t-0 {
  margin-top: 0px !important; }

.m-t-5 {
  margin-top: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.m-t-60 {
  margin-top: 60px !important; }

.m-t-70 {
  margin-top: 70px !important; }

.m-t-80 {
  margin-top: 80px !important; }

.m-t-90 {
  margin-top: 90px !important; }

.m-t-100 {
  margin-top: 100px !important; }

.m-l-0 {
  margin-left: 0px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.m-l-60 {
  margin-left: 60px !important; }

.m-l-70 {
  margin-left: 70px !important; }

.m-l-80 {
  margin-left: 80px !important; }

.m-l-90 {
  margin-left: 90px !important; }

.m-l-100 {
  margin-left: 100px !important; }

.m-r-0 {
  margin-right: 0px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.m-r-35 {
  margin-right: 35px !important; }

.m-r-40 {
  margin-right: 40px !important; }

.m-r-50 {
  margin-right: 50px !important; }

.m-r-60 {
  margin-right: 60px !important; }

.m-r-70 {
  margin-right: 70px !important; }

.m-r-80 {
  margin-right: 80px !important; }

.m-r-90 {
  margin-right: 90px !important; }

.m-r-100 {
  margin-right: 100px !important; }

.m-0 {
  margin: 0px !important; }

.m-5 {
  margin: 5px !important; }

.m-10 {
  margin: 10px !important; }

.m-15 {
  margin: 15px !important; }

.m-20 {
  margin: 20px !important; }

.m-25 {
  margin: 25px !important; }

.m-30 {
  margin: 30px !important; }

.m-35 {
  margin: 35px !important; }

.m-40 {
  margin: 40px !important; }

.m-50 {
  margin: 50px !important; }

.m-60 {
  margin: 60px !important; }

.m-70 {
  margin: 70px !important; }

.m-80 {
  margin: 80px !important; }

.m-90 {
  margin: 90px !important; }

.m-100 {
  margin: 100px !important; }

/*Margins*/
.col-no-margin {
  padding: 0 !important; }
  .col-no-margin [class^="col-"] {
    margin: 0 !important;
    padding: 0 !important; }

.row.col-no-margin {
  margin: 0 !important;
  padding: 0 !important; }

.row.col-small-margins [class^="col-"] {
  margin: 0 0 20px 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

.p-0 {
  padding: 0px !important; }

.p-5 {
  padding: 5px !important; }

.p-10 {
  padding: 10px !important; }

.p-15 {
  padding: 15px !important; }

.p-20 {
  padding: 20px !important; }

.p-25 {
  padding: 25px !important; }

.p-30 {
  padding: 30px !important; }

.p-35 {
  padding: 35px !important; }

.p-40 {
  padding: 40px !important; }

.p-50 {
  padding: 50px !important; }

.p-60 {
  padding: 60px !important; }

.p-70 {
  padding: 70px !important; }

.p-80 {
  padding: 80px !important; }

.p-90 {
  padding: 90px !important; }

.p-100 {
  padding: 100px !important; }

.p-150 {
  padding: 150px !important; }

.p-200 {
  padding: 200px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.p-b-60 {
  padding-bottom: 60px !important; }

.p-b-70 {
  padding-bottom: 70px !important; }

.p-b-80 {
  padding-bottom: 80px !important; }

.p-b-90 {
  padding-bottom: 90px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.p-b-110 {
  padding-bottom: 110px !important; }

.p-b-120 {
  padding-bottom: 120px !important; }

.p-b-130 {
  padding-bottom: 130px !important; }

.p-b-140 {
  padding-bottom: 140px !important; }

.p-b-150 {
  padding-bottom: 150px !important; }

.p-b-200 {
  padding-bottom: 200px !important; }

.p-t-0 {
  padding-top: 0px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.p-t-60 {
  padding-top: 60px !important; }

.p-t-70 {
  padding-top: 70px !important; }

.p-t-80 {
  padding-top: 80px !important; }

.p-t-90 {
  padding-top: 90px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.p-t-110 {
  padding-top: 110px !important; }

.p-t-120 {
  padding-top: 120px !important; }

.p-t-130 {
  padding-top: 130px !important; }

.p-t-140 {
  padding-top: 140px !important; }

.p-t-150 {
  padding-top: 150px !important; }

.p-t-200 {
  padding-top: 200px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.p-r-60 {
  padding-right: 60px !important; }

.p-r-70 {
  padding-right: 70px !important; }

.p-r-80 {
  padding-right: 80px !important; }

.p-r-90 {
  padding-right: 90px !important; }

.p-r-100 {
  padding-right: 100px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.p-l-60 {
  padding-left: 60px !important; }

.p-l-70 {
  padding-left: 70px !important; }

.p-l-80 {
  padding-left: 80px !important; }

.p-l-90 {
  padding-left: 90px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.b-r-2 {
  border-radius: 2px !important; }

.b-r-3 {
  border-radius: 3px !important; }

.b-r-4 {
  border-radius: 4px !important; }

.b-r-5 {
  border-radius: 5px !important; }

.b-r-6 {
  border-radius: 6px !important; }

.b-r-7 {
  border-radius: 7px !important; }

.b-r-8 {
  border-radius: 8px !important; }

.b-r-9 {
  border-radius: 9px !important; }

.b-r-10 {
  border-radius: 10px !important; }

.b-radius {
  border-radius: 50% !important; }

.float-right {
  float: right !important; }

.float-left {
  float: left !important; }

.float-none {
  float: none !important; }

.fullwidth {
  width: 100% !important; }

/*Line height*/
.lh80 {
  line-height: 80px !important; }

/*Resposnive settings*/
@media (max-width: 991px) {
  .text-lg,
  .text-lg span {
    font-size: 60px !important;
    line-height: 60px !important; }

  .text-lg-x2,
  .text-lg-x2 span,
  .text-lg-x3,
  .text-lg-x3 span {
    font-size: 70px !important;
    line-height: 70px !important; } }
@media (max-width: 768px) {
  .text-lg,
  .text-lg span {
    font-size: 30px !important;
    line-height: 30px !important; }

  .text-lg-x2,
  .text-lg-x2 span,
  .text-lg-x3,
  .text-lg-x3 span {
    font-size: 30px !important;
    line-height: 30px !important; } }

/*# sourceMappingURL=helpers.css.map */

.loader01::after,
.loader04::after,
.loader11::after,
.loader11::before,
.spinner .bar:after {
  content: ""; }

@keyframes ball-grid-pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0.5);
    opacity: 0.7; }
  100% {
    transform: scale(1);
    opacity: 1; } }
.ball-grid-pulse {
  width: 57px; }

.ball-grid-pulse > div:nth-child(1) {
  animation-delay: .78s;
  animation-duration: 1.55s; }

.ball-grid-pulse > div:nth-child(2) {
  animation-delay: -50ms;
  animation-duration: 1.22s; }

.ball-grid-pulse > div:nth-child(3) {
  animation-delay: .74s;
  animation-duration: 1.03s; }

.ball-grid-pulse > div:nth-child(4) {
  animation-delay: -20ms;
  animation-duration: 1.45s; }

.ball-grid-pulse > div:nth-child(5) {
  animation-delay: .66s;
  animation-duration: 0.75s; }

.ball-grid-pulse > div:nth-child(6) {
  animation-delay: -.15s;
  animation-duration: 1.18s; }

.ball-grid-pulse > div:nth-child(7) {
  animation-delay: .77s;
  animation-duration: 1.08s; }

.ball-grid-pulse > div:nth-child(8) {
  animation-delay: -.11s;
  animation-duration: 1.46s; }

.ball-grid-pulse > div:nth-child(9) {
  animation-delay: .1s;
  animation-duration: 1.22s; }

.ball-grid-pulse > div {
  background-color: #26B8F3;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  float: left;
  animation-name: ball-grid-pulse;
  animation-iteration-count: infinite;
  animation-delay: 0; }

@keyframes square-spin {
  25% {
    transform: perspective(100px) rotateX(180deg) rotateY(0); }
  50% {
    transform: perspective(100px) rotateX(180deg) rotateY(180deg); }
  75% {
    transform: perspective(100px) rotateX(0) rotateY(180deg); }
  100% {
    transform: perspective(100px) rotateX(0) rotateY(0); } }
.square-spin > div {
  width: 50px;
  height: 50px;
  animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite; }

.ball-rotate > div, .ball-rotate > div:after, .ball-rotate > div:before {
  width: 15px;
  height: 15px;
  margin: 2px;
  background-color: #26B8F3;
  border-radius: 100%; }

@keyframes rotate {
  0% {
    transform: rotate(0); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }
.ball-rotate > div {
  animation-fill-mode: both; }

.ball-rotate > div:first-child {
  animation: rotate 1s 0s cubic-bezier(0.7, -0.13, 0.22, 0.86) infinite; }

.ball-rotate > div:after, .ball-rotate > div:before {
  content: "";
  position: absolute;
  opacity: 0.8; }

.ball-rotate > div:before {
  top: 0;
  left: -28px; }

.ball-rotate > div:after {
  top: 0;
  left: 25px; }

@keyframes cube-transition {
  25% {
    transform: translateX(50px) scale(0.5) rotate(-90deg); }
  50% {
    transform: translate(50px, 50px) rotate(-180deg); }
  75% {
    transform: translateY(50px) scale(0.5) rotate(-270deg); }
  100% {
    transform: rotate(-360deg); } }
.cube-transition {
  position: relative;
  transform: translate(-25px, -25px); }

.cube-transition > div {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: #26B8F3;
  animation: cube-transition 1.6s 0s infinite ease-in-out; }

.cube-transition > div:last-child {
  animation-delay: -0.8s; }

@keyframes ball-zig {
  33% {
    transform: translate(-15px, -30px); }
  66% {
    transform: translate(15px, -30px); }
  100% {
    transform: translate(0, 0); } }
@keyframes ball-zag {
  33% {
    transform: translate(15px, 30px); }
  66% {
    transform: translate(-15px, 30px); }
  100% {
    transform: translate(0, 0); } }
.ball-zig-zag {
  position: relative;
  transform: translate(-15px, -15px); }

.ball-zig-zag > div {
  background-color: #26B8F3;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px 2px 2px 15px;
  animation-fill-mode: both;
  position: absolute;
  top: 4px;
  left: -7px; }

.ball-zig-zag > div:first-child {
  animation: ball-zig 0.7s 0s infinite linear; }

.ball-zig-zag > div:last-child {
  animation: ball-zag 0.7s 0s infinite linear; }

.ball-triangle-path > div:nth-child(1), .ball-triangle-path > div:nth-child(2), .ball-triangle-path > div:nth-child(3) {
  animation-delay: 0;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }

@keyframes ball-triangle-path-1 {
  33% {
    transform: translate(25px, -50px); }
  66% {
    transform: translate(50px, 0); }
  100% {
    transform: translate(0, 0); } }
@keyframes ball-triangle-path-2 {
  33% {
    transform: translate(25px, 50px); }
  66% {
    transform: translate(-25px, 50px); }
  100% {
    transform: translate(0, 0); } }
@keyframes ball-triangle-path-3 {
  33% {
    transform: translate(-50px, 0); }
  66% {
    transform: translate(-25px, -50px); }
  100% {
    transform: translate(0, 0); } }
.ball-triangle-path {
  position: relative;
  transform: translate(-29.99px, -37.51px); }

.ball-triangle-path > div:nth-child(1) {
  animation-name: ball-triangle-path-1; }

.ball-triangle-path > div:nth-child(2) {
  animation-name: ball-triangle-path-2; }

.ball-triangle-path > div:nth-child(3) {
  animation-name: ball-triangle-path-3; }

.ball-triangle-path > div {
  animation-fill-mode: both;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid #26B8F3; }

.ball-triangle-path > div:nth-of-type(1) {
  top: 50px; }

.ball-triangle-path > div:nth-of-type(2) {
  left: 25px; }

.ball-triangle-path > div:nth-of-type(3) {
  top: 50px;
  left: 50px; }

@keyframes line-scale {
  0%,100% {
    transform: scaley(1); }
  50% {
    transform: scaley(0.4); } }
.line-scale > div:nth-child(1) {
  animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.line-scale > div:nth-child(2) {
  animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.line-scale > div:nth-child(3) {
  animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.line-scale > div:nth-child(4) {
  animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.line-scale > div:nth-child(5) {
  animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08); }

.line-scale > div {
  background-color: #26B8F3;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block; }

@keyframes ball-scale-multiple {
  0% {
    transform: scale(0);
    opacity: 0; }
  5% {
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 0; } }
.ball-scale-multiple {
  position: relative;
  transform: translateY(-30px); }

.ball-scale-multiple > div:nth-child(2) {
  animation-delay: -0.4s; }

.ball-scale-multiple > div:nth-child(3) {
  animation-delay: -0.2s; }

.ball-scale-multiple > div {
  background-color: #26B8F3;
  border-radius: 100%;
  position: absolute;
  left: -30px;
  top: 0;
  opacity: 0;
  margin: 0;
  width: 60px;
  height: 60px;
  animation: ball-scale-multiple 1s 0s linear infinite; }

.ball-beat > div, .ball-pulse-sync > div {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  background-color: #26B8F3;
  display: inline-block; }

@keyframes ball-pulse-sync {
  33% {
    transform: translateY(10px); }
  66% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }
.ball-pulse-sync > div:nth-child(1) {
  animation: ball-pulse-sync 0.9s -0.22s infinite ease-in-out; }

.ball-pulse-sync > div:nth-child(2) {
  animation: ball-pulse-sync 0.9s -0.11s infinite ease-in-out; }

.ball-pulse-sync > div:nth-child(3) {
  animation: ball-pulse-sync 0.9s 0s infinite ease-in-out; }

.ball-pulse-sync > div {
  animation-fill-mode: both; }

@keyframes ball-beat {
  50% {
    opacity: .2;
    transform: scale(0.75); }
  100% {
    opacity: 1;
    transform: scale(1); } }
.ball-beat > div {
  animation: ball-beat 0.7s 0s infinite linear; }

.ball-beat > div:nth-child(2n-1) {
  animation-delay: -0.35s !important; }

@keyframes line-scale-pulse-out-rapid {
  0%,90% {
    transform: scaley(1); }
  80% {
    transform: scaley(0.3); } }
.line-scale-pulse-out-rapid > div {
  background-color: #26B8F3;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  display: inline-block;
  vertical-align: middle;
  animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78); }

.line-scale-pulse-out-rapid > div:nth-child(2), .line-scale-pulse-out-rapid > div:nth-child(4) {
  animation-delay: -0.25s !important; }

.line-scale-pulse-out-rapid > div:nth-child(1), .line-scale-pulse-out-rapid > div:nth-child(5) {
  animation-delay: 0s !important; }

@keyframes ball-scale-ripple-multiple {
  0% {
    transform: scale(0.1);
    opacity: 1; }
  70% {
    transform: scale(1);
    opacity: 0.7; }
  100% {
    opacity: 0; } }
.ball-scale-ripple-multiple {
  position: relative;
  transform: translateY(-25px); }

.ball-scale-ripple-multiple > div:nth-child(0) {
  animation-delay: -0.8s; }

.ball-scale-ripple-multiple > div:nth-child(1) {
  animation-delay: -0.6s; }

.ball-scale-ripple-multiple > div:nth-child(2) {
  animation-delay: -0.4s; }

.ball-scale-ripple-multiple > div:nth-child(3) {
  animation-delay: -0.2s; }

.ball-scale-ripple-multiple > div {
  position: absolute;
  top: -2px;
  left: -26px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  animation: ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8); }

@keyframes ball-spin-fade-loader {
  50% {
    opacity: .3;
    transform: scale(0.4); }
  100% {
    opacity: 1;
    transform: scale(1); } }
.ball-spin-fade-loader {
  position: relative;
  top: -10px;
  left: -10px; }

.ball-spin-fade-loader > div:nth-child(1) {
  top: 25px;
  left: 0;
  animation: ball-spin-fade-loader 1s -0.96s infinite linear; }

.ball-spin-fade-loader > div:nth-child(2) {
  top: 17.05px;
  left: 17.05px;
  animation: ball-spin-fade-loader 1s -0.84s infinite linear; }

.ball-spin-fade-loader > div:nth-child(3) {
  top: 0;
  left: 25px;
  animation: ball-spin-fade-loader 1s -0.72s infinite linear; }

.ball-spin-fade-loader > div:nth-child(4) {
  top: -17.05px;
  left: 17.05px;
  animation: ball-spin-fade-loader 1s -0.6s infinite linear; }

.ball-spin-fade-loader > div:nth-child(5) {
  top: -25px;
  left: 0;
  animation: ball-spin-fade-loader 1s -0.48s infinite linear; }

.ball-spin-fade-loader > div:nth-child(6) {
  top: -17.05px;
  left: -17.05px;
  animation: ball-spin-fade-loader 1s -0.36s infinite linear; }

.ball-spin-fade-loader > div:nth-child(7) {
  top: 0;
  left: -25px;
  animation: ball-spin-fade-loader 1s -0.24s infinite linear; }

.ball-spin-fade-loader > div:nth-child(8) {
  top: 17.05px;
  left: -17.05px;
  animation: ball-spin-fade-loader 1s -0.12s infinite linear; }

.ball-spin-fade-loader > div {
  background-color: #26B8F3;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute; }

@keyframes line-spin-fade-loader {
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }
.line-spin-fade-loader {
  position: relative;
  top: -10px;
  left: -4px; }

.line-spin-fade-loader > div:nth-child(1) {
  top: 20px;
  left: 0;
  animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out; }

.line-spin-fade-loader > div:nth-child(2) {
  top: 13.64px;
  left: 13.64px;
  transform: rotate(-45deg);
  animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out; }

.line-spin-fade-loader > div:nth-child(3) {
  top: 0;
  left: 20px;
  transform: rotate(90deg);
  animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out; }

.line-spin-fade-loader > div:nth-child(4) {
  top: -13.64px;
  left: 13.64px;
  transform: rotate(45deg);
  animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out; }

.line-spin-fade-loader > div:nth-child(5) {
  top: -20px;
  left: 0;
  animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out; }

.line-spin-fade-loader > div:nth-child(6) {
  top: -13.64px;
  left: -13.64px;
  transform: rotate(-45deg);
  animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out; }

.line-spin-fade-loader > div:nth-child(7) {
  top: 0;
  left: -20px;
  transform: rotate(90deg);
  animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out; }

.line-spin-fade-loader > div:nth-child(8) {
  top: 13.64px;
  left: -13.64px;
  transform: rotate(45deg);
  animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out; }

.line-spin-fade-loader > div {
  background-color: #26B8F3;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
  width: 5px;
  height: 15px; }

.pacman > div:first-of-type, .pacman > div:nth-child(2) {
  width: 0;
  height: 0;
  border-right: 25px solid transparent;
  border-top: 25px solid #26B8F3;
  border-left: 25px solid #26B8F3;
  border-bottom: 25px solid #26B8F3;
  border-radius: 25px;
  position: relative;
  left: -30px; }

@keyframes rotate_pacman_half_up {
  0%,100% {
    transform: rotate(270deg); }
  50% {
    transform: rotate(360deg); } }
@keyframes rotate_pacman_half_down {
  0%,100% {
    transform: rotate(90deg); }
  50% {
    transform: rotate(0); } }
@keyframes pacman-balls {
  75% {
    opacity: 0.7; }
  100% {
    transform: translate(-100px, -6.25px); } }
.pacman {
  position: relative; }

.pacman > div:nth-child(3) {
  animation: pacman-balls 1s -0.66s infinite linear; }

.pacman > div:nth-child(4) {
  animation: pacman-balls 1s -0.33s infinite linear; }

.pacman > div:nth-child(5) {
  animation: pacman-balls 1s 0s infinite linear; }

.pacman > div:first-of-type {
  animation: rotate_pacman_half_up 0.5s 0s infinite; }

.pacman > div:nth-child(2) {
  animation: rotate_pacman_half_down .5s 0s infinite;
  margin-top: -50px; }

.pacman > div:nth-child(3), .pacman > div:nth-child(4), .pacman > div:nth-child(5), .pacman > div:nth-child(6) {
  border-radius: 100%;
  margin: 2px;
  width: 10px;
  height: 10px;
  position: absolute;
  transform: translate(0, -6.25px);
  top: 25px;
  left: 70px; }

@keyframes ball-grid-beat {
  50% {
    opacity: 0.7; }
  100% {
    opacity: 1; } }
.ball-grid-beat {
  width: 57px; }

.ball-grid-beat > div:nth-child(1) {
  animation-delay: 30ms;
  animation-duration: 1.54s; }

.ball-grid-beat > div:nth-child(2) {
  animation-delay: .23s;
  animation-duration: 0.92s; }

.ball-grid-beat > div:nth-child(3) {
  animation-delay: .6s;
  animation-duration: 1.24s; }

.ball-grid-beat > div:nth-child(4) {
  animation-delay: .19s;
  animation-duration: 1.36s; }

.ball-grid-beat > div:nth-child(5) {
  animation-delay: .27s;
  animation-duration: 1.21s; }

.ball-grid-beat > div:nth-child(6) {
  animation-delay: -60ms;
  animation-duration: 1.04s; }

.ball-grid-beat > div:nth-child(7) {
  animation-delay: -80ms;
  animation-duration: 0.66s; }

.ball-grid-beat > div:nth-child(8) {
  animation-delay: -10ms;
  animation-duration: 0.95s; }

.ball-grid-beat > div:nth-child(9) {
  animation-delay: .8s;
  animation-duration: 0.66s; }

.ball-grid-beat > div {
  background-color: #26B8F3;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  float: left;
  animation-name: ball-grid-beat;
  animation-iteration-count: infinite;
  animation-delay: 0; }

.spinner {
  height: 56px;
  margin: auto 0;
  position: relative;
  width: 56px;
  -webkit-animation: material-rotate-all 1s linear infinite;
  animation: material-rotate-all 1s linear infinite; }

.spinner .bar {
  width: 100%;
  height: 100%;
  -moz-border-radius: 200px 0 0 200px;
  -webkit-border-radius: 200px;
  border-radius: 200px 0 0 200px;
  border: 6px solid #1abc9c;
  position: relative; }

.spinner .bar:after {
  width: 6px;
  height: 6px;
  display: block;
  background: #1abc9c;
  position: absolute;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px; }

.spinner .left-side, .spinner .right-side {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden; }

.loaders, .loaders .loader {
  box-sizing: border-box;
  display: flex; }

.spinner .right-side {
  right: 0; }

.spinner .right-side .bar {
  -moz-border-radius: 0 200px 200px 0;
  -webkit-border-radius: 0;
  border-radius: 0 200px 200px 0;
  border-left: none;
  -webkit-transform: rotate(-10deg);
  -webkit-transform-origin: left center;
  transform: rotate(-10deg);
  transform-origin: left center;
  -webkit-animation: material-rotate-right .75s linear infinite alternate;
  animation: material-rotate-right 0.75s linear infinite alternate; }

.spinner .right-side .bar:after {
  bottom: -6px;
  left: -3px; }

.spinner .left-side {
  left: 0; }

.spinner .left-side .bar {
  border-right: none;
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-animation: material-rotate-left .75s linear infinite alternate;
  animation: material-rotate-left 0.75s linear infinite alternate; }

.spinner .left-side .bar:after {
  bottom: -6px;
  right: -3px; }

@keyframes material-rotate-left {
  to {
    transform: rotate(30deg); }
  from {
    transform: rotate(175deg); } }
@keyframes material-rotate-right {
  from {
    transform: rotate(-175deg); }
  to {
    transform: rotate(-30deg); } }
@keyframes material-rotate-all {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(-360deg); } }
.loader01 {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  animation: 1s linear 0s normal none infinite running loader-rotate;
  border-color: #b74834 transparent #b74834 #b74834;
  border-image: none;
  border-radius: 50%;
  border-style: solid;
  border-width: 8px;
  height: 56px;
  margin: auto 0;
  position: relative;
  width: 56px; }

.loader01::after {
  background: #b74834;
  border-radius: 50%;
  height: 8px;
  left: 33px;
  position: absolute;
  top: -1px;
  width: 8px; }

.loader04 {
  animation: 1s ease-in-out 0s normal none infinite running loader-rotate;
  border: 2px solid #d8d7d7;
  border-radius: 50%;
  height: 56px;
  margin: -28px auto 0;
  position: relative;
  width: 56px; }

.loader04:after {
  background: #71B3D0;
  border-radius: 50%;
  height: 10px;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: -6px;
  width: 10px; }

.loader11, .loader11::after, .loader11::before {
  border-radius: 50%;
  box-shadow: 0 32px 0 #00b6ec;
  height: 16px;
  width: 16px; }

@keyframes loader-rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }
.loader11 {
  animation: .8s ease-in-out .32s alternate none infinite running loader11;
  margin: -50px auto 0;
  position: relative; }

.loader11::after, .loader11::before {
  animation: .8s ease-in-out 0s alternate none infinite running loader11;
  position: absolute; }

.loader11::before {
  animation-delay: .48s;
  left: -30px; }

.loader11::after {
  animation-delay: .16s;
  right: -30px; }

@keyframes loader11 {
  0% {
    box-shadow: 0 32px 0 #00b6ec; }
  100% {
    box-shadow: 0 20px 0 #00b6ec; } }
.single9 {
  height: 6px;
  width: 75px;
  background-color: #d8d7d7;
  position: relative; }

.single9:before {
  content: '';
  height: 6px;
  width: 10px;
  position: absolute;
  left: 0;
  background-color: #EC644B;
  -webkit-animation: single9anim 4s infinite ease-in-out;
  animation: single9anim 4s infinite ease-in-out; }

.ball-beat > div, .ball-grid-beat > div, .ball-grid-pulse > div, .ball-pulse-sync > div, .ball-rotate > div, .ball-rotate > div:after, .ball-rotate > div:before, .ball-scale-multiple > div, .ball-scale-ripple > div, .ball-spin-fade-loader > div, .ball-triangle-path > div, .ball-zig-zag > div, .cube-transition > div, .line-scale-pulse-out-rapid > div, .line-scale > div, .square-spin > div {
  background: #1abc9c; }

@-webkit-keyframes single9anim {
  0%,100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(65px);
    transform: translateX(65px); } }
@keyframes single9anim {
  0%,100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(65px);
    transform: translateX(65px); } }
.loaders {
  width: 100%;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap; }

.loaders .loader {
  flex: 0 1 auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25%;
  max-width: 25%;
  height: 200px;
  align-items: center;
  justify-content: center; }

.ball-scale-ripple-multiple > div {
  border: 2px solid #1abc9c; }

.pacman > div:nth-child(3), .pacman > div:nth-child(4), .pacman > div:nth-child(5), .pacman > div:nth-child(6) {
  background-color: #1abc9c; }

.pacman > div:first-of-type, .pacman > div:nth-child(2) {
  border-color: #1abc9c transparent #1abc9c #1abc9c; }

/*# sourceMappingURL=preloaders.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.item-link {
  color: #1abc9c;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase !important; }
  .item-link i {
    position: relative;
    right: 0;
    transition: right 0.3s ease 0s;
    top: -1px; }
  .item-link:hover {
    color: #1abc9c; }
    .item-link:hover i {
      right: -6px; }

.background-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.background-fixed {
  background-attachment: fixed;
  background-position: center top;
  background-size: cover; }

.no-parallax {
  background-size: cover !important;
  background-attachment: scroll !important;
  background-position: center center !important; }

.background-overlay-gradient-colored > .container,
.background-overlay-gradient > .container,
.background-overlay-gradient-dark > .container,
.background-overlay > .container,
.background-overlay-one > .container,
.background-overlay-dark > .container,
.background-overlay-light > .container,
.background-overlay-grey > .container,
.background-overlay-grey-dark > .container,
.background-overlay-dark > .container,
#particles-js > .container,
.background-overlay-gradient-colored > .container-fluid,
.background-overlay-gradient > .container-fluid,
.background-overlay-gradient-dark > .container-fluid,
.background-overlay > .container-fluid,
.background-overlay-one > .container-fluid,
.background-overlay-dark > .container-fluid,
.background-overlay-light > .container-fluid,
.background-overlay-grey > .container-fluid,
.background-overlay-grey-dark > .container-fluid,
.background-overlay-dark > .container-fluid,
#particles-js > .container-fluid,
.background-overlay-gradient-colored > .container-wide,
.background-overlay-gradient > .container-wide,
.background-overlay-gradient-dark > .container-wide,
.background-overlay > .container-wide,
.background-overlay-one > .container-wide,
.background-overlay-dark > .container-wide,
.background-overlay-light > .container-wide,
.background-overlay-grey > .container-wide,
.background-overlay-grey-dark > .container-wide,
.background-overlay-dark > .container-wide,
#particles-js > .container-wide {
  position: relative; }

.background-overlay-dark .mbYTP_wrapper,
.background-overlay .mbYTP_wrapper {
  z-index: -1 !important; }

.background-overlay-gradient:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.59)), color-stop(99%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
  filter: unicode("progid: DXImageTransform.Microsoft.gradient( startColorstr='#96000000', endColorstr='#00ffffff', GradientType=0)");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.8; }

.background-overlay-gradient-dark:before {
  background: -moz-linear-gradient(top, transparent 0%, rgba(16, 16, 16, 0.99) 100%);
  background: -webkit-linear-gradient(top, transparent 0%, rgba(16, 16, 16, 0.99) 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(16, 16, 16, 0.99) 100%);
  filter: unicode("progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#fc101010', GradientType=0)");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0; }

.background-overlay-gradient-colored:before {
  background: #ba01ff;
  background: -moz-linear-gradient(top, #ba01ff 0%, #ff0092 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ba01ff), color-stop(100%, #ff0092));
  background: -webkit-linear-gradient(top, #ba01ff 0%, #ff0092 100%);
  background: -o-linear-gradient(top, #ba01ff 0%, #ff0092 100%);
  background: -ms-linear-gradient(top, #ba01ff 0%, #ff0092 100%);
  background: linear-gradient(to bottom, #ba01ff 0%, #ff0092 100%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.8; }

.background-overlay:before {
  background: url("../src/images/overlay-pattern/overlay-pattern-1.html");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.8; }

.background-overlay-one:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0; }

.background-overlay-dark:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0; }

.background-overlay-light:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0; }

.background-overlay-grey:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0; }

.background-overlay-grey-dark:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(84, 87, 95, 0.8);
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0; }

.background-colored {
  background-color: #1abc9c; }

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0; }

/* ----------------------------------------------------------------
    MULTISCROLL
-----------------------------------------------------------------*/
#multiscroll .ms-left .ms-section {
  background-position: 100% center !important; }

#multiscroll .ms-right .ms-section {
  background-position: 0 center !important; }

/* ----------------------------------------------------------------
GO TO TOP BUTTON
-----------------------------------------------------------------*/
#goToTop {
  opacity: 0;
  position: fixed;
  text-align: center;
  line-height: 12px !important;
  right: 26px;
  bottom: 0;
  color: #fff;
  cursor: pointer;
  border-radius: 100px;
  z-index: 999;
  height: 40px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.3s, bottom 0.5s, box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1), background-color 0.1s linear;
  background-color: rgba(0, 0, 0, 0.25) !important;
  overflow: hidden; }
  #goToTop i {
    line-height: 39px !important;
    width: 39px !important;
    height: 39px !important;
    font-size: 16px !important;
    top: 0px !important;
    left: 0px !important;
    text-align: center !important;
    position: relative;
    z-index: 10;
    background-color: transparent !important;
    transform: translate(0, 0px);
    transition: transform 0.2s ease; }
  #goToTop:hover {
    transition: box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1), background-color 0.05s linear 0.25s;
    background-color: transparent !important; }
    #goToTop:hover i {
      transform: translate(0, -39px) !important; }
  #goToTop:after, #goToTop:before {
    display: block;
    content: ' ';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #1abc9c;
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    border-radius: 100px; }
  #goToTop:before {
    background-color: rgba(255, 255, 255, 0.25);
    transform: scale(1);
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    z-index: 2; }
  #goToTop:hover:after {
    transform: scale(1); }
  #goToTop:active:before {
    opacity: 1; }

/* ----------------------------------------------------------------
Misc
-----------------------------------------------------------------*/
.show-grid[class*="col-md-"] .show-grid-block {
  background-color: #eeeeee;
  border: 1px solid #ffffff;
  display: block;
  line-height: 40px;
  min-height: 40px;
  text-align: center; }

.fontawesome-icon-list i {
  font-size: 18px;
  display: inline-block;
  font-size: 1.3em;
  margin-right: 5px;
  position: relative;
  text-align: center;
  top: 2px;
  width: 30px;
  padding: 16px 0px; }

[class^="icon"] {
  display: inline-block; }

/* ----------------------------------------------------------------------
Images
-------------------------------------------------------------------------*/
.img-thumbnail {
  border: 1px solid #eee;
  padding: 1px; }

/* ----------------------------------------------------------------------
NEWS SECTIONS
-------------------------------------------------------------------------*/
.news-section-wrapper {
  clear: both;
  display: block;
  padding: 0 80px;
  position: relative; }

/* ----------------------------------------------------------------------
NEWS MARKETING BOX
-------------------------------------------------------------------------*/
.marketing-box {
  background-color: #f1f1f1;
  border: 1px solid #eee;
  clear: both;
  color: #999;
  height: 100px;
  line-height: 100px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px; }

/* ----------------------------------------------------------------------
NEWS MARKETING BOX
-------------------------------------------------------------------------*/
.news-ticker {
  border-bottom: 1px solid #eee;
  position: relative; }

.news-ticker-title {
  background-color: #f5f5f5;
  float: left;
  margin-right: 12px;
  height: 100%; }

.news-ticker-title h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  padding: 20px 30px 17px; }

.news-ticker-content {
  margin-bottom: 0;
  padding: 14px;
  width: 100%; }

.news-ticker-content .owl-item::before {
  color: #d82731;
  content: "\f067";
  font-family: fontawesome;
  font-size: 10px;
  margin-left: -24px;
  position: absolute; }

/* ----------------------------------------------------------------------
DEMO GRID
-------------------------------------------------------------------------*/
.grid-system-demo [class*="col-"] .grid-col-demo {
  background-color: #eee;
  display: block;
  line-height: 54px;
  min-height: 54px;
  text-align: center; }

.grid-system-demo .row {
  padding-bottom: 42px; }

.grid-system-demo-live .row {
  padding-bottom: 42px; }

/* ----------------------------------------------------------------------
BOXED GRID
-------------------------------------------------------------------------*/
.boxed-grid .row {
  margin-right: -10px;
  margin-left: -10px; }

.boxed-grid [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px; }

.boxed-grid [class*="col-"] a {
  margin-bottom: 20px; }

.boxed-grid a {
  overflow: hidden;
  display: block;
  position: relative; }

.boxed-grid a img {
  width: 100%;
  height: auto;
  transition: all 0.6s ease; }

.boxed-grid a:hover img {
  transform: scale(1.2); }

.boxed-grid a .title {
  position: absolute;
  top: 40%;
  left: 30px;
  font-size: 28px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff; }

/* ----------------------------------------------------------------------
PAGE 404
-------------------------------------------------------------------------*/
.page-error-404 {
  color: #eee;
  display: block;
  font-size: 300px;
  font-weight: 800;
  line-height: 0.7; }

body.device-xs .page-error-404 {
  font-size: 200px;
  line-height: 0.8;
  margin-bottom: 20px; }

body.device-xxs .page-error-404 {
  font-size: 140px;
  line-height: 0.8;
  margin-bottom: 20px; }

/* ----------------------------------------------------------------
Scrolldown icon animation
-----------------------------------------------------------------*/
.scrolldown-animation {
  animation: 1s ease-in-out 0s normal none infinite running scrolldown-keyframes;
  bottom: 40px;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 45px;
  z-index: 60; }

@keyframes scrolldown-keyframes {
  0%,
    100% {
    transform: translateY(-8px); }
  50% {
    transform: translateY(0px); } }
@keyframes scrolldown-keyframes {
  0%,
    100% {
    transform: translateY(-8px); }
  50% {
    transform: translateY(0px); } }
/*animated background*/
.animated-background {
  animation: 120s linear 0s normal none infinite running animatedBackground;
  background-repeat: repeat-x;
  background-size: cover; }

@keyframes animatedBackground {
  0% {
    background-position: 0 0; }
  100% {
    background-position: -3000px 0; } }
@keyframes animatedBackground {
  0% {
    background-position: 0 0; }
  100% {
    background-position: -3000px 0; } }
/* ----------------------------------------------------------------
Image Block
-----------------------------------------------------------------*/
.image-block {
  background-color: #f4f4f4; }

.image-block .col-md-6 {
  padding: 60px; }

.image-block-content {
  margin-bottom: 30px;
  padding: 0 50px; }

.image-block-content .feature-icon {
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: 60px;
  background-color: #eee; }

.image-block-content .feature-icon i {
  font-size: 28px;
  margin-top: 16px; }

.image-block-content .feature-content {
  padding-left: 85px; }

.image-block-content .feature-content h3 {
  margin-bottom: 0; }

.image-absolute {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 426px;
  height: 556px; }

/*hero headings*/
.heading-fancy-border {
  border: 15px solid #111;
  margin: 0 10%;
  padding: 60px 0px; }

.text-light .heading-fancy-border {
  border-color: #fff !important; }

/*hero heading 2*/
.hero-heading-2 {
  padding: 20px;
  background-color: #111;
  border-radius: 8px; }

.hero-heading-2 > i {
  font-size: 160px;
  opacity: 0.1;
  position: absolute;
  right: 23px;
  top: 2px; }

/*hero heading 3*/
.hero-heading-3 {
  background-color: #fff;
  border: 36px solid #fff;
  box-shadow: 0 0 0 1px #111 inset;
  color: #111;
  opacity: 0.7;
  padding: 72px;
  width: auto;
  margin: 40px;
  text-align: center; }

.hero-heading-3 h2,
.hero-heading-3 h3 {
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
  font-size: 92px;
  text-align: center;
  margin: 0;
  line-height: 72px; }

/*working-hours list*/
.working-hours ul {
  padding: 0; }

.working-hours li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 10px 0;
  width: 100%; }

.working-hours li::before {
  content: "\f017";
  font-family: FontAwesome;
  margin-right: 15px; }

.working-hours li span {
  float: right; }

.working-hours p {
  font-size: 13px;
  color: #111; }

/*price menu list*/
.price-menu-list {
  padding: 0;
  list-style: none; }

.price-menu-list li {
  margin-bottom: 40px; }

.price-menu-list li > h2 {
  margin-bottom: 0; }

.price-menu-list li > p {
  margin-bottom: 8px;
  opacity: 0.6; }

.price-menu-list li > img {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 100px;
  height: 100px;
  float: left; }

/* ----------------------------------------------------------------------
   Page loader
-------------------------------------------------------------------------*/
body:not(.no-page-loader) #wrapper {
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animsition-overlay,
.animsition-overlay-slide {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #fff; }

/* loading option */
.animsition-loading {
  background-color: #ffffff;
  z-index: 9999 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .animsition-loading .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
    border-radius: 50%;
    z-index: 100; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in; }

@-webkit-keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
.fade-out {
  -webkit-animation-name: fade-out;
  animation-name: fade-out; }

.ball-grid-pulse > div,
.square-spin > div,
.ball-rotate > div,
.ball-rotate > div:after,
.ball-rotate > div:before,
.cube-transition > div,
.ball-zig-zag > div,
.ball-triangle-path > div,
.line-scale > div,
.ball-scale-multiple > div,
.ball-pulse-sync > div,
.ball-beat > div,
.line-scale-pulse-out-rapid > div,
.ball-scale-ripple > div,
.ball-spin-fade-loader > div,
.line-spin-fade-loader > div,
.pacman > div:nth-child(3),
.pacman > div:nth-child(4),
.pacman > div:nth-child(5),
.pacman > div:nth-child(6),
.ball-grid-beat > div {
  background-color: #1abc9c; }

.ball-scale-ripple-multiple > div {
  border: 2px solid #1abc9c; }

.pacman > div:first-of-type,
.pacman > div:nth-child(2) {
  border-color: #1abc9c transparent #1abc9c #1abc9c; }

/* ----------------------------------------------------------------------
Responsive utilities
-------------------------------------------------------------------------*/
.responsive-utilities th small {
  color: #999;
  display: block;
  font-weight: 400; }

.responsive-utilities td.is-visible {
  background-color: #dff0d8 !important;
  color: #468847; }

/* ----------------------------------------------------------------
Bootstrap row colums fixes
-----------------------------------------------------------------*/
.col-md-2-5 {
  width: 20%;
  float: left;
  padding-left: 15px;
  padding-right: 15px; }

.row .col-md-6:nth-child(2) {
  clear: right; }

.row .col-xs-4:nth-child(3n+4),
.row .col-xs-3:nth-child(4n+5),
.row .col-xs-2:nth-child(6n+7),
.row .col-xs-1:nth-child(12n+13),
.row .col-md-4:nth-child(3n+4) {
  clear: right; }

@media (min-width: 768px) {
  .row .col-xs-6:nth-child(2n+3),
  .row .col-xs-4:nth-child(3n+4),
  .row .col-xs-3:nth-child(4n+5),
  .row .col-xs-2:nth-child(6n+7),
  .row .col-xs-1:nth-child(12n+13) {
    clear: none; } }
@media (min-width: 992px) {
  .row .col-sm-6:nth-child(2n+3),
  .row .col-sm-3:nth-child(4n+5),
  .row .col-sm-2:nth-child(6n+7),
  .row .col-sm-1:nth-child(12n+13) {
    clear: left; }

  .row .col-md-4:nth-child(3n+4) {
    clear: right; } }
@media (min-width: 1200px) {
  .row .col-md-2:nth-child(6n+7),
  .row .col-md-1:nth-child(12n+13) {
    clear: right; }

  .row .col-lg-6:nth-child(2n+3),
  .row .col-lg-4:nth-child(3n+4),
  .row .col-lg-3:nth-child(4n+5),
  .row .col-lg-2:nth-child(6n+7),
  .row .col-lg-1:nth-child(12n+13) {
    clear: left; } }
@media (min-width: 480px) and (max-width: 991px) {
  .row .col-md-6:nth-child(2n+3),
  .row .col-md-4:nth-child(2n+3),
  .row .col-md-3:nth-child(2n+4),
  .row .col-lg-6:nth-child(2n+3),
  .row .col-lg-4:nth-child(3n+4),
  .row .col-lg-3:nth-child(4n+5),
  .row .col-lg-2:nth-child(6n+7),
  .row .col-lg-1:nth-child(12n+13) {
    clear: left; }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4 {
    clear: right !important;
    float: left;
    margin-right: 0;
    width: 49.99%; }

  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-19,
  .col-md-11,
  .col-md-12 {
    width: 100%; }

  .col-md-6 .col-md-6 {
    clear: right;
    float: left;
    margin-right: 0;
    width: 50%; }

  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-19,
  .col-sm-11,
  .col-sm-12 {
    width: 100%; } }
@media (max-width: 991px) {
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-19,
  .col-sm-11,
  .col-sm-12 {
    width: 100%; } }
/* ----------------------------------------------------------------
Creative Profile Page
-----------------------------------------------------------------*/
.profile-content {
  width: 100%;
  padding: 0;
  position: relative; }
  .profile-content .profile-image {
    background-position: 50% 20%;
    background-repeat: repeat-y;
    background-size: cover;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 66.6666%;
    z-index: 2;
    display: table; }
    .profile-content .profile-image .profile-name {
      display: table-cell;
      height: 100%;
      padding: 30px;
      position: relative;
      vertical-align: bottom;
      width: 100%; }
      .profile-content .profile-image .profile-name h3 {
        color: #fff;
        font-size: 130px;
        opacity: 0.7;
        text-shadow: 0 22px 25px rgba(0, 0, 0, 0.13);
        line-height: 120px; }
  .profile-content .profile-bio {
    float: right;
    height: auto;
    margin: 50px;
    overflow: hidden;
    padding: 0 0 0 100px;
    position: relative;
    right: 0;
    top: 0;
    width: 33.3333%;
    z-index: 1; }
    .profile-content .profile-bio .profile-bio-footer {
      padding: 20px 0 0;
      width: 100%; }

/* ----------------------------------------------------------------
Text-Rotator
-----------------------------------------------------------------*/
.morphext > .animated {
  display: inline-block;
  opacity: 1; }

/* ----------------------------------------------------------------
pinOnScroll
-----------------------------------------------------------------*/
@media (min-width: 992px) {
  .affix-top,
  .affix-bottom {
    position: fixed; }

  .affix-bottom {
    position: absolute; } }
/* ----------------------------------------------------------------
Avatar image
-----------------------------------------------------------------*/
img.avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%; }

.ohidden {
  position: relative;
  overflow: hidden !important; }

/* ----------------------------------------------------------------
Play Button
-----------------------------------------------------------------*/
.play-button {
  position: relative;
  width: 90px;
  height: 90px;
  display: inline-block;
  border-width: 7px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 50%;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s; }
  .play-button > i {
    font-size: 22px;
    color: #ffffff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 2px;
    transform: translateY(-50%) translateX(-50%); }
  .play-button.dark {
    border-color: #252525; }
    .play-button.dark > i {
      color: #252525; }
  .play-button:hover {
    transform: scale(1.15);
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    /* > i {
    font-size: 18px;
    color: $white;
}
&.dark {
    border-color: $dark;
    > i {
        color: $dark;
    }
}*/ }

.lines-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  transition: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 16px;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent; }
  .lines-button > * {
    display: block; }
  .lines-button:hover, .lines-button:focus {
    outline: none; }
  .lines-button::-moz-focus-inner {
    border: 0; }

.lines {
  display: inline-block;
  height: 2px;
  width: 16px;
  border-radius: 4px;
  transition: 0.3s;
  position: relative;
  top: -4px; }
  .lines::before, .lines::after {
    display: inline-block;
    height: 2px;
    width: 16px;
    border-radius: 4px;
    transition: 0.3s;
    content: '';
    position: absolute;
    left: 0;
    -webkit-transform-origin: 2.85714px center;
    transform-origin: 2.85714px center;
    width: 100%; }
  .lines::before {
    top: 5px; }
  .lines::after {
    top: -5px; }
  .lines, .lines:before, .lines:after {
    background-color: #111; }

.toggle-active .lines {
  -webkit-transform: scale3d(0.8, 0.8, 0.8);
  transform: scale3d(0.8, 0.8, 0.8); }

.x {
  width: auto; }

.x.toggle-active .lines {
  background: transparent !important; }

.x.toggle-active .lines::before,
.x.toggle-active .lines::after {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  top: 0;
  width: 20px; }

.x.toggle-active .lines::before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg); }

.x.toggle-active .lines::after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg); }

a.toggle-item:hover,
a.toggle-item:focus,
a.toggle-item:active {
  color: #252525 !important; }

.toggle-item i {
  position: relative; }
  .toggle-item i:nth-of-type(2) {
    visibility: hidden;
    position: absolute; }
.toggle-item.toggle-active i:nth-of-type(2) {
  visibility: visible;
  position: relative; }
.toggle-item.toggle-active i:nth-of-type(1) {
  visibility: hidden;
  position: absolute; }

.image-hover-zoom {
  position: relative;
  overflow: hidden;
  cursor: url("../src/images/expand.png"), default;
  /*hover state*/ }
  .image-hover-zoom img {
    transition: all .4s ease;
    opacity: 1; }
  .image-hover-zoom:hover img {
    opacity: .3; }

/* ----------------------------------------------------------------
    YT PLAYER (YOUTUBE PLAYER)
-----------------------------------------------------------------*/
#youtube-background-controls {
  background: none;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 60px;
  position: relative;
  width: 60px; }

#youtube-background-controls .fa {
  font-size: 23px;
  line-height: 40px; }

#youtube-background-controls .fa-pause,
#youtube-background-controls .fa-play {
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 0;
  position: absolute;
  left: 19px;
  top: 9px; }

#youtube-background-controls .fa-play {
  left: 22px; }

#youtube-background-controls .fa-pause {
  opacity: 1; }

#youtube-background-controls.video-is-playing .fa-play {
  opacity: 1; }

#youtube-background-controls.video-is-playing .fa-pause {
  opacity: 0; }

.block-shadow {
  -moz-box-shadow: 0 25px 75px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 25px 75px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 25px 75px 0 rgba(0, 0, 0, 0.2);
  margin-left: -100px; }

.portfolio-item .new-badge {
  background-image: url("../src/preview/images/new-badge.html");
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 61px;
  position: absolute;
  right: 12px;
  top: 3px;
  width: 55px;
  z-index: 100; }

/* ----------------------------------------------------------------
    Hover 3d
-----------------------------------------------------------------*/
.hover-3d {
  /*    .portfolio-description,
  .grid-description {
transform: translateZ(0px);
      a {
          h3 {
              font-size: 32px;
              line-height: 34px;
          }
      }
      p {
          font-size: 16px;
      }
  }*/ }
  .hover-3d:hover .grid-item-wrap,
  .hover-3d:hover .portfolio-item-wrap {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4); }
  .hover-3d.hover-in {
    transition: transform .2s ease-out; }
  .hover-3d.hover-out {
    transition: transform .2s ease-in; }

/* ----------------------------------------------------------------
    Bar Rating
-----------------------------------------------------------------*/
.bar-rating {
  height: 23px;
  float: left;
  color: #FFC300; }

/* ----------------------------------------------------------------
    Bar Rating
-----------------------------------------------------------------*/
.image-left-offset {
  margin-left: -15px; }
  .image-left-offset img {
    width: 100%;
    max-width: none;
    z-index: 1;
    position: relative;
    display: block; }

.image-right-offset {
  margin-right: -15px; }
  .image-right-offset img {
    width: 100%;
    max-width: none;
    z-index: 1;
    position: relative;
    display: block; }

/*# sourceMappingURL=extras.css.map */

/* ----------------------------------------------------------------
    FOOTER
-----------------------------------------------------------------*/
#footer {
  clear: both;
  position: relative; }
  #footer .logo {
    margin-bottom: 54px;
    margin-top: 12px;
    max-width: 80px; }

.footer-logo {
  border-right: 1px solid #333;
  margin-bottom: 20px;
  margin-right: 18px;
  padding-right: 16px; }

#footer {
  font-size: 13px; }
  #footer.background-colored .footer-logo {
    border-right: 1px solid rgba(255, 255, 255, 0.4); }
  #footer.background-grey .footer-logo {
    border-right: 1px solid #ccc; }

.footer-content {
  padding: 60px 0 40px 0; }

.copyright-content {
  border-top: 1px solid #222;
  min-height: 80px;
  padding: 30px 0; }

footer.background-grey .copyright-content {
  border-top: 1px solid #ddd; }

.copyright-links a {
  padding: 10px; }

.copyright-text {
  font-size: 12px;
  padding-top: 10px;
  text-align: left;
  text-transform: uppercase; }

#footer-outer .widget h4,
#footer-outer .col .widget_recent_entries span,
#footer-outer .col .recent_posts_extra_widget .post-widget-text span {
  color: #999 !important; }
#footer-outer,
#footer-outer a {
  color: #666 !important; }

#footer .post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a {
  font-size: 13px;
  font-weight: 500; }
#footer .social-icons {
  float: right; }
  #footer .social-icons.center {
    float: none;
    display: inline-block; }
  #footer .social-icons:not(.social-icons-colored) li a:hover {
    background-color: transparent; }
#footer.footer-light {
  background-color: #f7f7f7;
  border-top: 1px solid #e5e5e5; }
  #footer.footer-light,
  #footer.footer-light .widget h4,
  #footer.footer-light a {
    color: #494949 !important; }
  #footer.footer-light span {
    color: #898989 !important; }
  #footer.footer-light .copyright-content {
    background-color: #efefef;
    border-top: 1px solid #e5e5e5; }
    #footer.footer-light .copyright-content .to-top {
      background-color: #fff;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      float: right;
      line-height: 24px;
      padding: 4px 9px;
      position: relative; }
      #footer.footer-light .copyright-content .to-top:hover {
        opacity: 0.9; }
      #footer.footer-light .copyright-content .to-top:hover, #footer.footer-light .copyright-content .to-top:focus {
        color: #333; }
  #footer.footer-light strong {
    color: #5a5a5a; }
#footer.footer-dark {
  background-color: #101010;
  border-top: 1px solid #222; }
  #footer.footer-dark,
  #footer.footer-dark a {
    color: #666 !important; }
  #footer.footer-dark .widget h4,
  #footer.footer-dark span {
    color: #999 !important; }
  #footer.footer-dark .copyright-content {
    border-top: 1px solid #222;
    min-height: 80px;
    padding: 30px 0;
    background-color: #1a1a1a; }
#footer.footer-sticky.footer-sticky-active {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%; }

/*# sourceMappingURL=footer.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.widget {
  margin-bottom: 30px;
  position: relative; }
  .widget .widget-title,
  .widget > h4 {
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 1px;
    margin-bottom: 28px; }
  .widget a {
    font-size: 13px; }
  .widget:after, .widget:before {
    clear: both;
    content: " ";
    display: table; }
  .widget iframe {
    width: 100%;
    height: auto; }

.sidebar {
  /*    .widget-title::after {
      border-top: 4px solid #444;
      content: "";
      display: block;
      margin-top: 14px;
      width: 60px;
  }*/ }
  .sidebar .widget {
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    padding-bottom: 30px; }
    .sidebar .widget:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0; }
    .sidebar .widget #testimonials .testimonial-item > img {
      border-radius: 50%;
      display: block;
      float: none;
      height: 50px !important;
      margin: 0 auto 20px;
      width: 50px !important; }

/*.widget-tags {
	.tags a {
		border: 1px solid rgba(0, 0, 0, 0.2);
        color: #777;
        display: inline-block;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 1px;
        margin: 0 2px 5px 0;
        padding: 5px 7px;
        text-decoration: none;
        text-transform: uppercase;
        -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
        transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	}
}*/
.tags a {
  border: 2px solid #f3f3f3;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  outline: none;
  padding: 5px 14px;
  margin: 0 2px 5px 0;
  background-color: transparent;
  border-color: #ebebeb;
  color: #4c5667;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s; }
  .tags a:hover, .tags a:focus, .tags a:active, .tags a.active {
    background-color: #1abc9c;
    border-color: #1abc9c;
    color: #fff; }

/*.widget-tags .tags a {
    
   
    
    font-size: 11px;
    letter-spacing: 1px;

}*/
.widget-archive ul {
  padding-left: 0; }

body.device-sm .sidebar .widget {
  float: left;
  width: 330px; }
body.device-xs .sidebar .widget {
  width: 100%; }
body.device-sm .sidebar .widget:nth-child(odd), body.device-xs .sidebar .widget:nth-child(odd) {
  margin-right: 30px; }

footer .widget-tags .tags a {
  border: 1px solid rgba(255, 255, 255, 0.2); }
footer .widget {
  margin-bottom: 20px; }

.col-md-3 .flickr-widget img {
  padding: 2px;
  width: 25%; }

body.device-xs .col-md-3 .flickr-widget img {
  width: 16.666%; }

.col-md-4 .flickr-widget img {
  padding: 2px;
  width: 20%; }

.widget .footer-widget .social-icon {
  background-color: rgba(0, 0, 0, 0.2); }

.footer-widget .social-icon o {
  color: white; }
.footer-widget .social-icon i {
  color: #ffffff; }

.widget.widget-newsletter button {
  margin-left: -1px; }
.widget.widget-newsletter button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }
.widget.widget-newsletter .btn {
  text-transform: none; }
.widget.widget-newsletter .form-control {
  height: 40px; }
.widget.widget-newsletter .btn {
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  padding: 8px 16px; }
.widget.widget-newsletter label.error {
  color: #e42c3e;
  display: none !important;
  font-weight: 400;
  margin-top: 5px; }

.background-dark .widget.widget-newsletter .input-group-addon {
  background-color: #ffffff;
  color: #ffffff; }
.background-dark .widget.widget-newsletter .form-transparent-fields .form-control {
  border-left: 0;
  padding-left: 0; }

.form-control.error,
.sm-form-control.error {
  border-color: #e42c3e; }

.widget-newsletter small {
  display: block; }

.widget.widget-search button {
  margin-left: -1px; }
.widget.widget-search button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }
.widget.widget-search .btn {
  text-transform: none; }
.widget.widget-search .btn {
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  padding: 8px 16px; }

.background-dark .widget.widget-search .input-group-addon {
  background-color: rgba(0, 0, 0, 0.35);
  border-color: rgba(0, 0, 0, 0.25);
  color: #999; }

#widget-contact-form {
  clear: left; }

.widget-tweeter,
.widget-twitter {
  min-height: 248px; }

.widget-tweeter li a,
.widget-twitter li a {
  color: #1abc9c; }

.widget-tweeter:not([data-avatar="true"]) li:before,
.widget-twitter:not([data-avatar="true"]) li:before {
  margin-left: -22px;
  position: absolute;
  font-family: fontawesome;
  content: "\f099"; }

.widget-tweeter[data-avatar="true"] li img,
.widget-twitter[data-avatar="true"] li img {
  border-radius: 50%;
  left: -4px;
  position: absolute;
  width: 26px;
  z-index: 3; }

.widget-tweeter[data-avatar="true"] li,
.widget-twitter[data-avatar="true"] li {
  padding-left: 10px; }

.widget-tweeter li,
.widget-twitter li {
  margin-bottom: 18px; }

.widget-tweeter li:last-child,
.widget-twitter li:last-child {
  margin-bottom: 0px; }

.widget-tweeter ul,
.widget-twitter ul {
  list-style: none;
  padding-left: 22px;
  margin-bottom: 0; }

.widget-tweeter small,
.widget-twitter small {
  display: block;
  color: #999 !important;
  width: 100%; }

.widget-newsletter .input-group-addon i {
  color: #555 !important; }

/*Widget: Contact us*/
/*Calendar - Datepicker Bootstrap*/
/*
.datepicker table {
    width: 100%;
}
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget .dow,
.bootstrap-datetimepicker-widget .picker-switch,
.bootstrap-datetimepicker-widget .prev,
.bootstrap-datetimepicker-widget .next,
.bootstrap-datetimepicker-widget datepicker-decades .decade{
    text-align: center;
}
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget .prev,
.bootstrap-datetimepicker-widget .next,
.bootstrap-datetimepicker-widget datepicker-decades .decade {
    cursor: pointer;
}*/
.bootstrap-datetimepicker-widget table td.active.today,
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover,
.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  background-color: #1abc9c !important;
  color: #fff;
  border-radius: 4px !important; }

.widget-gallery a > img {
  float: left;
  height: auto;
  width: 33.3333333%;
  padding: 3px; }

/*Widget Tabs*/
.widget .tabs-content {
  margin-bottom: 0;
  padding-bottom: 0; }

.sidebar .widget .tabs li > a {
  /*font-size: 11px;*/
  padding: 10px; }

.sidebar .widget .tabs .tabs-navigation li {
  float: left;
  display: inherit; }

.widget .post-thumbnail-content a {
  font-size: 13px;
  line-height: 20px;
  display: block; }

.sidebar .widget .post-thumbnail-list .post-thumbnail-entry > img {
  height: 48px;
  margin-right: 10px;
  width: 64px; }

.sidebar .widget .post-thumbnail-list .post-thumbnail-entry img + .post-thumbnail-content {
  padding-left: 72px; }

.sidebar .widget .post-thumbnail-entry .post-thumbnail-content .post-date,
.post-thumbnail-entry .post-thumbnail-content .post-category {
  font-size: 11px; }

.sidebar .widget .post-thumbnail-entry:last-child {
  margin-bottom: 0;
  padding-bottom: 0; }

@media (max-width: 991px) {
  .sidebar {
    margin-bottom: 40px; } }
/*Dark*/
.dark .widget .widget-title,
.dark .widget > h4 {
  color: #ffffff; }

/*# sourceMappingURL=widgets.css.map */

/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/
/*Colors*/
/*Typography*/
/*Header*/
/*Side Panel*/
/*Margins*/
/*Transitions*/
.list-icon-colored > li:before,
.list-icon-colored > li > i {
  color: #1abc9c !important; }

/*# sourceMappingURL=colors.css.map */


.whatsapp_floatt {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 165px;
  right: 40px;
  background-color: skyblue;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 100px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 100px;
      right: 50px;
      font-size: 22px;
  }
  .whatsapp_floatt{
    width: 40px;
      height: 40px;
      bottom: 145px;
      right: 50px;
      font-size: 22px;
  }
}
